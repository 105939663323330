import { switchMap, map, finalize } from 'rxjs/operators';
import { UpdateAppComponent } from './../../components/update-app/update-app.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from './../../../core/auth/auth.service';
import { APIHttpResponseCode, APIResponseModel } from './../../models/api-response';
import { LoaderService } from './../loader.service';
import { KeyValue } from '@angular/common';
import { defined, keyValuesToHttpHeaders } from './../../../shared/helpers/app.helpers';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
  useExisting: AuthService
})
export class ApiService {
  get accountId(): number {
    return this.authService.getCurrentUserAccountId();
  }
  get v2Accounts(): string {
    return `${environment.baseAPIUrl}${environment.version2}/accounts/${this.accountId}/`;
  }
  get v1Accounts(): string {
    return `${environment.baseAPIUrl}${environment.version}/accounts/${this.accountId}/`;
  }
  inventoryApi: string = `${environment.inventoryAPIUrl}v1.0/accounts/`;
  ocelotApi: string = environment.ocelotAPIUrl;

  constructor(private httpClient: HttpClient, private loaderService: LoaderService, private authService: AuthService, private keycloakService: KeycloakService) {}

  public showLoader() {
    this.loaderService.showLoader();
  }

  public hideLoader() {
    this.loaderService.hideLoader();
  }

  /**
   * Request Used to fetch data from the API
   * @param endpoint String, where the service will be calling to
   */
  public getRequest<T = any>(
    endpoint: string,
    showLoader: boolean = true,
    bypassAuth: boolean = false,
    responseTypeBlob: boolean = false,
    disregardUpdate: boolean = false,
    headers: KeyValue<string, string>[] = null
  ): Observable<APIResponseModel<T>> {
    if ((!bypassAuth && this.authService.isTokenGood()) || bypassAuth) {
      return this.httpGet(endpoint, showLoader, bypassAuth, responseTypeBlob, disregardUpdate, headers);
    } else if (this.authService.isTokenExpired()) {
      const impersonationMode = this.authService.getImpersonationMode();
      if (impersonationMode || !this.authService.getAuthenticateUsingKeycloakOIDC()) {
        return this.authService.refreshToken().pipe(
          switchMap((response) => {
            this.authService.setNewToken(response.results);
            return this.httpGet(endpoint, showLoader, bypassAuth, responseTypeBlob, disregardUpdate, headers);
          })
        );
      } else {
        return this.authService.refreshKeycloakToken().pipe(
          switchMap((response) => {
            if (response.results) {
              return this.httpGet(endpoint, showLoader, bypassAuth, responseTypeBlob, disregardUpdate, headers);
            } else {
              this.authService.logout();
              return this.getNullResponse();
            }
          })
        );
      }
    } else {
      this.authService.logout();
      return this.getNullResponse();
    }
  }

  /**
   * Request Used to Add and item.
   * @param endpoint String, where the service will be calling to
   * @param body Object, The body of the post request.
   */
  public postRequest(endpoint: string, body: any, bypassAuth: boolean = false, showLoader: boolean = true, disregardUpdate: boolean = false, responseTypeBlob: boolean = false): Observable<APIResponseModel<any>> {
    if ((!bypassAuth && this.authService.isTokenGood()) || bypassAuth) {
      return this.httpPost(endpoint, body, showLoader, disregardUpdate, responseTypeBlob);
    } else if (this.authService.isTokenExpired()) {
      const impersonationMode = this.authService.getImpersonationMode();
      if (impersonationMode || !this.authService.getAuthenticateUsingKeycloakOIDC()) {
        return this.authService.refreshToken().pipe(
          switchMap((response) => {
            this.authService.setNewToken(response.results);
            return this.httpPost(endpoint, body, showLoader, disregardUpdate, responseTypeBlob);
          })
        );
      } else {
        return this.authService.refreshKeycloakToken().pipe(
          switchMap((response) => {
            if (response.results) {
              return this.httpPost(endpoint, body, showLoader, disregardUpdate, responseTypeBlob);
            } else {
              this.authService.logout();
              return this.getNullResponse();
            }
          })
        );
      }
    } else {
      this.authService.logout();
      return this.getNullResponse();
    }
  }

  /**
   * Request used to update an item.
   * @param endpoint String, where the service will be calling to
   * @param body Object, The body of the put request.
   */
  public putRequest(endpoint: string, body: any, disregardUpdate: boolean = false, showLoader: boolean = true): Observable<APIResponseModel<any>> {
    if (this.authService.isTokenGood()) {
      return this.httpPut(endpoint, body, disregardUpdate, showLoader);
    } else if (this.authService.isTokenExpired()) {
      const impersonationMode = this.authService.getImpersonationMode();
      if (impersonationMode || !this.authService.getAuthenticateUsingKeycloakOIDC()) {
        return this.authService.refreshToken().pipe(
          switchMap((response) => {
            this.authService.setNewToken(response.results);
            return this.httpPut(endpoint, body, disregardUpdate, showLoader);
          })
        );
      } else {
        return this.authService.refreshKeycloakToken().pipe(
          switchMap((response) => {
            if (response.results) {
              return this.httpPut(endpoint, body, disregardUpdate, showLoader);
            } else {
              this.authService.logout();
              return this.getNullResponse();
            }
          })
        );
      }
    } else {
      this.authService.logout();
      return this.getNullResponse();
    }
  }

  /**
   * Request used to delete an item.
   * @param endpoint String, where the service will be calling to
   */
  public deleteRequest(endpoint: string, disregardUpdate: boolean = false, headers: KeyValue<string, string>[] = null): Observable<APIResponseModel<any>> {
    if (this.authService.isTokenGood()) {
      return this.httpDelete(endpoint, disregardUpdate, headers);
    } else if (this.authService.isTokenExpired()) {
      const impersonationMode = this.authService.getImpersonationMode();
      if (impersonationMode || !this.authService.getAuthenticateUsingKeycloakOIDC()) {
        return this.authService.refreshToken().pipe(
          switchMap((response) => {
            this.authService.setNewToken(response.results);
            return this.httpDelete(endpoint, disregardUpdate, headers);
          })
        );
      } else {
        return this.authService.refreshKeycloakToken().pipe(
          switchMap((response) => {
            if (response.results) {
              return this.httpDelete(endpoint, disregardUpdate, headers);
            } else {
              this.authService.logout();
              return this.getNullResponse();
            }
          })
        );
      }
    } else {
      this.authService.logout();
      return this.getNullResponse();
    }
  }

  public patchRequest(endpoint: string, body: any, disregardUpdate: boolean = false): Observable<APIResponseModel<any>> {
    if (this.authService.isTokenGood()) {
      return this.httpPatch(endpoint, body, disregardUpdate);
    } else if (this.authService.isTokenExpired()) {
      const impersonationMode = this.authService.getImpersonationMode();
      if (impersonationMode || !this.authService.getAuthenticateUsingKeycloakOIDC()) {
        return this.authService.refreshToken().pipe(
          switchMap((response) => {
            this.authService.setNewToken(response.results);
            return this.httpPatch(endpoint, body, disregardUpdate);
          })
        );
      } else {
        return this.authService.refreshKeycloakToken().pipe(
          switchMap((response) => {
            if (response.results) {
              return this.httpPatch(endpoint, body, disregardUpdate);
            } else {
              this.authService.logout();
              return this.getNullResponse();
            }
          })
        );
      }
    } else {
      this.authService.logout();
      return this.getNullResponse();
    }
  }

  getNullResponse(): Observable<APIResponseModel<any>> {
    this.authService.firstload = true;
    this.authService.logout();
    const resp = new APIResponseModel();
    resp.status = APIHttpResponseCode.unauthorized.toString();
    return of(resp);
  }

  private httpDelete(endpoint: string, disregardUpdate: boolean = false, keyValuesForHeaders: KeyValue<string, string>[] = null): Observable<APIResponseModel<any>> {
    this.showLoader();
    const _headers = keyValuesToHttpHeaders(keyValuesForHeaders);
    return this.httpClient.delete<any>(`${endpoint}`, { observe: 'response', headers: _headers }).pipe(
      map((result) => {
        this.hideLoader();
        this.authService.firstload = false;
        if (!disregardUpdate) {
          this.checkAppVersion(result);
        }
        return result.body;
      }),
      finalize(() => {
        this.hideLoader();
      })
    );
  }

  private httpPatch(endpoint: string, body: any, disregardUpdate: boolean = false): Observable<APIResponseModel<any>> {
    this.showLoader();
    return this.httpClient.patch<any>(`${endpoint}`, body, { observe: 'response' }).pipe(
      map((result) => {
        this.hideLoader();
        this.authService.firstload = false;
        if (!disregardUpdate) {
          this.checkAppVersion(result);
        }
        return result.body;
      }),
      finalize(() => {
        this.hideLoader();
      })
    );
  }

  private httpPut(endpoint: string, body: any, disregardUpdate: boolean = false, showLoader: boolean = true): Observable<APIResponseModel<any>> {
    if (showLoader) {
      this.showLoader();
    }
    return this.httpClient.put<any>(`${endpoint}`, body, { observe: 'response' }).pipe(
      map((result) => {
        if (showLoader) {
          this.hideLoader();
        }
        this.authService.firstload = false;
        if (!disregardUpdate) {
          this.checkAppVersion(result);
        }
        return result.body;
      }),
      finalize(() => {
        if (showLoader) {
          this.hideLoader();
        }
      })
    );
  }

  private httpPost(endpoint: string, body: any, showLoader: boolean = true, disregardUpdate: boolean = false, responseTypeBlob: boolean = false): Observable<APIResponseModel<any>> {
    if (showLoader) {
      this.showLoader();
    }
    const httpOptions = responseTypeBlob ? { responseType: 'blob' as 'json', observe: 'response' } : { observe: 'response' };
    return this.httpClient.post<any>(`${endpoint}`, body, <any>httpOptions).pipe(
      map((result: any) => {
        if (showLoader) {
          this.hideLoader();
        }
        this.authService.firstload = false;
        if (!disregardUpdate) {
          this.checkAppVersion(result);
        }
        return result.body;
      }),
      finalize(() => {
        if (showLoader) {
          this.hideLoader();
        }
      })
    );
  }

  private httpGet(
    endpoint: string,
    showLoader: boolean,
    bypassAuth: boolean,
    responseTypeBlob: boolean,
    disregardUpdate: boolean = false,
    keyValuesForHeaders: KeyValue<string, string>[] = null
  ): Observable<APIResponseModel<any>> {
    if (showLoader) {
      this.showLoader();
    }
    const _headers = keyValuesToHttpHeaders(keyValuesForHeaders); //Introduced for DMA

    const httpOptions = responseTypeBlob ? { responseType: 'blob' as 'json', observe: 'response', headers: _headers } : { observe: 'response', headers: _headers };
    return this.httpClient.get<any>(`${endpoint}`, <any>httpOptions).pipe(
      map((result: any) => {
        if (!disregardUpdate) {
          this.checkAppVersion(result);
        }
        if (showLoader) {
          this.hideLoader();
        }
        return result.body;
      }),
      finalize(() => {
        if (showLoader) {
          this.hideLoader();
        }
      })
    );
  }

  private checkAppVersion(response: HttpResponse<any>) {
    // update apiAppVersion only when requesting nome APIs
    if (response.url?.indexOf(environment.baseAPIUrl) != -1) {
      // @ts-ignore
      const appVersion = localStorage.getItem('appVersion');
      const apiAppVersion = response.headers.get('appVersion');
      let apiAppRequiresLoginAfterUpdate = response.headers.get('apprequiresloginafterupdate');

      // ignore apiAppRequiresLoginAfterUpdate when in punch-out mode
      if (this.authService.isPunchoutSession()) {
        apiAppRequiresLoginAfterUpdate = 'False';
      }

      this.authService.apiAppVersion = apiAppVersion;
      if (!appVersion || (defined(apiAppVersion) && appVersion < apiAppVersion)) {
        this.authService.apiAppVersion = apiAppVersion;
        this.authService.appRequiresLoginAfterUpdate = apiAppRequiresLoginAfterUpdate && apiAppRequiresLoginAfterUpdate === 'True' ? true : false;
        this.authService.appRequiresUpdate = true;
        this.authService.showUpdateAppDialog.next();
      }
    }
  }
}
