import { registerLocaleData, DOCUMENT } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeFrCA from '@angular/common/locales/fr-CA';
import localeZHHantHK from '@angular/common/locales/zh-Hant-HK';
import localeDe from '@angular/common/locales/de';
import localeJa from '@angular/common/locales/ja';
import localeCs from '@angular/common/locales/cs';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeSk from '@angular/common/locales/sk';
import localeIt from '@angular/common/locales/it';
import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { LOCALES } from '../../constants.config';
import { LocalStorageFlags } from '../../models/enums/local-storage-flags.enum';
import { Locale } from '../../models/locale.model';
import { PortalLanguage } from '../../models/portal-language.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  public $languageChanged: Subject<boolean> = new Subject();
  public $currentLanguage: BehaviorSubject<string>;

  constructor(private translateService: TranslateService, @Inject(DOCUMENT) private document: Document) {
    this.listenToLanguageChange();
  }

  getActiveLocale(): Locale {
    const language = localStorage.getItem(LocalStorageFlags.defaultLang);
    const locale = LOCALES.get(language);
    return locale;
  }

  registerLocalesData() {
    registerLocaleData(localeFrCA, LOCALES.get('fr-CA').code);
    registerLocaleData(localeFr, LOCALES.get('fr-FR').code);
    registerLocaleData(localeEsMX, LOCALES.get('es-MX').code);
    registerLocaleData(localeEs, LOCALES.get('es-ES').code);
    registerLocaleData(localeZHHantHK, LOCALES.get('zh').code);
    registerLocaleData(localeDe, LOCALES.get('de').code);
    registerLocaleData(localeJa, LOCALES.get('ja').code);
    registerLocaleData(localeCs, LOCALES.get('cs').code);
    registerLocaleData(localeNl, LOCALES.get('nl').code);
    registerLocaleData(localePl, LOCALES.get('pl').code);
    registerLocaleData(localePt, LOCALES.get('pt').code);
    registerLocaleData(localeSk, LOCALES.get('sk').code);
    registerLocaleData(localeIt, LOCALES.get('it').code);
  }

  getMappedLocale() {
    const langName = this.$currentLanguage.value;
    return LOCALES.get(langName).code;
  }

  initLanguage(): Observable<any> {
    this.translateService.addLangs(this.getPortalLanguages().map((l) => l.languageCode));
    const browserLang = this.translateService.getBrowserLang();
    let currentLang = localStorage.getItem('defaultLang') || browserLang;
    currentLang = currentLang.match(/en|fr\-FR|fr\-CA|es\-MX|es\-ES|de|ja|cs|nl|pl|pt|sk|it/) ? currentLang : 'en';
    this.translateService.setDefaultLang(currentLang);
    this.$currentLanguage = new BehaviorSubject(currentLang);
    this._setLanguage(currentLang);
    this.setHTMLTagLangAttribute(currentLang);
    return this.translateService.use(currentLang);
  }

  getPortalLanguages(): PortalLanguage[] {
    const portalLanguages = [];

    LOCALES.forEach((v, k) => {
      if (k !== 'en' && k !== 'zh') {
        portalLanguages.push(new PortalLanguage(v.language, k, v.cultureCode));
      }
    });

    portalLanguages.sort((l1, l2) => (l1.language > l2.language ? 1 : -1));
    portalLanguages.unshift(new PortalLanguage(LOCALES.get('en').language, 'en', LOCALES.get('en').cultureCode));

    return portalLanguages;
  }

  getCurrentLanguage(): string {
    return localStorage.getItem('defaultLang');
  }

  switchLanguage(lang: string) {
    this._setLanguage(lang);
    this.$currentLanguage.next(lang);
    this.translateService.use(lang);
    this.setHTMLTagLangAttribute(lang);
  }

  translate(value: string, interpolateParams?: Object): string {
    return this.translateService.instant(value, interpolateParams);
  }

  private _setLanguage(lang: string): void {
    localStorage.setItem('defaultLang', lang);
  }

  private setHTMLTagLangAttribute(lang: string): void {
    this.document.documentElement.lang = lang;
  }

  private listenToLanguageChange(): void {
    this.translateService.onLangChange.subscribe((response) => {
      this.$languageChanged.next(true);
    });
  }
}
