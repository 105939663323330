import { ResellerThemeModel } from './models/reseller-theme.model';
import { Reseller } from './models/enums/reseller.enum';
import { environment } from '../../environments/environment';
import { Case } from './models/enums/case.enum';
import { Locale } from './models/locale.model';
import { DropdownOption } from './models/dropdown-option.model';
import { CronOptions } from './components/cron-editor/CronOptions';

export const cronEditorOptions: CronOptions = {
  defaultTime: '00:00:00',

  hideMinutesTab: true,
  hideHourlyTab: true,
  hideDailyTab: false,
  hideWeeklyTab: false,
  hideMonthlyTab: false,
  hideYearlyTab: false,
  hideAdvancedTab: true,
  hideSpecificWeekDayTab: true,
  hideSpecificMonthWeekTab: true,

  use24HourTime: true,
  hideSeconds: true,

  cronFlavor: 'quartz', //standard or quartz
  formInputClass: '',
  formSelectClass: '',
  formRadioClass: '',
  formCheckboxClass: ''
};

export const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];

export const richTextEditorConfig: { [prop: string]: any } = {
  airMode: {
    airMode: true,
    popover: {
      air: ['bold', 'italic', 'underline', 'clear']
    },
    disableDragAndDrop: true,
    callbacks: {
      onEnter: (e) => {
        // Prevent the default behavior of creating paragraphs on "Enter" press
        e.stopImmediatePropagation();
        e.preventDefault();
        return false;
      },
      onPaste: (e) => {
        const bufferText = ((e.originalEvent || e).clipboardData || window['clipboardData']).getData('Text');
        e.preventDefault();
        // Cleanup all html tags
        const cleanBufferText = bufferText
          .replace(/\r\n/g, ' ') // Remove microsoft word newline chars
          .replace(/\r/g, ' ') // Remove macOS newline chars
          .replace(/\n/g, ' ') // Remove common newline chars
          .replace(/<[^>]*>/g, '')
          .replace(/&nbsp;/g, ' ')
          .trim();
        // Firefox fix
        setTimeout(function () {
          document.execCommand('insertHTML', false, cleanBufferText);
        }, 10);
      }
    }
  }
};

export const yesNo = [new DropdownOption(false, 'no_capital'), new DropdownOption(true, 'yes_capital')];

export const ANYTIME_ID = -1;

export const CLOUD_PATH = 'https://d3giki36ahiop9.cloudfront.net/';

export const COMPANY_NAME = { transact: 'Transact Technologies, Inc.' };
export const LOCATION_FILTER_NAME = 'locationsIds';

export const LOCATION_NAME_REGEX = /^[a-zA-Z0-9]{1,}[a-zA-Z0-9!@#\$%\^\&*\")\(+=._\-\s']*$/;
export const WIDGET_NAME_REGEX = /^[\w\u00C0-\uFFFF]{1,}[\w\u00C0-\uFFFF!@#\$%\^\&*\/\\\\,;<>:\~{}\`\|\[\]\?"\)\(\+=\._\-\s']*$/;
export const CUSTOM_FIELD_NAME_REGEX = /^[^!@#\$%\^\&*\/\\,;<>:~{}`|[\]?"')(+=._-]+$/;
export const DOMAIN_REGEX = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const RESELLER_THEME_CONFIG = [
  new ResellerThemeModel(Reseller.TRANSACT, 'transact-theme.css', '#002d8d', 'images/transact-images/', 'BOHA!', true, '#5FA2F5', '#1D6BCB', 'primary-bg-color', 'TN'),
  new ResellerThemeModel(Reseller.ZIPPYYUM, 'zy-theme.css', '#f9f9f9', 'images/zy-images/', 'ZippyYum', false, '#ef6721', '#424858', 'bg-transparent', 'ZY'),
  new ResellerThemeModel(Reseller.AveryDennison, 'ad-theme.css', '#E6E5E6', 'images/ad-images/', 'Avery Dennison', false, '#E31F26', '#2D2D2E', 'bg-transparent', 'AD')
];

export const COUNTRY_CODES_PLUS = [
  {
    code: 'US',
    dial_code: '+1',
    name: 'United States'
  },
  {
    code: 'AF',
    dial_code: '+93',
    name: 'Afghanistan'
  },
  {
    code: 'AL',
    dial_code: '+355',
    name: 'Albania'
  },
  {
    code: 'DZ',
    dial_code: '+213',
    name: 'Algeria'
  },
  {
    code: 'AS',
    dial_code: '+1 684',
    name: 'AmericanSamoa'
  },
  {
    code: 'AD',
    dial_code: '+376',
    name: 'Andorra'
  },
  {
    code: 'AO',
    dial_code: '+244',
    name: 'Angola'
  },
  {
    code: 'AI',
    dial_code: '+1 264',
    name: 'Anguilla'
  },
  {
    code: 'AQ',
    dial_code: '+672',
    name: 'Antarctica'
  },
  {
    code: 'AG',
    dial_code: '+1268',
    name: 'Antigua and Barbuda'
  },
  {
    code: 'AR',
    dial_code: '+54',
    name: 'Argentina'
  },
  {
    code: 'AM',
    dial_code: '+374',
    name: 'Armenia'
  },
  {
    code: 'AW',
    dial_code: '+297',
    name: 'Aruba'
  },
  {
    code: 'AU',
    dial_code: '+61',
    name: 'Australia'
  },
  {
    code: 'AT',
    dial_code: '+43',
    name: 'Austria'
  },
  {
    code: 'AZ',
    dial_code: '+994',
    name: 'Azerbaijan'
  },
  {
    code: 'BS',
    dial_code: '+1 242',
    name: 'Bahamas'
  },
  {
    code: 'BH',
    dial_code: '+973',
    name: 'Bahrain'
  },
  {
    code: 'BD',
    dial_code: '+880',
    name: 'Bangladesh'
  },
  {
    code: 'BB',
    dial_code: '+1 246',
    name: 'Barbados'
  },
  {
    code: 'BY',
    dial_code: '+375',
    name: 'Belarus'
  },
  {
    code: 'BE',
    dial_code: '+32',
    name: 'Belgium'
  },
  {
    code: 'BZ',
    dial_code: '+501',
    name: 'Belize'
  },
  {
    code: 'BJ',
    dial_code: '+229',
    name: 'Benin'
  },
  {
    code: 'BM',
    dial_code: '+1 441',
    name: 'Bermuda'
  },
  {
    code: 'BT',
    dial_code: '+975',
    name: 'Bhutan'
  },
  {
    code: 'BO',
    dial_code: '+591',
    name: 'Bolivia, Plurinational State of'
  },
  {
    code: 'BA',
    dial_code: '+387',
    name: 'Bosnia and Herzegovina'
  },
  {
    code: 'BW',
    dial_code: '+267',
    name: 'Botswana'
  },
  {
    code: 'BR',
    dial_code: '+55',
    name: 'Brazil'
  },
  {
    code: 'IO',
    dial_code: '+246',
    name: 'British Indian Ocean Territory'
  },
  {
    code: 'BN',
    dial_code: '+673',
    name: 'Brunei Darussalam'
  },
  {
    code: 'BG',
    dial_code: '+359',
    name: 'Bulgaria'
  },
  {
    code: 'BF',
    dial_code: '+226',
    name: 'Burkina Faso'
  },
  {
    code: 'BI',
    dial_code: '+257',
    name: 'Burundi'
  },
  {
    code: 'KH',
    dial_code: '+855',
    name: 'Cambodia'
  },
  {
    code: 'CM',
    dial_code: '+237',
    name: 'Cameroon'
  },
  {
    code: 'CA',
    dial_code: '+1',
    name: 'Canada'
  },
  {
    code: 'CV',
    dial_code: '+238',
    name: 'Cape Verde'
  },
  {
    code: 'KY',
    dial_code: '+ 345',
    name: 'Cayman Islands'
  },
  {
    code: 'CF',
    dial_code: '+236',
    name: 'Central African Republic'
  },
  {
    code: 'TD',
    dial_code: '+235',
    name: 'Chad'
  },
  {
    code: 'CL',
    dial_code: '+56',
    name: 'Chile'
  },
  {
    code: 'CN',
    dial_code: '+86',
    name: 'China'
  },
  {
    code: 'CX',
    dial_code: '+61',
    name: 'Christmas Island'
  },
  {
    code: 'CC',
    dial_code: '+61',
    name: 'Cocos (Keeling) Islands'
  },
  {
    code: 'CO',
    dial_code: '+57',
    name: 'Colombia'
  },
  {
    code: 'KM',
    dial_code: '+269',
    name: 'Comoros'
  },
  {
    code: 'CG',
    dial_code: '+242',
    name: 'Congo'
  },
  {
    code: 'CD',
    dial_code: '+243',
    name: 'Congo, The Democratic Republic of the'
  },
  {
    code: 'CK',
    dial_code: '+682',
    name: 'Cook Islands'
  },
  {
    code: 'CR',
    dial_code: '+506',
    name: 'Costa Rica'
  },
  {
    code: 'CI',
    dial_code: '+225',
    name: "Cote d'Ivoire"
  },
  {
    code: 'HR',
    dial_code: '+385',
    name: 'Croatia'
  },
  {
    code: 'CU',
    dial_code: '+53',
    name: 'Cuba'
  },
  {
    code: 'CY',
    dial_code: '+537',
    name: 'Cyprus'
  },
  {
    code: 'CZ',
    dial_code: '+420',
    name: 'Czech Republic'
  },
  {
    code: 'DK',
    dial_code: '+45',
    name: 'Denmark'
  },
  {
    code: 'DJ',
    dial_code: '+253',
    name: 'Djibouti'
  },
  {
    code: 'DM',
    dial_code: '+1 767',
    name: 'Dominica'
  },
  {
    code: 'DO',
    dial_code: '+1 849',
    name: 'Dominican Republic'
  },
  {
    code: 'EC',
    dial_code: '+593',
    name: 'Ecuador'
  },
  {
    code: 'EG',
    dial_code: '+20',
    name: 'Egypt'
  },
  {
    code: 'SV',
    dial_code: '+503',
    name: 'El Salvador'
  },
  {
    code: 'GQ',
    dial_code: '+240',
    name: 'Equatorial Guinea'
  },
  {
    code: 'ER',
    dial_code: '+291',
    name: 'Eritrea'
  },
  {
    code: 'EE',
    dial_code: '+372',
    name: 'Estonia'
  },
  {
    code: 'ET',
    dial_code: '+251',
    name: 'Ethiopia'
  },
  {
    code: 'FK',
    dial_code: '+500',
    name: 'Falkland Islands (Malvinas)'
  },
  {
    code: 'FO',
    dial_code: '+298',
    name: 'Faroe Islands'
  },
  {
    code: 'FJ',
    dial_code: '+679',
    name: 'Fiji'
  },
  {
    code: 'FI',
    dial_code: '+358',
    name: 'Finland'
  },
  {
    code: 'FR',
    dial_code: '+33',
    name: 'France'
  },
  {
    code: 'GF',
    dial_code: '+594',
    name: 'French Guiana'
  },
  {
    code: 'PF',
    dial_code: '+689',
    name: 'French Polynesia'
  },
  {
    code: 'GA',
    dial_code: '+241',
    name: 'Gabon'
  },
  {
    code: 'GM',
    dial_code: '+220',
    name: 'Gambia'
  },
  {
    code: 'GE',
    dial_code: '+995',
    name: 'Georgia'
  },
  {
    code: 'DE',
    dial_code: '+49',
    name: 'Germany'
  },
  {
    code: 'GH',
    dial_code: '+233',
    name: 'Ghana'
  },
  {
    code: 'GI',
    dial_code: '+350',
    name: 'Gibraltar'
  },
  {
    code: 'GR',
    dial_code: '+30',
    name: 'Greece'
  },
  {
    code: 'GL',
    dial_code: '+299',
    name: 'Greenland'
  },
  {
    code: 'GD',
    dial_code: '+1 473',
    name: 'Grenada'
  },
  {
    code: 'GP',
    dial_code: '+590',
    name: 'Guadeloupe'
  },
  {
    code: 'GU',
    dial_code: '+1 671',
    name: 'Guam'
  },
  {
    code: 'GT',
    dial_code: '+502',
    name: 'Guatemala'
  },
  {
    code: 'GG',
    dial_code: '+44',
    name: 'Guernsey'
  },
  {
    code: 'GN',
    dial_code: '+224',
    name: 'Guinea'
  },
  {
    code: 'GW',
    dial_code: '+245',
    name: 'Guinea-Bissau'
  },
  {
    code: 'GY',
    dial_code: '+595',
    name: 'Guyana'
  },
  {
    code: 'HT',
    dial_code: '+509',
    name: 'Haiti'
  },
  {
    code: 'VA',
    dial_code: '+379',
    name: 'Holy See (Vatican City State)'
  },
  {
    code: 'HN',
    dial_code: '+504',
    name: 'Honduras'
  },
  {
    code: 'HK',
    dial_code: '+852',
    name: 'Hong Kong'
  },
  {
    code: 'HU',
    dial_code: '+36',
    name: 'Hungary'
  },
  {
    code: 'IS',
    dial_code: '+354',
    name: 'Iceland'
  },
  {
    code: 'IN',
    dial_code: '+91',
    name: 'India'
  },
  {
    code: 'ID',
    dial_code: '+62',
    name: 'Indonesia'
  },
  {
    code: 'IR',
    dial_code: '+98',
    name: 'Iran, Islamic Republic of'
  },
  {
    code: 'IQ',
    dial_code: '+964',
    name: 'Iraq'
  },
  {
    code: 'IE',
    dial_code: '+353',
    name: 'Ireland'
  },
  {
    code: 'IM',
    dial_code: '+44',
    name: 'Isle of Man'
  },
  {
    code: 'IL',
    dial_code: '+972',
    name: 'Israel'
  },
  {
    code: 'IT',
    dial_code: '+39',
    name: 'Italy'
  },
  {
    code: 'JM',
    dial_code: '+1 876',
    name: 'Jamaica'
  },
  {
    code: 'JP',
    dial_code: '+81',
    name: 'Japan'
  },
  {
    code: 'JE',
    dial_code: '+44',
    name: 'Jersey'
  },
  {
    code: 'JO',
    dial_code: '+962',
    name: 'Jordan'
  },
  {
    code: 'KZ',
    dial_code: '+7 7',
    name: 'Kazakhstan'
  },
  {
    code: 'KE',
    dial_code: '+254',
    name: 'Kenya'
  },
  {
    code: 'KI',
    dial_code: '+686',
    name: 'Kiribati'
  },
  {
    code: 'KP',
    dial_code: '+850',
    name: "Korea, Democratic People's Republic of"
  },
  {
    code: 'KR',
    dial_code: '+82',
    name: 'Korea, Republic of'
  },
  {
    code: 'KW',
    dial_code: '+965',
    name: 'Kuwait'
  },
  {
    code: 'KG',
    dial_code: '+996',
    name: 'Kyrgyzstan'
  },
  {
    code: 'LA',
    dial_code: '+856',
    name: "Lao People's Democratic Republic"
  },
  {
    code: 'LV',
    dial_code: '+371',
    name: 'Latvia'
  },
  {
    code: 'LB',
    dial_code: '+961',
    name: 'Lebanon'
  },
  {
    code: 'LS',
    dial_code: '+266',
    name: 'Lesotho'
  },
  {
    code: 'LR',
    dial_code: '+231',
    name: 'Liberia'
  },
  {
    code: 'LY',
    dial_code: '+218',
    name: 'Libyan Arab Jamahiriya'
  },
  {
    code: 'LI',
    dial_code: '+423',
    name: 'Liechtenstein'
  },
  {
    code: 'LT',
    dial_code: '+370',
    name: 'Lithuania'
  },
  {
    code: 'LU',
    dial_code: '+352',
    name: 'Luxembourg'
  },
  {
    code: 'MO',
    dial_code: '+853',
    name: 'Macao'
  },
  {
    code: 'MK',
    dial_code: '+389',
    name: 'Macedonia, The Former Yugoslav Republic of'
  },
  {
    code: 'MG',
    dial_code: '+261',
    name: 'Madagascar'
  },
  {
    code: 'MW',
    dial_code: '+265',
    name: 'Malawi'
  },
  {
    code: 'MY',
    dial_code: '+60',
    name: 'Malaysia'
  },
  {
    code: 'MV',
    dial_code: '+960',
    name: 'Maldives'
  },
  {
    code: 'ML',
    dial_code: '+223',
    name: 'Mali'
  },
  {
    code: 'MT',
    dial_code: '+356',
    name: 'Malta'
  },
  {
    code: 'MH',
    dial_code: '+692',
    name: 'Marshall Islands'
  },
  {
    code: 'MQ',
    dial_code: '+596',
    name: 'Martinique'
  },
  {
    code: 'MR',
    dial_code: '+222',
    name: 'Mauritania'
  },
  {
    code: 'MU',
    dial_code: '+230',
    name: 'Mauritius'
  },
  {
    code: 'YT',
    dial_code: '+262',
    name: 'Mayotte'
  },
  {
    code: 'MX',
    dial_code: '+52',
    name: 'Mexico'
  },
  {
    code: 'FM',
    dial_code: '+691',
    name: 'Micronesia, Federated States of'
  },
  {
    code: 'MD',
    dial_code: '+373',
    name: 'Moldova, Republic of'
  },
  {
    code: 'MC',
    dial_code: '+377',
    name: 'Monaco'
  },
  {
    code: 'MN',
    dial_code: '+976',
    name: 'Mongolia'
  },
  {
    code: 'ME',
    dial_code: '+382',
    name: 'Montenegro'
  },
  {
    code: 'MS',
    dial_code: '+1664',
    name: 'Montserrat'
  },
  {
    code: 'MA',
    dial_code: '+212',
    name: 'Morocco'
  },
  {
    code: 'MZ',
    dial_code: '+258',
    name: 'Mozambique'
  },
  {
    code: 'MM',
    dial_code: '+95',
    name: 'Myanmar'
  },
  {
    code: 'NA',
    dial_code: '+264',
    name: 'Namibia'
  },
  {
    code: 'NR',
    dial_code: '+674',
    name: 'Nauru'
  },
  {
    code: 'NP',
    dial_code: '+977',
    name: 'Nepal'
  },
  {
    code: 'NL',
    dial_code: '+31',
    name: 'Netherlands'
  },
  {
    code: 'AN',
    dial_code: '+599',
    name: 'Netherlands Antilles'
  },
  {
    code: 'NC',
    dial_code: '+687',
    name: 'New Caledonia'
  },
  {
    code: 'NZ',
    dial_code: '+64',
    name: 'New Zealand'
  },
  {
    code: 'NI',
    dial_code: '+505',
    name: 'Nicaragua'
  },
  {
    code: 'NE',
    dial_code: '+227',
    name: 'Niger'
  },
  {
    code: 'NG',
    dial_code: '+234',
    name: 'Nigeria'
  },
  {
    code: 'NU',
    dial_code: '+683',
    name: 'Niue'
  },
  {
    code: 'NF',
    dial_code: '+672',
    name: 'Norfolk Island'
  },
  {
    code: 'MP',
    dial_code: '+1 670',
    name: 'Northern Mariana Islands'
  },
  {
    code: 'NO',
    dial_code: '+47',
    name: 'Norway'
  },
  {
    code: 'OM',
    dial_code: '+968',
    name: 'Oman'
  },
  {
    code: 'PK',
    dial_code: '+92',
    name: 'Pakistan'
  },
  {
    code: 'PW',
    dial_code: '+680',
    name: 'Palau'
  },
  {
    code: 'PS',
    dial_code: '+970',
    name: 'Palestinian Territory, Occupied'
  },
  {
    code: 'PA',
    dial_code: '+507',
    name: 'Panama'
  },
  {
    code: 'PG',
    dial_code: '+675',
    name: 'Papua New Guinea'
  },
  {
    code: 'PY',
    dial_code: '+595',
    name: 'Paraguay'
  },
  {
    code: 'PE',
    dial_code: '+51',
    name: 'Peru'
  },
  {
    code: 'PH',
    dial_code: '+63',
    name: 'Philippines'
  },
  {
    code: 'PN',
    dial_code: '+872',
    name: 'Pitcairn'
  },
  {
    code: 'PL',
    dial_code: '+48',
    name: 'Poland'
  },
  {
    code: 'PT',
    dial_code: '+351',
    name: 'Portugal'
  },
  {
    code: 'PR',
    dial_code: '+1 939',
    name: 'Puerto Rico'
  },
  {
    code: 'QA',
    dial_code: '+974',
    name: 'Qatar'
  },
  {
    code: 'RO',
    dial_code: '+40',
    name: 'Romania'
  },
  {
    code: 'RU',
    dial_code: '+7',
    name: 'Russia'
  },
  {
    code: 'RW',
    dial_code: '+250',
    name: 'Rwanda'
  },
  {
    code: 'RE',
    dial_code: '+262',
    name: 'Réunion'
  },
  {
    code: 'BL',
    dial_code: '+590',
    name: 'Saint Barthélemy'
  },
  {
    code: 'SH',
    dial_code: '+290',
    name: 'Saint Helena, Ascension and Tristan Da Cunha'
  },
  {
    code: 'KN',
    dial_code: '+1 869',
    name: 'Saint Kitts and Nevis'
  },
  {
    code: 'LC',
    dial_code: '+1 758',
    name: 'Saint Lucia'
  },
  {
    code: 'MF',
    dial_code: '+590',
    name: 'Saint Martin'
  },
  {
    code: 'PM',
    dial_code: '+508',
    name: 'Saint Pierre and Miquelon'
  },
  {
    code: 'VC',
    dial_code: '+1 784',
    name: 'Saint Vincent and the Grenadines'
  },
  {
    code: 'WS',
    dial_code: '+685',
    name: 'Samoa'
  },
  {
    code: 'SM',
    dial_code: '+378',
    name: 'San Marino'
  },
  {
    code: 'ST',
    dial_code: '+239',
    name: 'Sao Tome and Principe'
  },
  {
    code: 'SA',
    dial_code: '+966',
    name: 'Saudi Arabia'
  },
  {
    code: 'SN',
    dial_code: '+221',
    name: 'Senegal'
  },
  {
    code: 'RS',
    dial_code: '+381',
    name: 'Serbia'
  },
  {
    code: 'SC',
    dial_code: '+248',
    name: 'Seychelles'
  },
  {
    code: 'SL',
    dial_code: '+232',
    name: 'Sierra Leone'
  },
  {
    code: 'SG',
    dial_code: '+65',
    name: 'Singapore'
  },
  {
    code: 'SK',
    dial_code: '+421',
    name: 'Slovakia'
  },
  {
    code: 'SI',
    dial_code: '+386',
    name: 'Slovenia'
  },
  {
    code: 'SB',
    dial_code: '+677',
    name: 'Solomon Islands'
  },
  {
    code: 'SO',
    dial_code: '+252',
    name: 'Somalia'
  },
  {
    code: 'ZA',
    dial_code: '+27',
    name: 'South Africa'
  },
  {
    code: 'GS',
    dial_code: '+500',
    name: 'South Georgia and the South Sandwich Islands'
  },
  {
    code: 'ES',
    dial_code: '+34',
    name: 'Spain'
  },
  {
    code: 'LK',
    dial_code: '+94',
    name: 'Sri Lanka'
  },
  {
    code: 'SD',
    dial_code: '+249',
    name: 'Sudan'
  },
  {
    code: 'SR',
    dial_code: '+597',
    name: 'Suriname'
  },
  {
    code: 'SJ',
    dial_code: '+47',
    name: 'Svalbard and Jan Mayen'
  },
  {
    code: 'SZ',
    dial_code: '+268',
    name: 'Swaziland'
  },
  {
    code: 'SE',
    dial_code: '+46',
    name: 'Sweden'
  },
  {
    code: 'CH',
    dial_code: '+41',
    name: 'Switzerland'
  },
  {
    code: 'SY',
    dial_code: '+963',
    name: 'Syrian Arab Republic'
  },
  {
    code: 'TW',
    dial_code: '+886',
    name: 'Taiwan, Province of China'
  },
  {
    code: 'TJ',
    dial_code: '+992',
    name: 'Tajikistan'
  },
  {
    code: 'TZ',
    dial_code: '+255',
    name: 'Tanzania, United Republic of'
  },
  {
    code: 'TH',
    dial_code: '+66',
    name: 'Thailand'
  },
  {
    code: 'TL',
    dial_code: '+670',
    name: 'Timor-Leste'
  },
  {
    code: 'TG',
    dial_code: '+228',
    name: 'Togo'
  },
  {
    code: 'TK',
    dial_code: '+690',
    name: 'Tokelau'
  },
  {
    code: 'TO',
    dial_code: '+676',
    name: 'Tonga'
  },
  {
    code: 'TT',
    dial_code: '+1 868',
    name: 'Trinidad and Tobago'
  },
  {
    code: 'TN',
    dial_code: '+216',
    name: 'Tunisia'
  },
  {
    code: 'TR',
    dial_code: '+90',
    name: 'Turkey'
  },
  {
    code: 'TM',
    dial_code: '+993',
    name: 'Turkmenistan'
  },
  {
    code: 'TC',
    dial_code: '+1 649',
    name: 'Turks and Caicos Islands'
  },
  {
    code: 'TV',
    dial_code: '+688',
    name: 'Tuvalu'
  },
  {
    code: 'UG',
    dial_code: '+256',
    name: 'Uganda'
  },
  {
    code: 'UA',
    dial_code: '+380',
    name: 'Ukraine'
  },
  {
    code: 'AE',
    dial_code: '+971',
    name: 'United Arab Emirates'
  },
  {
    code: 'GB',
    dial_code: '+44',
    name: 'United Kingdom'
  },
  {
    code: 'UY',
    dial_code: '+598',
    name: 'Uruguay'
  },
  {
    code: 'UZ',
    dial_code: '+998',
    name: 'Uzbekistan'
  },
  {
    code: 'VU',
    dial_code: '+678',
    name: 'Vanuatu'
  },
  {
    code: 'VE',
    dial_code: '+58',
    name: 'Venezuela, Bolivarian Republic of'
  },
  {
    code: 'VN',
    dial_code: '+84',
    name: 'Viet Nam'
  },
  {
    code: 'VG',
    dial_code: '+1 284',
    name: 'Virgin Islands, British'
  },
  {
    code: 'VI',
    dial_code: '+1 340',
    name: 'Virgin Islands, U.S.'
  },
  {
    code: 'WF',
    dial_code: '+681',
    name: 'Wallis and Futuna'
  },
  {
    code: 'YE',
    dial_code: '+967',
    name: 'Yemen'
  },
  {
    code: 'ZM',
    dial_code: '+260',
    name: 'Zambia'
  },
  {
    code: 'ZW',
    dial_code: '+263',
    name: 'Zimbabwe'
  },
  {
    code: 'AX',
    dial_code: '+358',
    name: 'Åland Islands'
  }
];

// List of articles, prepositions, and conjunctions that should not be capitalized for title case (unless it is the first word in title).
// For now, using the APA Style rules and only applying to words of 3 letters or less.
// See https://apastyle.apa.org/style-grammar-guidelines/capitalization/title-case
// Also using this filter to include words that should always be fully capitalized (e.g. RFID).
export const EN_TITLE_CASE_FILTER_WORDS = ['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'if', 'in', 'nor', 'of', 'off', 'on', 'or', 'so', 'per', 'the', 'to', 'up', 'via', 'yet', 'RFID', 'EPC'];

export const LOCALES = new Map<string, Locale>([
  ['cs', { translationKey: 'languages.cs_language', name: 'czech', code: 'cs', case: Case.Sentence, language: 'Čeština', cultureCode: 'cs' }],
  ['de', { translationKey: 'languages.de_language', name: 'german', code: 'de', case: Case.Sentence, language: 'Deutsch', cultureCode: 'de' }],
  ['en', { translationKey: 'languages.en_language', name: 'english', code: 'en-US', case: Case.Title, language: 'English', cultureCode: 'en', titleCaseFilter: EN_TITLE_CASE_FILTER_WORDS }],
  ['es-MX', { translationKey: 'languages.es_mx_language', name: 'spanish', code: 'es-MX', case: Case.Sentence, language: 'Español (México)', cultureCode: 'es-MX' }],
  ['es-ES', { translationKey: 'languages.es_es_language', name: 'spanish', code: 'es-ES', case: Case.Sentence, language: 'Español (España)', cultureCode: 'es-ES' }],
  ['fr-CA', { translationKey: 'languages.fr_ca_language', name: 'french', code: 'fr-CA', case: Case.Sentence, language: 'Français (Canada)', cultureCode: 'fr-CA' }],
  ['fr-FR', { translationKey: 'languages.fr_fr_language', name: 'french', code: 'fr-FR', case: Case.Sentence, language: 'Français (France)', cultureCode: 'fr-FR' }],
  ['ja', { translationKey: 'languages.ja_language', name: 'japanese', code: 'ja', case: Case.Default, language: '日本語', cultureCode: 'ja' }],
  ['nl', { translationKey: 'languages.nl_language', name: 'dutch', code: 'nl', case: Case.Sentence, language: 'Nederlands', cultureCode: 'nl' }],
  ['pl', { translationKey: 'languages.pl_language', name: 'polish', code: 'pl', case: Case.Sentence, language: 'Polski', cultureCode: 'pl' }],
  ['pt', { translationKey: 'languages.pt_language', name: 'portuguese', code: 'pt', case: Case.Title, language: 'Português', cultureCode: 'pt' }],
  ['sk', { translationKey: 'languages.sk_language', name: 'slovak', code: 'sk', case: Case.Sentence, language: 'Slovenský', cultureCode: 'sk' }],
  ['it', { translationKey: 'languages.it_language', name: 'italian', code: 'it', case: Case.Sentence, language: 'Italiano', cultureCode: 'it' }],
  ['zh', { translationKey: 'languages.zh_language', name: 'chinese_traditional', code: 'zh-Hant-HK', language: '', cultureCode: '' }]
]);

export const languageDefaultDateFormat = new Map<
  string,
  { shortDate: string; shortDateTime: string; shortDateTimeHourMinute: string; longDate: string; longDateTime: string; shortDate_dashSeparated: string; shortDateTimeHourMinuteAlt: string }
>([
  [
    'en',
    {
      shortDate: 'MM/dd/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'MMM dd yyyy',
      longDateTime: 'MM/dd/yyyy h:mm a',
      shortDate_dashSeparated: 'MM-dd-yyyy',
      shortDateTimeHourMinuteAlt: 'h:mm a'
    }
  ],
  [
    'fr-FR',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ],
  [
    'fr-CA',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'hh:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ],
  [
    'es-MX',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'hh:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm a',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'h:mm a'
    }
  ],
  [
    'es-ES',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'hh:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm a',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'h:mm a'
    }
  ],
  [
    'zh-Hant-HK',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'hh:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'MM/dd/yyyy h:mm a',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'h:mm a'
    }
  ],
  [
    'de',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'hh:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm a',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'h:mm a'
    }
  ],
  [
    'ja',
    {
      shortDate: 'yyyy/MM/dd',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'yyyy dd MMM',
      longDateTime: 'yyyy/MM/dd h:mm a',
      shortDate_dashSeparated: 'yyyy-MM-dd',
      shortDateTimeHourMinuteAlt: 'h:mm a'
    }
  ],
  [
    'cs',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ],
  [
    'nl',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ],
  [
    'pl',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ],
  [
    'pt',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ],
  [
    'sk',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ],
  [
    'it',
    {
      shortDate: 'dd/MM/yyyy',
      shortDateTime: 'HH:mm:ss',
      shortDateTimeHourMinute: 'HH:mm',
      longDate: 'dd MMM yyyy',
      longDateTime: 'dd/MM/yyyy h:mm',
      shortDate_dashSeparated: 'dd-MM-yyyy',
      shortDateTimeHourMinuteAlt: 'HH:mm'
    }
  ]
]);

export const assets = environment.assets;

export const ICONS_PATH = {
  LOCK: 'images/Lock.svg',
  INHERITANCE_BLACK: `${assets}${encodeURIComponent('images/inheritance-black.svg')}`,
  SUBSCRIPTION: `${assets}${encodeURIComponent('images/Menu-Subscribe.svg')}`,
  WARNING: 'images/warning.svg',
  WARNING_RED: `${assets}${encodeURIComponent('images/warningRed.svg')}`,
  SELECTIONS: 'images/UserInputIcNome.svg'
};

export const HOURS_COLOR_PALETTE = new Map<number, string[]>([
  // based on this page https://coolors.co/
  [0, ['#034732', '#C6C013', '#EF2917']],
  [1, ['#7C6A0A', '#FFDAC6', '#EB6424']],
  [2, ['#C2E812', '#8B9EB7', '#632A50']],
  [3, ['#776D5A', '#A09CB0', '#ABDAE1']],
  [4, ['#20BF55', '#01BAEF', '#757575']],
  [5, ['#D5C5C8', '#604D53', '#FFDBDA']],
  [6, ['#07004D', '#42E2B8', '#EB8A90']],
  [7, ['#DEF6CA', '#F497DA', '#F65BE3']],
  [8, ['#EAE0CC', '#A0A083', '#4D6A6D']],
  [9, ['#5C0029', '#857C8D', '#ACF7C1']],
  [10, ['#040403', '#8EB897', '#9DDBAD']],
  [11, ['#BBDEF0', '#EFCA08', '#F08700']],
  [12, ['#E26D5A', '#D7BBA8', '#3E2A35']],
  [13, ['#D0FEF5', '#4A051C', '#830A48']],
  [14, ['#E0ACD5', '#F4EBE8', '#6A3E37']],
  [15, ['#6CD4FF', '#CFBFF7', '#83858C']],
  [16, ['#454851', '#7BAE7F', '#FCEFF9']],
  [17, ['#822E81', '#F0F2A6', '#1A1B25']],
  [18, ['#EECF6D', '#8B6220', '#45050C']],
  [19, ['#820933', '#D2FDFF', '#26FFE6']],
  [20, ['#546A76', '#B4CEB3', '#FAD4D8']],
  [21, ['#E3E7D3', '#989C94', '#E6E49F']],
  [22, ['#6F2DBD', '#B298DC', '#B9FAF8']],
  [23, ['#F7B2B7', '#DE639A', '#16001E']] // this is added for advanced task 11 pm timeframe (which is not present for Temp due to 2-days restriction policy i.e. 11pm-12am)
]);

export const DEFAULT_TIME_RANGES = [
  {
    id: 0,
    title: 'date_picker.last_7days',
    hours: 0,
    days: -6
  },
  {
    id: 1,
    title: 'date_picker.last_14days',
    hours: 0,
    days: -13
  },
  {
    id: 2,
    title: 'date_picker.last_30days',
    hours: 0,
    days: -29
  },
  {
    id: 3,
    title: 'date_picker.last_90days',
    hours: 0,
    days: -89
  }
];

export const TIME_PICKER_DEFAULT_START_DATE = { timeUnit: 'day', amount: -29 };
