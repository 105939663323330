import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NomeCustomFloatButtonComponent } from './nome-custom-float-button.component';
import { CustomFloatButtonModule } from '../../components/custom-float-button/custom-float-button.module';

@NgModule({
  imports: [CommonModule, CustomFloatButtonModule, MatIconModule, MatButtonModule, TranslateModule.forChild()],
  exports: [NomeCustomFloatButtonComponent],
  declarations: [NomeCustomFloatButtonComponent]
})
export class NomeCustomFloatButtonModule {}
