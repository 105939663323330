export enum Temperature {
  Celsius = 'C',
  Fahrenheit = 'F',
  DegreeCelsius = '°C',
  DegreeFahrenheit = '°F'
}

export enum TemperatureUomId {
  Celsius = 1,
  Fahrenheit = 2
}
