import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS_PATH } from '../constants.config';
import { environment } from './../../../environments/environment';
import { CustomIcon } from './../models/custom-icon.model';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class IconRegistrationService {
  icons: CustomIcon[] = [
    new CustomIcon('lang', `${environment.assets}images/lang.svg`),
    new CustomIcon('green', `${environment.assets}images/status-icons/Green.svg`),
    new CustomIcon('export_csv', `${environment.assets}images/ExportCsv.svg`),
    new CustomIcon('export_pdf', `${environment.assets}images/ExportPdf.svg`),
    new CustomIcon('export', `${environment.assets}images/Export.svg`),
    new CustomIcon('import', `${environment.assets}images/import.svg`),
    new CustomIcon('edit_custom', `${environment.assets}images/Edit.svg`),
    new CustomIcon('card_view', `${environment.assets}images/Card-View.svg`),
    new CustomIcon('list_view', `${environment.assets}images/List-View.svg`),
    new CustomIcon('filtering', `${environment.assets}images/Filtering.svg`),
    new CustomIcon('menu.dashboard', `${this.brandImagesAssets}menu-icons/MenuIcon-Dash.svg`),
    new CustomIcon('menu.acct_mgmnt', `${this.brandImagesAssets}menu-icons/MenuIcon-Account-management.svg`),
    new CustomIcon('menu.label_designer', `${this.brandImagesAssets}menu-icons/MenuIcon-LabelManager.svg`),
    new CustomIcon('menu.menu_mgr', `${this.brandImagesAssets}menu-icons/MenuIcon-MenuManager.svg`),
    new CustomIcon('menu.loc_mgr', `${this.brandImagesAssets}menu-icons/MenuIcon-LocationManager.svg`),
    new CustomIcon('menu.media_mgr', `${this.brandImagesAssets}menu-icons/MenuIcon-MediaManager.svg`),
    new CustomIcon('menu.analytics', `${this.brandImagesAssets}menu-icons/MenuIcon-Report.svg`),
    new CustomIcon('menu.nutrition_facts', `${this.brandImagesAssets}menu-icons/MenuIcon-NutritionFact.svg`),
    new CustomIcon('menu.settings', `${this.brandImagesAssets}menu-icons/MenuIcon-Settings.svg`),
    new CustomIcon('menu.resellers', `${this.brandImagesAssets}menu-icons/MenuIcon-LocationManager.svg`),
    new CustomIcon('exit_impersonation', `${environment.assets}images/ExitImpersonation.svg`),
    new CustomIcon('menus', `${this.brandImagesAssets}menu-icons/MenuIcon-MenuManager.svg`),
    new CustomIcon('products', `${environment.assets}images/menu-manager/MenuItems.svg`),
    new CustomIcon('ingredients', `${environment.assets}images/menu-manager/Ingredients.svg`),
    new CustomIcon('addons', `${environment.assets}images/menu-manager/Combos.svg`),
    new CustomIcon('tasks', `${environment.assets}images/menu-manager/Task.svg`),
    new CustomIcon('recipes', `${environment.assets}images/menu-manager/Recipe.svg`),
    new CustomIcon('price_fill', `${environment.assets}images/menu-manager/Price_Fill.svg`),
    new CustomIcon('grouptags', `${environment.assets}images/menu-manager/GroupTag.svg`),
    new CustomIcon('terminalUser', `${environment.assets}images/TerminalUser.svg`),
    new CustomIcon('inherited', `${environment.assets}images/Inh.svg`),
    new CustomIcon('portalUser', `${environment.assets}images/DeviceOth.svg`),
    new CustomIcon('folder', `${environment.assets}images/Node_Folder.svg`),
    new CustomIcon('folder-open', `${environment.assets}images/Folder-Open.svg`),
    new CustomIcon('store', `${environment.assets}images/Store.svg`),
    new CustomIcon('new_folder', `${environment.assets}images/NewFolder.svg`),
    new CustomIcon('customized', `${environment.assets}images/CustomizedMenu.svg`),
    new CustomIcon('menu-published', `${environment.assets}/images/PublishMenuDone_dark.svg`),
    new CustomIcon('menu-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('timer-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('checklist-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('media-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('equipment-subscribed', `${environment.assets}images/SubscriptionCircle.svg`),
    new CustomIcon('equipment-sensors', `${environment.assets}images/sensors.svg`),
    new CustomIcon('view-actual-size', `${environment.assets}/images/ViewActualSize.svg`),
    new CustomIcon('portrait', `${environment.assets}/images/portrait.svg`),
    new CustomIcon('landscape', `${environment.assets}/images/landscape.svg`),
    new CustomIcon('zoom-in', `${environment.assets}/images/zoom in.svg`),
    new CustomIcon('zoom-out', `${environment.assets}/images/zoom out.svg`),
    new CustomIcon('checklist-published', `${environment.assets}/images/PublishMenuDone_dark.svg`),
    new CustomIcon('checklist-queued', `${environment.assets}/images/PublishMenu_dark.svg`),
    new CustomIcon('user', `${this.brandImagesAssets}menu-icons/user-icon.svg`),
    new CustomIcon('checklists', `${environment.assets}images/Checklists.svg`),
    new CustomIcon('ops', `${this.brandImagesAssets}ops.svg`),
    new CustomIcon('temp', `${this.brandImagesAssets}Temp.svg`),
    new CustomIcon('arrow_up_down', `${environment.assets}images/move_alt_icons/move_alt-1.svg`),
    new CustomIcon('arrow_all', `${environment.assets}images/move_alt_icons/move_alt-2.svg`),
    new CustomIcon('arrow_left', `${environment.assets}images/move_alt_icons/move_alt-3.svg`),
    new CustomIcon('arrow_right', `${environment.assets}images/move_alt_icons/move_alt-5.svg`),
    new CustomIcon('arrow_up_down_right', `${environment.assets}images/move_alt_icons/move_alt-6.svg`),
    new CustomIcon('arrow_left_right', `${environment.assets}images/move_alt_icons/move_alt-4.svg`),
    new CustomIcon('opschecklist', `${this.brandImagesAssets}OpsChecklist.svg`),
    new CustomIcon('tempchecklist', `${this.brandImagesAssets}TempChecklist.svg`),
    new CustomIcon('employeeSurvey', `${this.brandImagesAssets}EmployeeSurvey.svg`),
    new CustomIcon('subscription', `${environment.assets}images/Menu-Subscribe.svg`),
    new CustomIcon('locationPin', `${environment.assets}images/LocationPin.svg`),
    new CustomIcon('locationPinGrey', `${environment.assets}images/LocationPinGrey.svg`),
    new CustomIcon('locationPinHalfGrey', `${environment.assets}images/LocationPinHalfGrey.svg`),
    new CustomIcon('dialog-icon', `${environment.assets}images/InputTypesIc.svg`),
    new CustomIcon('warning', `${environment.assets}${ICONS_PATH.WARNING}`),
    new CustomIcon('red_warning', ICONS_PATH.WARNING_RED),
    new CustomIcon('timers', `${this.brandImagesAssets}menu-icons/timer.svg`),
    new CustomIcon('locked', `${environment.assets}images/Lock.svg`),
    new CustomIcon('qr_code_scan', `${environment.assets}images/qr code scan.svg`),
    new CustomIcon('add', `${environment.assets}images/AddTranslation.svg`),
    new CustomIcon('edit', `${environment.assets}images/EditTranslation.svg`),
    new CustomIcon('view', `${environment.assets}images/ViewTranslation.svg`),

    new CustomIcon('snap-top', `${environment.assets}images/label-designer/style/snap top.svg`),
    new CustomIcon('snap-bottom', `${environment.assets}images/label-designer/style/snap bottom.svg`),
    new CustomIcon('snap-center', `${environment.assets}images/label-designer/style/snap center.svg`),
    new CustomIcon('snap-left', `${environment.assets}images/label-designer/style/snap left.svg`),
    new CustomIcon('snap-middle', `${environment.assets}images/label-designer/style/snap middle.svg`),
    new CustomIcon('snap-right', `${environment.assets}images/label-designer/style/snap right.svg`),
    new CustomIcon('group_align_top', `${environment.assets}images/label-designer/style/group align top.svg`),
    new CustomIcon('group_align_bottom', `${environment.assets}images/label-designer/style/group align bottom.svg`),
    new CustomIcon('group_align_left', `${environment.assets}images/label-designer/style/group align left.svg`),
    new CustomIcon('group_align_right', `${environment.assets}images/label-designer/style/group align right.svg`),
    new CustomIcon('group_align_center', `${environment.assets}images/label-designer/style/group align center.svg`),
    new CustomIcon('group_align_middle', `${environment.assets}images/label-designer/style/group align middle.svg`),
    new CustomIcon('undo', `${environment.assets}images/label-designer/style/undo.svg`),
    new CustomIcon('delete', `${environment.assets}images/label-designer/style/delete.svg`),
    new CustomIcon('align_center', `${environment.assets}images/label-designer/style/align center.svg`),
    new CustomIcon('align_left', `${environment.assets}images/label-designer/style/align left.svg`),
    new CustomIcon('align_right', `${environment.assets}images/label-designer/style/align right.svg`),
    new CustomIcon('black_background', `${environment.assets}images/label-designer/style/black background.svg`),
    new CustomIcon('rtl', `${environment.assets}images/label-designer/style/rtl.svg`),
    new CustomIcon('ltr', `${environment.assets}images/label-designer/style/ltr.svg`),
    new CustomIcon('rotate', `${environment.assets}images/label-designer/style/rotate.svg`),
    new CustomIcon('expand', `${environment.assets}images/label-designer/style/expand.svg`),
    new CustomIcon('shrink', `${environment.assets}images/label-designer/style/shrink.svg`),
    new CustomIcon('underline', `${environment.assets}images/label-designer/style/underline.svg`),
    new CustomIcon('white_background', `${environment.assets}images/label-designer/style/white background.svg`),

    new CustomIcon('grid', `${environment.assets}images/label-designer/canvas options/grid.svg`),
    new CustomIcon('snap_to_grid', `${environment.assets}images/label-designer/canvas options/snap to grid.svg`),
    new CustomIcon('ruler', `${environment.assets}images/label-designer/canvas options/ruler.svg`),
    new CustomIcon('square_plus', `${environment.assets}images/label-designer/canvas options/square plus.svg`),

    new CustomIcon('addons', `${environment.assets}images/label-designer/objects/addons.svg`),
    new CustomIcon('address', `${environment.assets}images/label-designer/objects/address.svg`),
    new CustomIcon('allergens', `${environment.assets}images/label-designer/objects/allergens.svg`),
    new CustomIcon('barcode-value', `${environment.assets}images/label-designer/objects/barcode value.svg`),
    new CustomIcon('barcode', `${environment.assets}images/label-designer/objects/barcode.svg`),
    new CustomIcon('batch-count', `${environment.assets}images/label-designer/objects/batch count.svg`),
    new CustomIcon('calculated-field', `${environment.assets}images/label-designer/objects/calculated field.svg`),
    new CustomIcon('conditional-text', `${environment.assets}images/label-designer/objects/conditional text.svg`),
    new CustomIcon('calories', `${environment.assets}images/label-designer/objects/calories.svg`),
    new CustomIcon('caption', `${environment.assets}images/label-designer/objects/caption.svg`),
    new CustomIcon('category', `${environment.assets}images/label-designer/objects/category.svg`),
    new CustomIcon('city', `${environment.assets}images/label-designer/objects/city.svg`),
    new CustomIcon('color', `${environment.assets}images/label-designer/objects/color.svg`),
    new CustomIcon('composite-field', `${environment.assets}images/label-designer/objects/composite field.svg`),
    new CustomIcon('current-price', `${environment.assets}images/label-designer/objects/current price.svg`),
    new CustomIcon('date-shelf-life-cf', `${environment.assets}images/label-designer/objects/date shelf life cf.svg`),
    new CustomIcon('date', `${environment.assets}images/label-designer/objects/date.svg`),
    new CustomIcon('decimal-cf', `${environment.assets}images/label-designer/objects/decimal cf.svg`),
    new CustomIcon('department', `${environment.assets}images/label-designer/objects/department.svg`),
    new CustomIcon('description', `${environment.assets}images/label-designer/objects/description.svg`),
    new CustomIcon('discard-date', `${environment.assets}images/label-designer/objects/discard date.svg`),
    new CustomIcon('discount-rate', `${environment.assets}images/label-designer/objects/discount rate.svg`),
    new CustomIcon('discounted-calculated-field', `${environment.assets}images/label-designer/objects/discounted calculated field.svg`),
    new CustomIcon('dow', `${environment.assets}images/label-designer/objects/dow.svg`),
    new CustomIcon('email', `${environment.assets}images/label-designer/objects/email.svg`),
    new CustomIcon('employee-id', `${environment.assets}images/label-designer/objects/employee id.svg`),
    new CustomIcon('employee-name', `${environment.assets}images/label-designer/objects/employee name.svg`),
    new CustomIcon('end-date', `${environment.assets}images/label-designer/objects/end date.svg`),
    new CustomIcon('expiration-date', `${environment.assets}images/label-designer/objects/expiration date.svg`),
    new CustomIcon('group-tags', `${environment.assets}images/label-designer/objects/group tags.svg`),
    new CustomIcon('image-cf', `${environment.assets}images/label-designer/objects/image cf.svg`),
    new CustomIcon('image', `${environment.assets}images/label-designer/objects/image.svg`),
    new CustomIcon('ingredients', `${environment.assets}images/label-designer/objects/ingredients.svg`),
    new CustomIcon('labeling-logo', `${environment.assets}images/label-designer/objects/labeling logo.svg`),
    new CustomIcon('line', `${environment.assets}images/label-designer/objects/line.svg`),
    new CustomIcon('list-cf', `${environment.assets}images/label-designer/objects/list cf.svg`),
    new CustomIcon('name', `${environment.assets}images/label-designer/objects/name.svg`),
    new CustomIcon('net-weight', `${environment.assets}images/label-designer/objects/net weight.svg`),
    new CustomIcon('number', `${environment.assets}images/label-designer/objects/number.svg`),
    new CustomIcon('nutritional-panel-widget', `${environment.assets}images/label-designer/objects/nutritional panel widget.svg`),
    new CustomIcon('phone', `${environment.assets}images/label-designer/objects/phone.svg`),
    new CustomIcon('prep-description', `${environment.assets}images/label-designer/objects/prep description.svg`),
    new CustomIcon('price-per-weight', `${environment.assets}images/label-designer/objects/price per weight.svg`),
    new CustomIcon('price', `${environment.assets}images/label-designer/objects/price.svg`),
    new CustomIcon('recipe-composition', `${environment.assets}images/label-designer/objects/recipe composition.svg`),
    new CustomIcon('rectangle', `${environment.assets}images/label-designer/objects/rectangle.svg`),
    new CustomIcon('serial-cf', `${environment.assets}images/label-designer/objects/serial cf.svg`),
    new CustomIcon('size', `${environment.assets}images/label-designer/objects/size.svg`),
    new CustomIcon('sku', `${environment.assets}images/label-designer/objects/sku.svg`),
    new CustomIcon('start-date', `${environment.assets}images/label-designer/objects/start date.svg`),
    new CustomIcon('state', `${environment.assets}images/label-designer/objects/state.svg`),
    new CustomIcon('tare-weight', `${environment.assets}images/label-designer/objects/tare weight.svg`),
    new CustomIcon('text-cf', `${environment.assets}images/label-designer/objects/text cf.svg`),
    new CustomIcon('text', `${environment.assets}images/label-designer/objects/text.svg`),
    new CustomIcon('timer-name', `${environment.assets}images/label-designer/objects/timer name.svg`),
    new CustomIcon('use-by-description', `${environment.assets}images/label-designer/objects/use by description.svg`),
    new CustomIcon('voice-pick-code', `${environment.assets}images/label-designer/objects/voice pick code.svg`),
    new CustomIcon('weight-scale', `${environment.assets}images/label-designer/objects/weight scale.svg`),
    new CustomIcon('zip', `${environment.assets}images/label-designer/objects/zip.svg`),
    new CustomIcon('download-file', `${environment.assets}images/download-file.svg`),
    new CustomIcon('order-scale', `${environment.assets}images/OrderScale.svg`),
    new CustomIcon('catalogs', `${environment.assets}images/catalog-manager/Catalog.svg`),
    new CustomIcon('catalogProduct', `${environment.assets}images/catalog-manager/Product.svg`),
    new CustomIcon('refresh', `${environment.assets}images/label-designer/ios-refresh.svg`),
    new CustomIcon('twitter-x', `${environment.assets}images/twitter-x.svg`),
    new CustomIcon('chart', `${environment.assets}images/chart.svg`),
    new CustomIcon('tabular', `${environment.assets}images/tabular.svg`),
    new CustomIcon('maximize', `${environment.assets}images/maximize.svg`),
    new CustomIcon('minimize', `${environment.assets}images/minimize.svg`),
    new CustomIcon('breadcrumb-arrow', `${environment.assets}images/breadcrumb arrow.svg`),
    new CustomIcon('search', `${environment.assets}images/search.svg`),
    new CustomIcon('plus_circle', `${environment.assets}images/plus_circle.svg`),
    new CustomIcon('trash', `${environment.assets}images/trash.svg`)
  ];
  constructor(private matIconRegistryService: MatIconRegistry, private domSanitizer: DomSanitizer, private themeService: ThemeService) {}

  registerIcons() {
    this.icons.forEach((icon) => {
      const iconUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url);
      this.matIconRegistryService.addSvgIcon(icon.name, iconUrl);
    });
  }

  private get brandImagesAssets(): string {
    return this.themeService.getbrandImagesAssets();
  }
}
