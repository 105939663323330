export enum ReportSubject {
  menuitems = 1,
  users = 2,
  media = 3,
  label = 4,
  TEMP_INGREDIENT = 5,
  SENSORS_ALERTS_HISTORY = 6,
  SENSORS_TEMPERATURES = 7,
  SENSORS_HUMIDITY = 8,
  SENSORS_BATTERY = 9,
  SENSORS_ALERTS_COUNT = 10,
  SENSORS_LATEST_READINGS = 11,
  SENSORS_STATUS = 12,
  EQUIPMENTS_STATUS = 13,
  EQUIPMENTS_LATEST_READINGS = 14,
  EQUIPMENTS_TEMPERATURES = 15,
  EQUIPMENTS_HUMIDITY = 16,
  EQUIPMENTS_BATTERY = 17,
  EQUIPMENTS_ALERTS_COUNT = 18,
  EQUIPMENTS_ALERTS_HISTORY = 19,
  SENSORS_OUT_OF_RANGE = 20,
  CORPORATE_MAP = 21,
  CORPORATE_ALERTS_COUNT = 22,
  CORPORATE_SENSORS_OUTOFRANGE_LATEST_READINGS = 23,
  CORPORATE_EQUIPMENTS_OUTOFRANGE_LATEST_READINGS = 24,
  TEMP_SESSIONS_STATUS = 25,
  TEMP_MISSING_SESSIONS = 26,
  TEMP_INGREDIENT_STATUS = 27,
  TEMP_INGREDIENT_OUT_OF_RANGE = 28,
  TEMP_INGREDIENT_AVERAGE_SESSION = 29,
  TEMP_INGREDIENT_NOT_TEMPED = 30,
  TEMP_AVERAGE_COMPLIANCE_RATE = 31,
  TEMP_INGREDIENT_IN_RANGE_COMP = 32,
  TEMP_INGREDIENT_MANUAL_INPUT_PERCENTAGE = 33,
  TEMP_INGREDIENT_OUT_OF_RANGE_LIST = 34,
  TEMP_MISSING_SESSIONS_FLAT = 35,
  TEMP_COMPLIANCE_RATE = 36,
  TEMP_INGREDIENT_MANUAL_VS_APP_INPUT = 37,
  TEMP_MISSING_SESSIONS_OCCURENCES = 38,
  TEMP_AVERAGE_SESSION_TIME_FLAT = 39,
  TEMP_INGREDIENT_OUT_OF_RANGE_COUNTS = 40,
  TEMP_INGREDIENT_IN_RANGE_COMP_STORE = 41,
  TEMP_SESSIONS_TIME = 42,
  TEMP_SESSION_STATUS_REPORT_FOR_LOCATION_MANAGER = 43,
  TEMP_INGREGIENT_HISTORY = 44,
  DAILY_PRODUCTION_REPORT = 45,
  OPS_TASK_EXECUTIONS = 46,
  OPS_TASK_EXECUTIONS_EXPORT = 47,
  CORPORATE_SENSORS_LATEST_READINGS = 48,
  CORPORATE_EQUIPMENTS_LATEST_READINGS = 49,
  CORPORATE_AGGREGATED_REQUIRED_TASKS_COMPLETION_RATE = 50,
  CORPORATE_UNAGGREGATED_REQUIRED_TASKS_COMPLETION_RATE = 51,
  TEMPERATURE_AND_PH_MEASUREMENT = 62,
  LOCATION_REQUIRED_TASKS_COMPLETION_RATE = 63,
  // TO USE the enum used at the BE from now on, so that if any report is added to report on demand, report name is translated correctly
  CORPORATE_AGGREGATED_PH_INGREDIENTS_STATUS = 67,
  CORPORATE_AGGREGATED_PH_OUT_OF_INGREDIENTS = 68,
  CORPORATE_AGGREGATED_PH_IN_RANGE_INGREDIENTS = 69,
  CORPORATE_AGGREGATED_PH_MISSED_INGREDIENTS = 70,
  CORPORATE_FLAT_PH_INGREDIENTS_STATUS = 71,
  CORPORATE_FLAT_PH_OUT_OF_INGREDIENTS = 72,
  CORPORATE_FLAT_PH_IN_RANGE_INGREDIENTS = 73,
  CORPORATE_FLAT_PH_MISSED_INGREDIENTS = 74,
  CORPORATE_FLAT_OTHER_TASKS_TEMPERATURE_MEASUREMENTS_STATUS_INGREDIENTS = 84, // TODO: remove Ingredients from subject name , It can not be ingredient and other task at the same time
  CORPORATE_FLAT_OTHER_TASKS_PH_MEASUREMENTS_STATUS_INGREDIENTS = 85,
  CORPORATE_AGGREGATED_OTHER_TASKS_PH_MEASUREMENTS_STATUS_INGREDIENTS = 75,
  CORPORATE_AGGREGATED_OTHER_TASKS_TEMPERATURE_MEASUREMENTS_OUT_OF_RANGE_INGREDIENTS = 80,
  CORPORATE_AGGREGATED_OTHER_TASKS_PH_MEASUREMENTS_OUT_OF_RANGE_INGREDIENTS = 76,
  CORPORATE_AGGREGATED_OTHER_TASKS_PH_MEASUREMENTS_NOT_TEMPED = 77,
  CORPORATE_AGGREGATED_OTHER_TASKS_TEMPERATURE_MEASUREMENTS_IN_RANGE_INGREDIENTS = 82,
  CORPORATE_AGGREGATED_OTHER_TASKS_PH_MEASUREMENTS_IN_RANGE_INGREDIENTS = 78,
  CORPORATE_AGGREGATED_OTHER_TASKS_TEMPERATURE_MEASUREMENTS_STATUS_INGREDIENTS = 79,
  CORPORATE_AGGREGATED_OTHER_TASKS_TEMPERATURE_MEASUREMENTS_NOT_TEMPED = 81,
  LOCATION_MANAGER_PH_MEASUREMENTS_STATUS_INGREDIENTS = 83,
  CORPORATE_FLAT_OTHER_TASKS_Missed_TEMPERATURE_MEASUREMENTS_STATUS = 84,
  CORPORATE_FLAT_OTHER_TASKS_Missed_PH_MEASUREMENTS_STATUS = 85,
  CORPORATE_FLAT_OTHER_TASKS_OUT_OF_RANGE_TEMPERATURE_MEASUREMENTS_STATUS = 86,
  CORPORATE_FLAT_OTHER_TASKS_OUT_OF_RANGE_PH_MEASUREMENTS_STATUS = 87,
  CORPORATE_FLAT_OTHER_TASKS_IN_RANGE_TEMPERATURE_MEASUREMENTS_STATUS = 88,
  CORPORATE_FLAT_OTHER_TASKS_IN_RANGE_PH_MEASUREMENTS_STATUS = 89,
  LOCATION_MANAGER_OTHER_TASKS_TEMPERATURE_MEASUREMENTS_STATUS = 90,
  LOCATION_MANAGER_OTHER_TASKS_PH_MEASUREMENTS_STATUS = 91,
  CORPORATE_AGGREGATED_OUT_OF_RANGE_INGREDIENT_LIST_PH = 93,
  INGREDIENT_PH_DAILY_STATUS = 94,
  LABEL_EXPIRY_AGGREGATED = 95,
  LABEL_EXPIRY_AGGREGATED_STATIONS = 96,
  MEDIA_DATE = 97,
  MEDIA_DATE_AGGREGATED = 98,
  MEDIA_DATE_AGGREGATED_STATIONS = 99,
  MEDIA_DOW = 100,
  MEDIA_DOW_AGGREGATED = 101,
  MEDIA_DOW_AGGREGATED_STATIONS = 102,
  MEDIA_HOUR = 103,
  MEDIA_HOUR_AGGREGATED = 104,
  MEDIA_HOUR_AGGREGATED_STATIONS = 105,
  USER_DATE = 106,
  USER_DATE_AGGREGATED = 107,
  USER_DATE_AGGREGATED_STATIONS = 108,
  USER_DOW = 109,
  USER_DOW_AGGREGATED = 110,
  USER_DOW_AGGREGATED_STATIONS = 111,
  USER_HOUR = 112,
  USER_HOUR_AGGREGATED = 113,
  USER_HOUR_AGGREGATED_STATIONS = 114,
  MENU_ITEM_DATE = 115,
  MENU_ITEM_DATE_AGGREGATED = 116,
  MENU_ITEM_DATE_AGGREGATED_STATIONS = 117,
  MENU_ITEM_DOW = 118,
  MENU_ITEM_DOW_AGGREGATED = 119,
  MENU_ITEM_DOW_AGGREGATED_STATIONS = 120,
  MENU_ITEM_HOUR = 121,
  MENU_ITEM_HOUR_AGGREGATED = 122,
  MENU_ITEM_HOUR_AGGREGATED_STATIONS = 123,
  CORPORATE_AGGREGATED_TEMP_MEASUREMENTS_BY_WORKFLOW = 124,
  CORPORATE_FLAT_TEMP_MEASUREMENTS_BY_WORKFLOW = 125,
  CHECKLIST_TASK_STATUS = 126,
  CHECKLIST_STATUS = 127,
  CORPORATE_AGGREGATED_PH_MEASUREMENTS_BY_WORKFLOW = 128,
  CORPORATE_FLAT_PH_MEASUREMENTS_BY_WORKFLOW = 129,
  DETAILED_TASK = 130,
  RANKING_BY_LOCATION = 131,
  EQUIPMENT_TEMPERATURE_STATUS_AGGREGATE = 133,
  EQUIPMENT_TEMPERATURE_STATUS_FLAT = 134,
  EQUIPMENT_TEMPERATURE_STATUS_STORE = 135,
  RFID_EPC_LABELS_REPORT = 136,
  MENU_ITEM_PRINT_TIME = 137,
  PrintedLabelsPerSize = 138,
  RETAIL_LABEL_PRINTS_PER_PRODUCT = 139,
  CORPORATE_AGGREGATE_REQUIRED_ACTIONS = 140,
  CORPORATE_AGGREGATE_ALL_ACTIONS = 141,
  RETAIL_LABEL_PRINTS_PER_EMPLOYEE = 142,
  CORPORATE_FLAT_REQUIRED_ACTIONS = 143,
  CORPORATE_FLAT_ALL_ACTIONS = 144,
  RETAIL_LABEL_PRINTS = 145,
  RETAIL_LABEL_PRINTS_PER_LABEL_SIZE = 146,
  RETAIL_PRODUCTS = 147,
  RETAIL_SCANS = 148
}
