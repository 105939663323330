import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { arrayIsNotDefinedOrEmpty, deepClone, definedAndNotEmptyString, getObjectPropertyValue, notDefined, notDefinedOrEmptyString } from '../../helpers/app.helpers';
import { NodeTypeEnum } from '../../models/enums/node-type.enum';
import { TranslationKeyAction } from '../../models/translation-key-action.model';
import { TreeSearchEvent } from '../../models/tree-search-event.model';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { TreeTypeEnum } from './../../models/enums/TreeTypes.enum';
import { TreeCmp, TreeNode } from './../../models/Tree-Node.model';
import { TreeService } from '../../services/tree.service';

@Component({
  selector: 'nome-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit, OnChanges {
  @ViewChild('mat_tree') mat_tree: MatTree<TreeNode>;
  @Output('expandClicked') expandClicked: EventEmitter<TreeNode> = new EventEmitter();
  @Output('nodeSelected') nodeSelected: EventEmitter<TreeNode> = new EventEmitter();
  @Output('nodeChecked') nodeChecked: EventEmitter<TreeNode> = new EventEmitter();
  @Output('checkboxChangedEvent') checkboxChangedEvent: EventEmitter<any> = new EventEmitter();
  @Input('treeCmp') treeCmp: TreeCmp;
  @Input('treetitle') treetitle = '';
  @Input('selectable') selectable = false;
  @Input('selectionType') selectionType = '';
  @Input('showtitle') showtitle = true;
  @Input('treeItems') treeItems: TreeNode[] = [];
  @Input('isInsideDropDown') isInsideDropDown = false;
  @Input('isPopulatedInMenuItem') isPopulatedInMenuItem = false;
  @Input('isSideNavTree') isSideNavTree = false;
  @Input('treeSubtitle') treeSubtitle = '';
  @Input('checkedLocations') checkedLocations = [];
  @Input('expandRootOnLoad') expandRootOnLoad: boolean = false;
  checkedLocationsUsed: boolean;
  @Input('showSelectionForStoreLevelOnly') showSelectionForStoreLevelOnly: boolean = false;
  @Input('onlyShowSelected') onlyShowSelected: boolean = false;
  @Input('showInTree') showInTree: TreeNode = null;
  @Input('ignoreDescendants') ignoreDescendants: boolean = false;
  @Input('currentTreeType') currentTreeType: number;
  @Input('doNotClearSearchFieldWhenNodeIsSelected') doNotClearSearchFieldWhenNodeIsSelected: boolean = false;
  @Input() fixedTableLayout = true;
  flatNodeMap = new Map<number, TreeNode>();
  treeControl: FlatTreeControl<TreeNode>;
  treeFlattener: MatTreeFlattener<TreeNode, TreeNode>;
  dataSource: MatTreeFlatDataSource<TreeNode, TreeNode>;
  checkedNodes = {
    array: [],
    addIfNotExist: (...nodes: TreeNode[]) => {
      nodes.forEach((node) => {
        if (!this.checkedNodes.exist(node)) this.checkedNodes.array.push(node);
      });
    },
    removeIfExist: (...nodes: TreeNode[]) => {
      nodes.forEach((node) => {
        if (this.checkedNodes.exist(node)) this.checkedNodes.array.splice(this.checkedNodes.index(node), 1);
      });
    },
    toggle: (node: TreeNode) => {
      if (this.checkedNodes.exist(node)) {
        this.checkedNodes.removeIfExist(node);
      } else {
        this.checkedNodes.addIfNotExist(node);
      }
    },
    exist: (node: TreeNode) => {
      return (this.checkedNodes.array as TreeNode[]).some((n) => n.id === node.id);
    },
    index: (node: TreeNode) => {
      return (this.checkedNodes.array as TreeNode[]).findIndex((n) => n.id === node.id);
    }
  }; // used instead of SelectionModel because SelectionModel relies on object reference which is not suitable when searching (cloning is being done there)
  referencedSpinner;
  expandedTreeNodes: TreeNode[];
  selectedNode: TreeNode;
  assets: string = environment.assets;
  nodeTypeEnum = NodeTypeEnum;
  searchField: string;
  searchForm = new UntypedFormGroup({ searchField: new UntypedFormControl('') });
  lastHighlightedNode: TreeNode;
  lastFilteredTreeBranch: TreeNode;
  treetypes = TreeTypeEnum;

  @Output() private nodeExpandedAndRequestingItsChildren = new EventEmitter<TreeNode>();
  @Input() private lazyLoad = false;
  @Output() private search = new EventEmitter<TreeSearchEvent>();
  @Output('searchFieldValueChange') searchFieldValueChangeEventEmitter = new EventEmitter<string>();

  @Input() contextMenuItems: TranslationKeyAction[];
  @Input() idsThatDoesntHaveContextMenu: number[];
  contextMenuComponent = ContextMenuComponent;

  private get menuItemTree(): boolean {
    return this.currentTreeType === this.treetypes.ProductCategory || this.currentTreeType === this.treetypes.IngredientCategory;
  }

  private get menuItemCategoryTree(): boolean {
    return this.currentTreeType === this.treetypes.MenuItemCategory;
  }

  @Input() nonLeafNodeRightContentTemplate: TemplateRef<any>;

  private get searchIsEmpty(): boolean {
    return notDefinedOrEmptyString(this.searchField);
  }

  constructor(private treeService: TreeService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);

    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);

    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checkedLocations']) {
      this.checkedLocationsUsed = true;
    }
    if (changes.treeItems) {
      if (this.referencedSpinner) {
        this.hideFolderSpinner(this.referencedSpinner);
      }
      if (this.treeItems) {
        if (!this.lazyLoad) {
          this.clearSearchField();
        }
        let node = this.treeItems.find((x) => {
          return x.highlightOnLoad;
        });
        if (node) {
          this.highlightNode(node);
        }
        if (!this.lazyLoad || this.menuItemTree || this.menuItemCategoryTree) {
          this.expandNodes(this.treeItems, this.expandedTreeNodes);
          this.expandNode(this.treeItems, (node) => node.selected);
        }
        if (this.expandRootOnLoad && this.lazyLoad && this.treeItems[0]?.hasChildren && !this.treeItems[0]?.expanded) {
          this.toggleNodeExpansion(this.treeItems[0]);
        }
        this.dataSource.data = [...this.treeItems];
      }
    }
    if (changes.showInTree && !changes.showInTree.firstChange) {
      this.searchForm.setValue({
        searchField: changes.showInTree.currentValue ? changes.showInTree.currentValue.name : ''
      });
    }
  }

  triggerContextMenuItemAction(contextMenuItem: TranslationKeyAction, node: TreeNode): void {
    contextMenuItem.action(node);
  }

  expandNodes(nodes: TreeNode[], expandedNodes: TreeNode[]) {
    this.loopOverNodes(nodes, expandedNodes);
  }

  loopOverNodes(nodes: TreeNode[], expandedNodes: TreeNode[]) {
    let i = 0;
    if (expandedNodes != null) {
      if (expandedNodes.length > 0) {
        do {
          if (expandedNodes[i].expanded && expandedNodes[i].hasChildren) {
            this.expandNode(nodes, (node) => node.id == expandedNodes[i].id);
          }
          if (expandedNodes[i].hasChildren) {
            this.loopOverNodes(nodes, expandedNodes[i].children);
          }
          i++;
        } while (i < expandedNodes.length);
      }
    }
  }

  expandNode(nodes, condition) {
    let i = 0;
    if (nodes.length > 0) {
      do {
        if (condition(nodes[i])) {
          nodes[i].expanded = true;
          return;
        } else if (nodes[i].hasChildren) {
          this.expandNode(nodes[i].children, condition);
        }
        i++;
      } while (i < nodes.length);
    }
  }

  ngOnInit() {
    this.dataSource.data = this.treeItems;
    this.expandedTreeNodes = [];
    // setTimeout(() => {
    //   window.dispatchEvent(new Event('resize'));
    // }, 50);
    this.searchForm
      .get('searchField')
      .valueChanges.pipe(debounceTime(500))
      .subscribe((change) => this.onSearchFieldValueChange(change));
  }

  onSearchFieldValueChange(change: string): void {
    if (change !== null && change !== undefined) {
      this.searchField = change.trim();
      this.searchFieldValueChangeEventEmitter.emit(this.searchField);
      if (this.lazyLoad) {
        if (this.searchField.length !== 1) {
          this.search.emit(new TreeSearchEvent(this.searchField, getObjectPropertyValue(this.selectedNode, 'id')));
        }
      } else {
        this.searchTree(this.searchField);
      }
    } else {
      if (this.lazyLoad) {
        this.search.emit(new TreeSearchEvent(this.searchField, getObjectPropertyValue(this.selectedNode, 'id')));
      } else {
        this.searchTree(this.searchField);
      }
    }
  }

  getLevel = (node: TreeNode) => node.level;
  isExpandable = (node: TreeNode) => node.hasChildren;
  getChildren = (node: TreeNode): TreeNode[] => node.children;
  hasChild = (_: number, _nodeData: TreeNode) => _nodeData.hasChildren;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: any, level: number) => {
    node.level = level;
    node.subLocations = node.subLocations || [];

    if (node.selected) {
      this.selectedNode = deepClone(node);
    }

    if (node.expanded && node.children.length > 0) {
      this.treeControl.expand(node);
    }

    if (!this.flatNodeMap.has(node.id)) {
      this.flatNodeMap.set(node.id, node);
    }

    return node;
  };

  isNodeChecked(node: TreeNode): boolean {
    return this.checkedNodes.exist(node);
  }

  private getDescendants(node: TreeNode): TreeNode[] {
    let descendants = [];
    if (this.searchIsEmpty) {
      descendants = this.treeControl.getDescendants(node);
    } else {
      this.treeService.loopOverTreeNodes(
        [this.getNodeById(this.treeItems, node.id)],
        (n) => true,
        (n) => {
          if (n.id !== node.id) {
            // not itself
            descendants.push(n);
          }
        }
      );
    }

    return descendants;
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TreeNode): boolean {
    if (this.ignoreDescendants) {
      return false;
    }
    if (node.hasChildren && node.children.length > 0) {
      const descendants = this.getDescendants(node);
      return descendants.every((child) => this.isNodeChecked(child));
    } else {
      return this.isNodeChecked(node);
    }
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeNode): boolean {
    if (this.ignoreDescendants) {
      return false;
    }
    if (node.hasChildren && node.children.length > 0) {
      const descendants = this.getDescendants(node);
      const result = descendants.some((child) => this.isNodeChecked(child));
      return result && !this.descendantsAllSelected(node);
    } else {
      return false;
    }
  }

  toggleLeafNodeCheckedState(node: TreeNode): void {
    this.checkedNodes.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  toggleNonLeafNodeCheckedState(node: TreeNode): void {
    this.checkedNodes.toggle(node);
    const descendants = this.getDescendants(node);
    // tslint:disable-next-line:max-line-length
    this.isNodeChecked(node) ? this.checkedNodes.addIfNotExist(...descendants) : this.checkedNodes.removeIfExist(...descendants);

    this.checkAllParentsSelection(node);

    this.nodeChecked.emit(node);
  }

  /* Checks all the parents when a node is selected/unselected */
  checkAllParentsSelection(node: TreeNode): void {
    let parent: TreeNode | null = this.getParentNode(node);
    while (parent) {
      this.checkNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check node checked state and change it accordingly */
  checkNodeSelection(node: TreeNode): void {
    const nodeSelected = this.isNodeChecked(node);
    const descendants = this.getDescendants(node);
    const nonLeafNode = descendants.length > 0;
    const descAllSelected =
      nonLeafNode &&
      descendants.every((child) => {
        return this.isNodeChecked(child);
      });
    if (nonLeafNode && nodeSelected && !descAllSelected) {
      this.checkedNodes.removeIfExist(node);
    } else if (nonLeafNode && !nodeSelected && descAllSelected) {
      this.checkedNodes.addIfNotExist(node);
    }
  }

  /**
   * Function used to expand the node in the tree,
   * fetching it's children and setting it to expanded.
   * @param node TreeNode
   * @param spinner Reference for the spinner next to each expandable node.
   */

  /**
   * Function used to get the child nodes of a node
   * and update the node and the tree with the child data
   * @param nodeId the node id
   */

  showFolderSpinner(spinner) {
    if (spinner) {
      spinner._elementRef.nativeElement.style.visibility = 'visible';
      this.referencedSpinner = spinner;
    }
  }
  // used to hide the spinner next to the folder in the tree.
  hideFolderSpinner(spinner) {
    spinner._elementRef.nativeElement.style.visibility = 'hidden';
  }

  /**
   * Function used to highlight the folder/node in the tree
   * @param node TreeNode
   */
  public highlightNode(node: TreeNode, emitEvent = true) {
    if (!this.isInsideDropDown) {
      node.expanded = true;
    }
    this.deHighlightAllNodes(this.treeItems);
    this.deHighlightAllNodes(this.dataSource.data);
    this.selectNode(this.treeItems, node);
    this.selectNode(this.dataSource.data, node);
    if (emitEvent) {
      this.nodeSelected.emit(node);
    }
    this.expandedTreeNodes = JSON.parse(JSON.stringify(this.treeItems));
    this.dataSource.data = this.dataSource.data;
    this.lastHighlightedNode = deepClone(node);

    if (this.treeCmp?.showCheckbox) {
      // on node click toggle checkbox
      if (this.isExpandable(node)) {
        this.toggleNonLeafNodeCheckedState(node);
      } else {
        this.toggleLeafNodeCheckedState(node);
      }
    }
  }

  selectNode(nodes: TreeNode[], node) {
    if (!this.doNotClearSearchFieldWhenNodeIsSelected && definedAndNotEmptyString(this.searchField)) {
      this.selectedNode = deepClone(node);
      this.clearSearchField(true);
    }
    let i = 0;
    if (nodes.length > 0) {
      do {
        if (node.id == nodes[i].id) {
          nodes[i].selected = true;
          if (!this.isInsideDropDown) {
            nodes[i].expanded = true;
          }
          return;
        } else if (nodes[i].hasChildren) {
          this.selectNode(nodes[i].children, node);
        }
        i++;
      } while (i < nodes.length);
    }
  }

  deHighlightAllNodes(nodes) {
    this.setAllNodesUnselected(nodes);
  }

  storeExpansionModel(node: TreeNode) {
    node.expanded = !node.expanded;
    this.expandedTreeNodes = JSON.parse(JSON.stringify(this.treeItems));
  }

  setTreeNodeExpanded(node: TreeNode): TreeNode[] {
    if (this.expandedTreeNodes.length > 0) {
      this.treeItems = this.expandedTreeNodes;
    }

    this.setTreeNodeExpandedRecursively(this.treeItems, node);
    return this.treeItems;
  }

  setTreeNodeExpandedRecursively(nodes: TreeNode[], node: TreeNode) {
    let i = 0;
    if (nodes.length > 0) {
      do {
        if (node.id == nodes[i].id) {
          nodes[i].expanded = !nodes[i].expanded;
          return;
        } else if (nodes[i].hasChildren) {
          this.setTreeNodeExpandedRecursively(nodes[i].children, node);
        }
        i++;
      } while (i < nodes.length);
    }
  }

  setTreeNodeSelectedRecursively(nodes: TreeNode[], node: TreeNode) {
    let i = 0;
    if (nodes.length > 0) {
      do {
        if (node.id == nodes[i].id && !nodes[i].selected) {
          nodes[i].selected = true;
          return;
        } else {
          this.setTreeNodeSelectedRecursively(nodes[i].children, node);
        }
        i++;
      } while (i < nodes.length);
    }
  }

  setTreeNodeSelected(node: TreeNode) {
    this.setTreeNodeSelectedRecursively(this.treeItems, node);
  }

  getNodeById(list: any[], id: number): TreeNode {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        return list[i];
      }
      const found = this.getNodeById(list[i].children, id);
      if (found) {
        return found;
      }
    }
  }

  setAllNodesUnselected(nodes: TreeNode[]) {
    nodes.forEach((treeItemNode) => {
      treeItemNode.selected = false;
      if (treeItemNode.hasChildren) {
        this.setAllNodesUnselected(treeItemNode.children);
      }
    });
  }

  returnTreeItem(id: number): TreeNode {
    return this.findContainingObject(this.dataSource.data, id);
  }

  findContainingObject(array, id) {
    let index = 0,
      result = null;

    while (index < array.length && !result) {
      const item = array[index];

      if (item.id === id) {
        result = item;
      } else if (item.children.length > 0) {
        result = this.findContainingObject(item.children, id);
      }
      if (result === null) {
        index++;
      }
    }
    return result;
  }

  searchTree(searchString: string) {
    // Reset array then search, always.
    this.dataSource.data = [...this.treeItems];
    const filteredTree = this._filterTree(searchString, JSON.parse(JSON.stringify(this.treeItems)));
    this.dataSource.data = filteredTree.length > 0 ? filteredTree : this.searchField.trim() === '' ? [...this.treeItems] : [];

    if (this.lastHighlightedNode) {
      this.lastHighlightedNode.selected = true;
      this.expandNodeParents(this.lastHighlightedNode);
    }
  }

  expandNodeParents(node: TreeNode) {
    this._filterTree(node.name, JSON.parse(JSON.stringify(this.treeItems)), node.id);
  }

  _filterTree(searchText: string, tree: TreeNode[], nodeId?: number): TreeNode[] {
    if (notDefined(searchText) || searchText.trim() === '') {
      return [];
    }
    const treeArray = [];
    let i = tree.length;
    while (i--) {
      const node = tree[i];
      if (nodeId && node.id === nodeId) {
        const treeItem = this.returnTreeItem(node.id);
        if (treeItem) {
          treeItem.expanded = !treeItem.expanded || true;
        }
        treeArray.unshift(node);
      }
      if (this.highlightBackground(node)) {
        treeArray.unshift(node);
        if (node.children) {
          const _children = this._filterTree(searchText, node.children);
          node.children = _children && _children.length > 0 ? _children : [];
          if (node.children.length > 0) {
            node.expanded = true;
          }
        }
      } else {
        if (node.children && node.children.length > 0) {
          const childFilter = this._filterTree(searchText, node.children);
          if (childFilter && childFilter.length > 0) {
            node.expanded = true;
            treeArray.unshift(node);
          } else {
            tree.splice(i, 1);
          }
        } else {
          tree.splice(i, 1);
        }
      }
    }
    return treeArray;
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeNode): TreeNode {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  highlightBackground(node: TreeNode): boolean {
    if (this.searchField && this.searchField !== '' && (!this.lazyLoad || (this.lazyLoad && this.searchField.length >= 2))) {
      const nodeCode = node.code;
      const nodeStoreNumber = node.storeNumber;
      const nodeHighlighted = node.nodeHighlighted;
      const nameHighlighted = this.doesTheTextMatchesTheSearchCriteria(node.name, this.searchField);
      const storeHaveSubLocationNamesThatMatchesTheSearchCriteria = node.subLocations
        .map((subLocation) => subLocation.name)
        .some((subLocationName) => this.doesTheTextMatchesTheSearchCriteria(subLocationName, this.searchField));
      const codeHighLighted = nodeCode ? this.doesTheTextMatchesTheSearchCriteria(node.code, this.searchField) : false;
      const storeNumberHighlighted = nodeStoreNumber ? this.doesTheTextMatchesTheSearchCriteria(nodeStoreNumber, this.searchField) : false;
      const storeHaveSubLocationStoreNumbersThatMatchesTheSearchCriteria = node.subLocations
        .map((subLocation) => subLocation.storeNumber)
        .some((subLocationStoreNumber) => this.doesTheTextMatchesTheSearchCriteria(subLocationStoreNumber, this.searchField));
      return nameHighlighted || storeHaveSubLocationNamesThatMatchesTheSearchCriteria || codeHighLighted || storeNumberHighlighted || storeHaveSubLocationStoreNumbersThatMatchesTheSearchCriteria || nodeHighlighted;
    }
    return false;
  }

  requestNodeChildrenIfTheyAreNotFetchedYet(node: TreeNode): void {
    const expandingTheNode = node.expanded;
    const theNodeDoesNotHaveChildren = arrayIsNotDefinedOrEmpty(node.children);
    const treeIsproductsOrIngredient = this.treeCmp.type === TreeTypeEnum.ProductCategory || this.treeCmp.type === TreeTypeEnum.IngredientCategory;
    /**
     * we have lazyLoad in the condition because when searching
     * a non-lazy loaded tree for a node that has children and the
     * search criteria does not match any of the children, expanding
     * that node will show the spinner instead of the node icon
     */
    if (this.lazyLoad && expandingTheNode && theNodeDoesNotHaveChildren && !treeIsproductsOrIngredient) {
      this.nodeExpandedAndRequestingItsChildren.emit(node);
      node.loading = true;
    }
  }

  onExpandableNodeRadioButtonClick(node: TreeNode): void {
    if (!this.isInsideDropDown) {
      this.requestNodeChildrenIfTheyAreNotFetchedYet(node);
    }
  }

  onExpandableNodeIconClick(node: TreeNode): void {
    if (!this.isInsideDropDown) {
      this.requestNodeChildrenIfTheyAreNotFetchedYet(node);
    }
  }

  onExpandableNodeTextClick(node: TreeNode): void {
    if (!this.isInsideDropDown) {
      this.requestNodeChildrenIfTheyAreNotFetchedYet(node);
    }
  }

  setNodeChildren(node: TreeNode, children: TreeNode[]): void {
    node.children = children;
    node.loading = false;
    this.renderUI();
  }

  setTreeData(data: TreeNode[]): void {
    this.treeItems = deepClone(data);
    this.dataSource.data = deepClone(data);
  }

  clearSearchField(executeOnSearchFieldValueChange = false): void {
    this.searchField = '';
    /** this was done to avoid waiting 500ms before executing the callback */
    this.searchForm.get('searchField').reset(null, { emitEvent: false });
    if (executeOnSearchFieldValueChange) {
      this.onSearchFieldValueChange(null);
    }
  }

  clear(): void {
    this.deHighlightAllNodes(this.treeItems);
    this.deHighlightAllNodes(this.dataSource.data);
    this.clearSearchField();
    this.clearSelectedNode();
    this.dataSource.data = this.dataSource.data;
  }

  private clearSelectedNode(): void {
    this.selectedNode = null;
  }

  private doesTheTextMatchesTheSearchCriteria(text: string, searchCriteria: string): boolean {
    return text.toLowerCase().includes(searchCriteria.toLowerCase());
  }

  private renderUI(): void {
    this.dataSource.data = this.dataSource.data;
  }
  checkboxSelection(event, node) {
    const selectionData = { checked: event.checked, id: node.id, name: node.name, childrenIds: [] };
    this.checkboxChangedEvent.emit(selectionData);
  }
  parentNodeSelection(node) {
    let event = true;
    let childrenIds = [];
    this.getVisibleChildItems(childrenIds, node);
    childrenIds.forEach((childId) => {
      if (this.checkedLocations.includes(childId)) {
        event = false;
      }
    });
    if (childrenIds.length > 0) {
      const selectionData = { checked: event, id: node.id, name: node.name, childrenIds: childrenIds };
      this.checkboxChangedEvent.emit(selectionData);
    }
  }
  checkchildsSelection(node) {
    let selected = false;
    let childrenIds = [];
    this.getVisibleChildItems(childrenIds, node);
    childrenIds = childrenIds.filter((x) => this.checkedLocations.includes(x));
    if (childrenIds.length > 0) {
      selected = true;
    }
    return selected;
  }
  getVisibleChildItems(childrenIds: any[], node) {
    node.children.forEach((child) => {
      if (this.treeCmp.folderSelectionType.includes(child.type)) {
        childrenIds.push(child.id);
      } else if (child.children && child.expanded) {
        this.getVisibleChildItems(childrenIds, child);
      }
    });
  }

  showExpansionArrow(node: TreeNode): boolean {
    return !node.expanded || node.loading || (node.children && node.children.length > 0);
  }

  toggleNodeExpansion(node, $event?): void {
    $event?.stopPropagation();
    this.treeControl.toggle(node);
    this.storeExpansionModel(node);
    this.requestNodeChildrenIfTheyAreNotFetchedYet(node);
  }
}
