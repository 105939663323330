import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { addPaginationParametersToUrl, appendQueryParamIfDefined, defined, stringIsDefinedAndNotEmpty } from '../../../shared/helpers/app.helpers';
import { APIResponseModel } from '../../../shared/models/api-response';
import { Category } from '../../../shared/models/category.model';
import { MenuModel } from '../../../shared/models/menu-model';
import { PaginationResponse } from '../../../shared/models/pagination-response.model';
import { PreSelectedNode } from '../../../shared/models/Tree-Node.model';
import { ApiService } from '../../../shared/services/api/api.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { AllergenDescription } from '../models/allergen.model';
import { Currency } from '../models/Currency.model';
import { AssignMenuItems } from '../models/menu-builder-request.model';
import { MenuItemModel, ProductModel } from '../models/menu-item-model';
import { MenuItemsByCategory } from '../models/MenuItemsByCategory';
import { SubCategoryParents } from '../models/subCategoriesBreadcrumbs';
import { AuthService } from './../../../core/auth/auth.service';
import { AddOnsItems } from './../models/IngredientRecipes.model';
import { MenuDesignerConfiguration } from './../models/menu-design-configuration.model';
import { CustomFieldValue } from '././../../../shared/models/custom-field.model';
import { BatchPrint, BatchPrintItems, UpdatedBatchPrints } from '././../../../shared/models/Batch-Prints.model';
import { MenuConflictDetail } from '../models/menuConflictDetail';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FieldSizeEnum } from '../../../shared/models/enums/FieldSize.enum';
import { Temperature } from '../../analytics/models/Temperature.enum';
import { ItemDetails, SelectedTempTaskItem } from '../../settings/models/temp-ingredient-task.model';
import { ConfigPresetModel } from '../models/config-preset.model';
import { BulkDeleteModel } from '../models/bulk-delete.model';
import { BulkMoveModel } from '../models/bulk-move.model';
import { ProductConflictItems } from '../models/product-conflict-items.model';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { MenuItemType } from '../../../shared/models/enums/menu-item-type.enum';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  // Used to inform main component about new item created weather it's Menu, Menu Item or any other entity
  public creationSucess: Subject<boolean> = new Subject<boolean>();
  locationSubject: Subject<number> = new Subject<number>();
  originalCategoryId: Subject<number> = new Subject<number>();
  public noResultsFound: Subject<string> = new Subject<string>();
  currentLocationId: number;
  currentLocationName: string;
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/accounts/`;
  baseNoAccountsServiceUrl = `${environment.baseAPIUrl}/${environment.version}`;
  constructor(private apiService: ApiService, private loaderService: LoaderService, private authService: AuthService, private snackbarService: SnackbarService) {}

  getProductAssociationsThatPreventDeletion(id: number, locationId: number): Observable<ProductConflictItems> {
    let url = `${this.apiService.v1Accounts}products/${id}/conflictedItems`;

    if (defined(locationId)) {
      url = url + `/${locationId}`;
    }

    return this.apiService.getRequest<ProductConflictItems>(url).pipe(map((res) => res.results));
  }

  // Menus
  getMenus(accountId: number, brandId?: number, nodeId?: number, start?: number, limit?: number, searchKeyword?: string, filter?: string): Observable<PaginationResponse<MenuModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus`;
      if (brandId) {
        url += `?brandId=${brandId}`;
        if (nodeId) {
          url += `&locationId=${nodeId}`;
        }

        if (filter) {
          url += `&filter=${filter}`;
        }

        url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
      }
      return this.apiService.getRequest(url, true).pipe(map((response) => response.results));
    }
  }

  getBatchItems(accountId: number, menuId: number, locationId: number): Observable<PaginationResponse<BatchPrint[]>> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints?locationId=${locationId}`;
      return this.apiService.getRequest(url, true).pipe(map((response) => response.results));
    }
  }

  getBatchItemDetails(
    accountId: number,
    menuId: number,
    batchId: number,
    include: string,
    locationId?: number,
    categoryId?: number,
    searchKeyword?: string,
    start?: number,
    limit?: number
  ): Observable<PaginationResponse<BatchPrintItems[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints/${batchId}/BatchPrintDetail?include=${include}`;
      if (locationId) {
        url += `&locationId=${locationId}`;
      }
      if (categoryId) {
        url += `&categoryId=${categoryId}`;
      }
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
      return this.apiService.getRequest(url, true).pipe(map((response) => response.results));
    }
  }

  updateBatchDetails(accountId: number, menuId: number, batchId: number, assigned: UpdatedBatchPrints[], unassigned: UpdatedBatchPrints[], batchPrints: any[], locationId?: number, overrideChildNodes?: boolean) {
    batchPrints = batchPrints.map((item, index) => {
      return { batchId: item.batchId, displayOrder: index + 1 };
    });
    if (accountId) {
      const jsonBody = { assigned: assigned, unassigned: unassigned, batchPrints };
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints/${batchId}/BatchPrintDetail`;
      if (locationId) {
        url += `?locationId=${locationId}`;
      }
      if (overrideChildNodes) {
        url += locationId ? `&overrideChildNodes=${overrideChildNodes}` : `?overrideChildNodes=${overrideChildNodes}`;
      }
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  getBatchItemById(accountId: number, menuId: number, batchId: number, locationId: number): Observable<BatchPrint> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints/${batchId}?locationId=${locationId}`;
      return this.apiService.getRequest(url, true).pipe(map((response) => response.results));
    }
  }

  createBatchPrint(accountId: number, name: string, description: string, menuId: number, locationId?: number) {
    if (accountId) {
      const jsonBody = { batchName: name, batchDescription: description };
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints`;
      if (locationId) {
        url += `?locationId=${locationId}`;
      }
      return this.apiService.postRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  editBatchPrint(accountId: number, name: string, description: string, batchId: number, menuId: number, locationId?: number) {
    if (accountId) {
      const jsonBody = { batchName: name, batchDescription: description };
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints/${batchId}`;
      if (locationId) {
        url += `?locationId=${locationId}`;
      }
      return this.apiService.putRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  deleteBatchPrint(accountId: number, menuId: number, batchId: number, locationId?: number) {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints/${batchId}`;
      if (locationId) {
        url += `?locationId=${locationId}`;
      }
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  checkExistingBatchPrintCustomization(accountId: number, menuId: number, batchId: number, assigned: UpdatedBatchPrints[], locationId?: number) {
    if (accountId) {
      const jsonBody = { assigned: assigned };

      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/BatchPrints/${batchId}/ExistingCustomization`;
      if (locationId) {
        url += `?locationId=${locationId}`;
      }

      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  getCurrencies(): Observable<Currency[]> {
    const url = this.baseNoAccountsServiceUrl + `/currencies`;
    return this.apiService.getRequest(url).pipe(
      map((response) => {
        return <Currency[]>response.results;
      })
    );
  }

  // getLatestMenus(accountId: number, brandId?: number, nodeId?: number, limit: number = 6): Observable<MenuModel[]> {
  //   if (accountId) {
  //     this.loaderService.showLoader();
  //     let url = this.baseServiceUrl + `${accountId}/menus` + `?limit=${limit}`;
  //     if (brandId) {
  //       url += `&brandId=${brandId}`;
  //       if (nodeId) {
  //         url += `&locationId=${nodeId}`;
  //       }
  //     }
  //     return this.apiService.getRequest(url).pipe(map(response => {
  //       this.loaderService.hideLoader();
  //       // if (response.statusCode === 202)
  //       return <MenuModel[]>response.results;
  //       // return [];
  //     }));
  //   }
  // }

  addMenu(
    accountId: number,
    menuName: string,
    menuName_Secondary: string,
    description: string,
    brandId: number,
    locationId: number,
    sortItemsAlphabetically: boolean,
    publishAt?: string,
    primaryLanguage?: number,
    secondaryLanguage?: number,
    allowSubscribersToUnassign?: boolean
  ) {
    if (accountId) {
      const jsonBody = {
        name: menuName,
        name_Secondary: menuName_Secondary,
        description: description,
        brandId: brandId,
        accountId: accountId,
        locationId: locationId,
        publishAt: publishAt,
        primaryLanguageId: primaryLanguage,
        secondaryLanguageId: secondaryLanguage,
        sortItemsAlphabetically: sortItemsAlphabetically,
        allowUnassignBySubscriber: allowSubscribersToUnassign
      }; //Changed
      const url = this.baseServiceUrl + `${accountId}/menus/`;
      return this.apiService.postRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }
  //Mock data
  //addOns: AddOnsItems[] = [] = [{ ingId: 1, ingValue: "Ing1", name: "Ing1", type: 'ingredient', display: "Test1", caption: "Caption1", price: "111", id: 1, assignedToExistingMenu: true }, { ingId: 2, ingValue: "Ing2", name: "Ing2", type: 'ingredient', display: "Test2", caption: "Caption2", price: "222", id: 2, assignedToExistingMenu: false }, { ingId: 3, ingValue: "Ing3", name: "Recipe1", type: 'product', display: "Test3", caption: "Caption3", price: "333", id: 3, assignedToExistingMenu: true }, { ingId: 4, ingValue: "Ing4", name: "Recipe2", type: 'product', display: "Test4", caption: "Caption4", price: "444", id: 4, assignedToExistingMenu: false }];
  getAddOns(
    accountId: number,
    brandId?: number,
    nodeId?: number,
    start?: number,
    limit?: number,
    searchKeyword?: string,
    includeParentNodes?: boolean,
    sortBy?: string,
    sortDirection?: string,
    excludeDisabledAddons?: boolean
  ): Observable<PaginationResponse<AddOnsItems[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/addons`;

      if (brandId) {
        url += `?brandId=${brandId}`;

        if (nodeId) {
          url += `&locationId=${nodeId}`;
        }
        if (includeParentNodes !== undefined) {
          url += `&includeParentNodes=${includeParentNodes}`;
        }
        if (excludeDisabledAddons) {
          url += `&excludeDisabledAddons=${excludeDisabledAddons}`;
        }
      }

      url = `${url}` + (sortBy ? (brandId ? `&SortBy=${sortBy}` : `?SortBy=${sortBy}`) : '');
      url = `${url}` + (sortDirection ? (brandId && sortBy ? `&SortDirection=${sortDirection}` : `?SortDirection=${sortDirection}`) : '');

      url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  getAllergenverbs(start?: number, limit?: number): Observable<AllergenDescription[]> {
    let url = `${environment.baseAPIUrl}/${environment.version}/params/allergenverbs`;

    url = addPaginationParametersToUrl(url, start, limit);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getUnassignedIngredientsRecipes(name: string, accountId: number, brandId?: number, locationId?: number, sortBy?: string, sortDirection?: string, getAllAddOns: boolean = false): Observable<any> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/addons`;

      if (brandId) {
        url += `?brandId=${brandId}`;
        if (name) {
          url += `&name=${name}`;
        }
        if (locationId) {
          url += `&locationId=${locationId}`;
        }
      }

      url = `${url}` + (sortBy ? (brandId ? `&SortBy=${sortBy}` : `?SortBy=${sortBy}`) : '');
      url = `${url}` + (sortDirection ? (brandId && sortBy ? `&SortDirection=${sortDirection}` : `?SortDirection=${sortDirection}`) : '');
      url = `${url}` + (getAllAddOns ? `&getAllAddOns=${getAllAddOns}` : '');

      return this.apiService.getRequest(url).pipe(
        map((response) => {
          return response.results;
        })
      );
    }
  }

  addAddOns(
    accountId: number,
    displayName: string,
    price: number,
    currencyId: number,
    brandId: number,
    name: string,
    caption: string,
    nodeId: number,
    typeId: number,
    itemId: number,
    name_Secondary?: string,
    caption_Secondary?: string,
    displayName_Secondary?: string,
    valueAddOn?: number,
    uomAddOn?: number,
    productAssignedRecipeId?: number,
    productAssignedRecipeSizeId?: number,
    barcode?: string
  ) {
    if (accountId) {
      const jsonBody = {
        displayName: displayName,
        price: price,
        currencyId: currencyId,
        brandId: brandId,
        name: name,
        caption: caption,
        nodeId: nodeId,
        typeId: typeId,
        itemId: itemId,
        name_Secondary: name_Secondary,
        caption_Secondary: caption_Secondary,
        displayName_Secondary: displayName_Secondary,
        value: valueAddOn,
        uoM: uomAddOn,
        productAssignedRecipeId: productAssignedRecipeId,
        productAssignedRecipeSizeId: productAssignedRecipeSizeId,
        barcode: barcode
      };
      const url = this.baseServiceUrl + `${accountId}/addons`;
      return this.apiService.postRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  updateAddOnDetails(
    accountId: number,
    addOnId: number,
    display: string,
    price: number,
    currencyId: number,
    name: string,
    caption: string,
    typeId: number,
    itemId: number,
    brandId: number,
    locationId: number,
    caption_Secondary?: string,
    name_Secondary?: string,
    displayName_Secondary?: string,
    valueAddOn?: number,
    uomAddOn?: number,
    productAssignedRecipeId?: number,
    productAssignedRecipeSizeId?: number,
    barcode?: string
  ): Observable<any> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/addons/${addOnId}`;
      const jsonBody = {
        itemId: itemId,
        displayName: display,
        price: price,
        currencyId: currencyId,
        name: name,
        caption: caption,
        brandId: brandId,
        typeId: typeId,
        nodeId: locationId,
        caption_Secondary: caption_Secondary,
        name_Secondary: name_Secondary,
        displayName_Secondary: displayName_Secondary,
        value: valueAddOn,
        uoM: uomAddOn,
        productAssignedRecipeId: productAssignedRecipeId,
        productAssignedRecipeSizeId: productAssignedRecipeSizeId,
        barcode: barcode
      };
      return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response.results));
    }
  }

  // Menu Items
  getMenuItems(accountId: number, brandId?: number, locationId?: number): Observable<MenuItemModel[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menuitems`;
      if (brandId) {
        url += `?BrandId=${brandId}`;
      }
      if (locationId) {
        url += `&check_by=locationId&locationId=${locationId}`;
      }
      return this.apiService.getRequest(url, true).pipe(
        map((response) => {
          // if (response.statusCode === 202)
          return <MenuItemModel[]>response.results;
        })
      );
    }
  }

  getAllMenuItemsByBrandAndMenuId(accountId: number, brandId: number, menuId: number, locationId?: number): Observable<MenuItemsByCategory[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menuitemcategories?include=children&check_by=menuid&menuid=${menuId}&brandId=${brandId}&itemType=1`;
      if (locationId) {
        url += `&locationId=${locationId}`;
      }
      return this.apiService.getRequest(url, true).pipe(
        map((response: APIResponseModel<MenuItemsByCategory[]>) => {
          return response.results;
        })
      );
    }
  }
  getAssignedAndUnassignedMenuItems(menuId, accountId, payload, locationid, start, limit, assigned) {
    let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/menuitems`;
    url = addPaginationParametersToUrl(url, start, limit);
    url = appendQueryParamIfDefined(url, 'locationId', locationid);
    url = appendQueryParamIfDefined(url, 'assigned', assigned);

    return this.apiService.postRequest(url, payload).pipe(
      map((response: any) => {
        return response.results;
      })
    );
  }

  assignMenuItemToMenu(accountId: number, menuId, assignmentObject: any, locationId?: number, reorderItems: boolean = false): any {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/menuitems`;
      if (locationId) {
        url += `?locationId=${locationId}&reorderItems=${reorderItems}`;
      } else {
        url += `?reorderItems=${reorderItems}`;
      }
      return this.apiService.patchRequest(url, assignmentObject).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  unassignMenuItemFromMenu(accountId: number, menuId: number, menuItemId: number, locationId?: number, reorderItems: boolean = true): Observable<any> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/menuitems/${menuItemId}`;
      if (locationId) {
        url += `?locationId=${locationId}&reorderItems=${reorderItems}`;
      } else url += `?reorderItems=${reorderItems}`;

      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  getCategories(accountId: number, brandId?: number, locationId?: number): Observable<Category[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menuitemcategories`;
      if (brandId) {
        url += `?BrandId=${brandId}`;
        if (locationId != null) {
          url = url + `&locationId=${locationId}`;
        }
      }
      return this.apiService.getRequest(url, true).pipe(
        map((response: APIResponseModel<Category[]>) => {
          return response.results;
        })
      );
    }
  }

  getCategoryById(accountId: number, categoryId: number): Observable<Category> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/menuitemcategories/${categoryId}`;
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<Category>) => {
          return response.results;
        })
      );
    }
  }

  // Menu Items
  getMenuItemsByCategoryId(
    accountId: number,
    categoryId?: number,
    itemType: number = null,
    locationId?: number,
    start?: number,
    limit?: number,
    searchKeyword?: string,
    taskId?: number,
    labelingEnabled?: boolean,
    getAssignedOnly?: boolean,
    includeParentNodes?: boolean,
    variableWeightProducts?: boolean,
    tenantOnlyItems?: boolean
  ): Observable<PaginationResponse<MenuItemModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menuitems`;
      if (categoryId) {
        url += `${url.indexOf('?') >= 0 ? '&' : '?'}categoryId=${categoryId}`;
      }
      if (itemType) {
        url += `${url.indexOf('?') >= 0 ? '&' : '?'}itemType=${itemType}`;
      }
      if (locationId) {
        url += `${url.indexOf('?') >= 0 ? '&' : '?'}locationId=${locationId}`;
      }
      if (taskId) {
        url += `${url.indexOf('?') >= 0 ? '&' : '?'}taskId=${taskId}`;
      }

      url = appendQueryParamIfDefined(url, 'labelingEnabled', labelingEnabled);
      url = appendQueryParamIfDefined(url, 'getAssignedOnly', getAssignedOnly);
      url = appendQueryParamIfDefined(url, 'includeParentNodes', includeParentNodes);
      url = appendQueryParamIfDefined(url, 'variableWeightProducts', variableWeightProducts);
      url = appendQueryParamIfDefined(url, 'tenantOnlyItems', tenantOnlyItems);

      // If we have a search word, do not lazy load
      // if(stringIsDefinedAndNotEmpty(searchKeyword)){
      //   limit = 99999;
      // }

      // For Lazy Loading
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword);

      return this.apiService.getRequest(url, true).pipe(map((response) => response.results));
    }
  }

  getProductsByCategoryId(
    accountId: number,
    categoryId: number,
    locationId?: number,
    start?: number,
    limit?: number,
    searchKeyword?: string,
    sortBy?: string,
    sortDirection?: string
  ): Observable<PaginationResponse<ProductModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/products` + `?categoryId=${categoryId}`;
      if (locationId != null) {
        url = url + `&locationId=${locationId}`;
      }

      url = `${url}` + (sortBy ? `&SortBy=${sortBy}` : '');
      url = `${url}` + (sortDirection ? `&SortDirection=${sortDirection}` : '');

      url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
      return this.apiService.getRequest(url, true).pipe(map((response) => response.results));
    }
  }

  // getLatestMenuItems(accountId: number, brandId?: number, limit: number = 6): Observable<MenuItemModel[]> {
  //   if (accountId) {
  //     this.loaderService.showLoader();
  //     let url = this.baseServiceUrl + `${accountId}/menuitems` + `?limit=${limit}`;
  //     if (brandId) {
  //       url += `&BrandId=${brandId}`;
  //     }
  //     return this.apiService.getRequest(url).pipe(map(response => {
  //       this.loaderService.hideLoader();
  //       return response.results;
  //     }));
  //   }
  // }

  getSubCategories(accountId: number, categoryId: number, locationId?: number): Observable<Category[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menuitemcategories/${categoryId}/menuitemcategories`;
      if (locationId) {
        url = `${url}?locationId=${locationId}`;
      }
      return this.apiService.getRequest(url, true).pipe(
        map((response: APIResponseModel<Category[]>) => {
          return response.results;
        })
      );
    }
  }

  addCategory(cat: Category) {
    if (cat.accountId) {
      const url = this.baseServiceUrl + `${cat.accountId}/menuItemCategories?itemType=1`;
      return this.apiService.postRequest(url, cat).pipe(
        map((response: any) => {
          return response.results;
        })
      );
    }
  }

  getMenuItemsByMenuIdCatId(
    accountId: number,
    menuId: number,
    categoryId?: number,
    locationId?: number,
    enableLocationFilter?: boolean,
    start?: number,
    limit?: number,
    searchKeyword?: string,
    labelingEnabled?: boolean
  ): Observable<PaginationResponse<MenuItemModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/menuItems`;
      if (enableLocationFilter != undefined && enableLocationFilter == true) {
        if (categoryId && locationId) {
          url += `?check_by=locationId&categoryId=${categoryId}&locationId=${locationId}`;
        } else {
          if (locationId) {
            url += `?check_by=locationId&locationId=${locationId}`;
          }
          if (categoryId) {
            url += `?categoryId=${categoryId}`;
          }
        }
      } else {
        if (categoryId && locationId) {
          url += `?categoryId=${categoryId}&locationId=${locationId}`;
        } else {
          if (locationId) {
            url += `?locationId=${locationId}`;
          }
          if (categoryId) {
            url += `?categoryId=${categoryId}`;
          }
        }
      }
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
      url = appendQueryParamIfDefined(url, 'labelingEnabled', labelingEnabled);

      return this.apiService.getRequest(url, true).pipe(
        map((response) => {
          return response.results;
        })
      );
    }
  }

  getConfigPresetsByMenuIdCatId(accountId: number, menuId: number, categoryId?: number, locationId?: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<ConfigPresetModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}/presets`;
      if (categoryId && locationId) {
        url += `?categoryId=${categoryId}&locationId=${locationId}`;
      } else {
        if (locationId) {
          url += `?locationId=${locationId}`;
        }
        if (categoryId) {
          url += `?categoryId=${categoryId}`;
        }
      }
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
      return this.apiService.getRequest(url, true).pipe(
        map((response) => {
          return response.results;
        })
      );
    }
  }

  deleteConfigPreset(accountId: number, menuId: number, configPresetId: number) {
    if (accountId && menuId && configPresetId) {
      const url = this.baseServiceUrl + `${accountId}/menus/${menuId}/configpreset/${configPresetId}`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  onCreationSuccess() {
    this.creationSucess.next(true);
  }

  updateCategoryDetails(accountId: number, category: Category, categoryId: number): Observable<any> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/menuitemcategories/${categoryId}`;
      return this.apiService.putRequest(url, category).pipe(map((response) => response.results));
    }
  }

  updateMenuDetails(
    accountId: number,
    menuName: string,
    menuName_Secondary: string,
    menuDescription: string,
    brandId: number,
    menuId: number,
    locationId: number,
    publishAt: string,
    typeId: number,
    subscriptionCode: string,
    primaryLanguageId?: number,
    secondaryLanguageId?: number,
    killDateShelfLifeHour?: number,
    sortItemsAlphabetically?: boolean,
    allowSubscribersToUnassign?: boolean
  ): Observable<any> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/menus/${menuId}`;
      const jsonBody = {
        name: menuName,
        description: menuDescription,
        brandId: brandId,
        accountId: accountId,
        locationId: locationId,
        publishAt: publishAt,
        typeId: typeId,
        subscriptionCode: subscriptionCode,
        name_Secondary: menuName_Secondary,
        primaryLanguageId: primaryLanguageId,
        secondaryLanguageId: secondaryLanguageId,
        killDateShelfLifeHour: killDateShelfLifeHour,
        sortItemsAlphabetically: sortItemsAlphabetically,
        allowUnassignBySubscriber: allowSubscribersToUnassign
      };
      return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response.results));
    }
  }

  updateMenuSortingType(accountId: number, menuId: number, locationId: number, sortItemsAlphabetically: boolean, reorderItems: boolean = false) {
    let url = `${this.baseServiceUrl}/${accountId}/locations/${locationId}/menus/${menuId}/sorting?reorderItems=${reorderItems}`;
    return this.apiService.putRequest(url, { sortItemsAlphabetically: sortItemsAlphabetically });
  }

  GetSubCategoryParents(accountId: number, menuItemCategoryid: string, locationId?: number): Observable<SubCategoryParents[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menuItemCategories/${menuItemCategoryid}/tree?Include=ancestors`;
      if (locationId != null) {
        url = url + `&locationId=${locationId}`;
      }
      return this.apiService.getRequest(url, true).pipe(
        map((response: APIResponseModel<PreSelectedNode>) => {
          return response.results.tree;
        })
      );
    }
  }

  getMenuById(accountId: number, menuId: number, locationId?: number): Observable<MenuModel> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus/${menuId}`;
      if (locationId) {
        url = `${url}?locationId=${locationId}&check_by=locationId `;
      }
      return this.apiService.getRequest(url, true).pipe(
        map((response: APIResponseModel<any>) => {
          return response.results;
        })
      );
    }
  }

  revertMenuChanges(accountId: number, menuId: number, locationId: number): Observable<MenuModel> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/menus/${menuId}/revert`;
      return this.apiService.putRequest(url, null).pipe(
        map((response: APIResponseModel<any>) => {
          return response.results;
        })
      );
    }
  }

  searchForMenuItems(accountId: number, menuId: number, query: string, categoryId?: number, locationId?: number, labelingEnabled?: boolean): Observable<MenuItemModel[]> {
    if (accountId) {
      let url = `${this.baseServiceUrl}${accountId}/menus/${menuId}/menuItems?q=${query}&start=0`;
      if (categoryId) {
        url = `${url}&categoryid=${categoryId}`;
      }
      if (locationId) {
        url = `${url}&locationId=${locationId}`;
      }
      url = appendQueryParamIfDefined(url, 'labelingEnabled', labelingEnabled);
      return this.apiService.getRequest(url, false).pipe(map((response: APIResponseModel<MenuItemModel[]>) => response.results));
    }
  }

  updateMenuItemStatus(accountId: number, menuId: number, menuItemId: number, required: boolean, enabled: boolean, locationId?: number) {
    if (accountId) {
      let url = `${this.baseServiceUrl}${accountId}/menus/${menuId}/menuItems/${menuItemId}`;
      if (locationId) {
        url = `${url}?locationId=${locationId}`;
      }
      let body = { required: required, enabled: enabled };
      return this.apiService.putRequest(url, body).pipe(map((response) => response.results));
    }
  }

  setLocationId(locationId: number) {
    this.locationSubject.next(locationId);
    this.currentLocationId = locationId;
  }

  setLocationName(name: string) {
    this.currentLocationName = name;
  }

  getLocationName(): string {
    return this.currentLocationName;
  }

  getLocationId() {
    return this.currentLocationId;
  }

  getMenuItemAssignedCustomFields(accountId: number, menuItemId: number, menuItemTypeId): Observable<CustomFieldValue[]> {
    const url = `${this.baseServiceUrl}${accountId}/menuitems/${menuItemId}/customfields?itemTypeId=${menuItemTypeId}`;
    return this.apiService.getRequest(url, false).pipe(map((res) => res.results));
  }

  configureMenuDesign(menuId: number, configuration: MenuDesignerConfiguration, locationId: number): Observable<unknown> {
    let url = `${this.baseServiceUrl}${this.authService.getCurrentUserAccountId()}/menus/${menuId}/configuration`;
    if (locationId) {
      url = `${url}?locationId=${locationId}`;
    }
    return this.apiService.patchRequest(url, configuration).pipe(map((response) => response.results));
  }

  generateSubscriptionCode(accountId: number, locationId: number, brandId: number, menuId: number) {
    const url = `${this.baseServiceUrl}${accountId}/menus/${menuId}/generateSubscriptionCode?locationId=${locationId}&brandId=${brandId}`;
    return this.apiService.putRequest(url, {}).pipe(map((response) => response.results));
  }

  export(accountId: number, menuId: number, locationId: number): Observable<any> {
    const url = `${this.baseServiceUrl}${accountId}/reports/menus/${menuId}?locationId=${locationId}`;
    return this.apiService.getRequest(url, true, false, true).pipe(map((res) => res));
  }

  exportCustomization(accountId: number, menuId: number, locationId: number): Observable<any> {
    const url = `${this.baseServiceUrl}${accountId}/reports/menus/${menuId}/customization?locationId=${locationId}`;
    return this.apiService.getRequest(url, true, false, true).pipe(map((res) => res));
  }

  exportAdd(accountId: number, menuId: number, locationId: number): Observable<any> {
    const url = `${this.baseServiceUrl}${accountId}/reports/menus/${menuId}/exportAdd?locationId=${locationId}`;
    return this.apiService.getRequest(url, true, false, true).pipe(map((res) => res));
  }
  exportPricing(accountId: number, menuId: number, locationId: number, language: string, menuName: string) {
    const url = `${this.baseServiceUrl}${accountId}/reports/menus/pricing/${menuId}?locationId=${locationId}&languageCode=${language}`;
    return this.apiService.getRequest(url, true, false, true).subscribe((res) => {
      this.snackbarService.openSnackBar('menu_manager.menu_pricing_exported_successfully');
      const blob = new Blob(['\ufeff', <any>res], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const encodedUri = encodeURI(downloadUrl);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${menuName} Pricing.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    });
  }

  import(accountId: number, menuId: number, locationId: number, file: any, forceAssign?: boolean): Observable<unknown> {
    let url = `${this.baseServiceUrl}${accountId}/bulk/menus/${menuId}?locationId=${locationId}`;

    if (forceAssign) url = `${url}&forceAssign=${forceAssign}`;

    const myfile: FormData = new FormData();
    if (file !== undefined) {
      myfile.append('File', file);
    }
    return this.apiService.patchRequest(url, myfile).pipe(map((response) => response.results));
  }

  importCutomization(accountId: number, menuId: number, locationId: number, file: any, forceAssign?: boolean): Observable<unknown> {
    let url = `${this.baseServiceUrl}${accountId}/bulk/menus/${menuId}/customization?locationId=${locationId}`;

    if (typeof forceAssign === 'boolean') url = `${url}&OverrideChildNodes=${forceAssign}`;

    const myfile: FormData = new FormData();
    if (file !== undefined) {
      myfile.append('File', file);
    }
    return this.apiService.patchRequest(url, myfile).pipe(map((response) => response.results));
  }

  importAdd(accountId: number, menuId: number, locationId: number, file: any, forceAssign?: boolean): Observable<unknown> {
    let url = `${this.baseServiceUrl}${accountId}/bulk/menus/${menuId}/importAdd?locationId=${locationId}`;

    if (typeof forceAssign === 'boolean') url = `${url}&OverrideChildNodes=${forceAssign}`;

    const myfile: FormData = new FormData();
    if (file !== undefined) {
      myfile.append('File', file);
    }
    return this.apiService.patchRequest(url, myfile).pipe(map((response) => response.results));
  }

  clearSubscriptionCode(accountId: number, locationId: number, menuId: number) {
    const url = `${this.baseServiceUrl}${accountId}/menus/${menuId}/clearSusbcriptionCode?locationId=${locationId}`;
    return this.apiService.putRequest(url, {}).pipe(map((response) => response.results));
  }

  subscribe(accountId: number, brandId: number, locationId: number, subscriptionCode: string) {
    const url = `${this.baseServiceUrl}${accountId}/menus/subscribe`;
    let body = { brandId: brandId, locationId: locationId, subscriptionCode: subscriptionCode };
    return this.apiService.postRequest(url, body).pipe(map((response) => response.results));
  }

  deleteMenu(accountId: number, menuId: number): Observable<MenuConflictDetail> {
    if (accountId && menuId) {
      const url = this.baseServiceUrl + `${accountId}/menus/${menuId}`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  deleteCategory(accountId: number, brandId: number, locationId: number, categoryId: number) {
    if (accountId && brandId && locationId && categoryId) {
      const url = this.baseServiceUrl + `${accountId}/menuitemcategories/${categoryId}?locationid=${locationId}&brandId=${brandId}`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  deleteBulkProducts(accountId: number, bulkDelete: BulkDeleteModel, forceDelete: boolean = false) {
    let url = this.baseServiceUrl + `${accountId}/products/bulk`;
    if (forceDelete) url = `${url}?forceUpdate=${forceDelete}`;

    return this.apiService.patchRequest(url, bulkDelete).pipe(map((response) => response.results));
  }

  deleteBulkIngredients(accountId: number, bulkDelete: BulkDeleteModel, forceDelete: boolean = false) {
    let url = this.baseServiceUrl + `${accountId}/ingredients/bulk`;
    if (forceDelete) url = `${url}?forceUpdate=${forceDelete}`;

    return this.apiService.patchRequest(url, bulkDelete).pipe(map((response) => response.results));
  }

  moveBulkProducts(accountId: number, overrideStyle: boolean, bulkMove: BulkMoveModel) {
    const url = this.baseServiceUrl + `${accountId}/products/move/bulk`;
    bulkMove.overrideStyle = overrideStyle;
    return this.apiService.patchRequest(url, bulkMove).pipe(map((response) => response.results));
  }

  checkStyleChangeEligibility(type: MenuItemType, bulkMove: BulkMoveModel): Observable<{ isEligible: boolean }> {
    const url = `${this.apiService.v1Accounts}menuItems/styleOverride?itemType=${type}`;
    return this.apiService.postRequest(url, bulkMove).pipe(map((response) => response.results));
  }

  moveBulkIngredients(accountId: number, overrideStyle: boolean, bulkMove: BulkMoveModel) {
    const url = this.baseServiceUrl + `${accountId}/ingredients/move/bulk`;
    bulkMove.overrideStyle = overrideStyle;
    return this.apiService.patchRequest(url, bulkMove).pipe(map((response) => response.results));
  }

  getConflictedSubscribers(accountId: number, categoryId: number): Observable<string> {
    const url = this.baseServiceUrl + `${accountId}/menuitemcategories/conflictedsubscribers?categoryId=${categoryId}`;
    return this.apiService.getRequest(url, true).pipe(
      map((response: APIResponseModel<string>) => {
        return response.results;
      })
    );
  }
}
