import { BehaviorSubject, Subject } from 'rxjs';
import Neodynamic from '../../../vendor/ThermalLabelWebEditor';
import { LABEL_PADDING, OBJECTS_ALLOWED_FOR_REMOTE_PRINTING, SUPPORTED_DATE_FROMATS } from '../constants.config';
import { DesignerSectionItem } from '../models/designer-section.model';
import { Widget } from '../models/widget.model';
import { DesignerSection } from './../models/designer-section.model';
import { ComponentTypes } from './../models/enums/component-types.enum';
import { Orientation } from './../models/enums/label-orientations.enum';
import { LabelItemProperties } from './../models/enums/labelItemProperties.enum';
import { ItemSelected } from './../models/label-designer-item-selection.model';
import { Comments, DowSettings, Editor, LabelDimension, TextItem, ThermalLabelItem } from './../models/label-designer-objects/NeoTypes.model';
import { DesignerSections } from './../models/enums/objects-sections.enum';
import * as _ from 'lodash';
import { LocalStorageFlags } from './../../../shared/models/enums/local-storage-flags.enum';
import { defined, definedAndNotEmptyString, getObjectPropertyValue, isObject, notDefinedOrEmptyString, uuidv4 } from './../../../shared/helpers/app.helpers';
import { CustomFieldItem, DateItem, DiscardDateItem, LabelDetailsModel, MainlabelItem, NutritionalFactITem, RfidSettings, atmaField, atmaFieldMapItem } from '../models/label-model';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TenantTypeEnum } from '../../../shared/models/tenant-type.model';
import { ItemField } from '../models/item-field.model';
import { getCustomeFieldTypeId } from '../pipes/custom-field-type-id.pipe';
import { CustomFieldTypeEnum } from '../../../shared/models/custom-field.model';
import { FormErrosEnum } from '../../../pipes/form-errors/error-mapper';
import { ConfirmationModalData } from '../../../shared/models/confirmation-dialog.model';
import { HeaderListFooterComponent } from '../../../shared/modules/confirmation-content/components/header-list-footer/header-list-footer.component';
import { DynamicComponentModel } from '../../../shared/models/dynamic-component-model.model';
import { ConflictData } from '../models/conflict-data.model';
import { AtmaFields } from '../models/atma-fields.model';
const Printing = Neodynamic.SDK.Printing;
export const selectedElement: Subject<ItemSelected> = new Subject();
export const multiSelectedElements: Subject<any> = new Subject();
declare var fabric;
export function createEmptyLabel(labelDimension: LabelDimension, isContinuous: boolean = false, isAvailableForRemotePrinting: boolean = false, commissionRFID: boolean = false): string {
  let isContinuousValue = isContinuous ? 'True' : 'False';
  let isAvailableForRemotePrintingValue = isAvailableForRemotePrinting ? 'True' : 'False';
  let commissionRFIDValue = commissionRFID ? 'True' : 'False';
  return `<?xml version="1.0" encoding="UTF-8"?>
  <ThermalLabel Version="8.0"
  Width="${labelDimension.width}"
  Height="${labelDimension.height}"
  UnitType="${labelDimension.unitType}"
   GapLength="0" MarkLength="0" OffsetLength="0"
   LabelsPerRow="1" LabelsHorizontalGapLength="0"
   IsContinuous="${isContinuousValue}" PrintSpeed="" PrintMirror="False"
   IsAvailableForRemotePrinting="${isAvailableForRemotePrintingValue}"
   CommissionRFID="${commissionRFIDValue}"
   CutAfterPrinting="False">
     <Items>
     </Items>
  </ThermalLabel>`;
}
export const deletingReferencedItem = new BehaviorSubject(false);
export function createDayOfWeekLabel(labelDimension: LabelDimension, dowSetting: DowSettings): string {
  return `<?xml version="1.0" encoding="UTF-8"?>
            <ThermalLabel Version="8.0"
            Width="${labelDimension.width}"
            Height="${labelDimension.height}"
            UnitType="${labelDimension.unitType}"
            GapLength="0" MarkLength="0" OffsetLength="0"
            LabelsPerRow="1" LabelsHorizontalGapLength="0"
            IsContinuous="False" PrintSpeed="" PrintMirror="False"
            CutAfterPrinting="False">
              <Items>
              <ImageItem Name="IsDayOfWeek" X="${dowSetting.xPosition}" Y="${dowSetting.yPosition}" DataField="" DataFieldFormatString="" CacheItemId=""
              Comments="_x007B__x0022_type_x0022_:_x0022_dayOfWeek_x0022__x002C__x0022_placeholder_x0022_:_x0022__x005B_DAY_OF_WEEK_x005D__x0022__x002C__x0022_section_x0022_:12_x007D_"
              Tag="" Width="${dowSetting.width}" Height="${dowSetting.height}" Source="Base64"
              SourceData="${dowSetting.sourceData}"  MonochromeSettings="OtsuThreshold,50,False" LockAspectRatio="Fit"/>
              </Items>
            </ThermalLabel>`;
}

export function getProperties(editor, event?: any) {
  const elements: any = getEditorSelectedItems(editor);
  // If elements is an array means it's a group and should be returned as multi selected
  // Else it's one object
  if (Array.isArray(elements)) {
    const selectionGroup = editor._selected_objects.target;
    const selections: ItemSelected[] = [];
    elements.forEach((element) => {
      if (element) {
        selections.push(new ItemSelected(element, element.comments.type));
      }
    });
    selectionGroup.selections = selections;
    multiSelectedElements.next(selectionGroup);
  } else {
    if (!elements || (elements && elements.length === 0)) {
      return;
    } else {
      const selectedItemType = elements.comments.type;
      const selection = new ItemSelected(elements, selectedItemType);
      selectedElement.next(selection);
    }
  }

  // if (elements) {
  //   if (elements.length === 0) { return; }
  //   if (elements.length == 1) {
  //     const element = elements[0];
  //     const selectedItemType = element.comments.type;
  //     const selection = new ItemSelected(element, selectedItemType);
  //     selectedElement.next(selection);
  //   } else {

  //     const selectionGroup = editor._selected_objects.target;
  //     const selections: ItemSelected[] = [];
  //     elements.forEach(element => {
  //      if (element) {  selections.push(new ItemSelected(element, element.comments.type)); }
  //     });
  //     selectionGroup.selections = selections;
  //     multiSelectedElements.next(selectionGroup);
  //   }
  // }
}

export function convertInchToMM(value: number): number {
  return value * 25.4;
}

export function convertMMToInch(value: number): number {
  return value / 25.4;
}

export function adjustToZoomValue(value: number, editorZoom: number): number {
  return (value * 100) / editorZoom;
}

export function restrictBarCodeResizing(editor, event) {
  const element: any = getEditorSelectedItems(editor);
  if (element.symbology === 17) {
    if (event.target.height <= 150) {
      element.height = 0.5;
    } else {
      element.height = event.target.height / 300;
    }
  }
}
export function isNameInItems(items: ThermalLabelItem[], name: string): boolean {
  if (items.findIndex((x) => x.comments.alias === name) >= 0) {
    return true;
  } else {
    const composFields = items.filter((item) => item.comments[LabelItemProperties.COMPOSITE_FIELDS]?.length >= 0);
    let valueInFields = false;
    composFields.forEach((composite) => {
      const innerFields = composite.comments[LabelItemProperties.COMPOSITE_FIELDS];
      if (innerFields.findIndex((x) => x.alias === name) >= 0) {
        valueInFields = true;
      }
    });
    return valueInFields;
  }
}

export function setAdditionalFieldInComments(selectedItem: any, field: string, value: any) {
  if (!selectedItem.comments || selectedItem.comments === '') {
    selectedItem.comments = new Comments();
  }
  selectedItem.comments[field] = value;
}

/**
 * Function used to calculate the % position of the item inside the label
 * percentage of the position out of the label width or height.
 * @param item_position the item position ( x or y )
 * @param label_dimension the label dimension ( width or height )
 */
export function getItemPositionPercentage(rotationAngle: number, itemWidth: number, positionToGet: string, item_position: number, label_dimension: number, considerRotation: boolean, double?: boolean): number {
  if (rotationAngle === 270 && positionToGet === 'y' && considerRotation) {
    item_position += itemWidth;
  }
  if (rotationAngle === 180 && positionToGet === 'x' && considerRotation) {
    item_position += itemWidth;
  }

  const raw_position = item_position * 100000;
  const raw_dimension = label_dimension * 100000;
  const value = (raw_position / raw_dimension) * 100;
  if (double) {
    return parseFloat(value.toPrecision(4));
  } else {
    return Math.round(value);
  }
}

export function getRawPositionValueFromPercentage(position_percentage: number, label_dimension: number): number {
  return (label_dimension * position_percentage) / 100;
}

export function fix_item_x_axis_margin(editor: Editor, item: any, onItemDrop: boolean, rotated?: boolean): number {
  // we should consider the orientation when calculating
  const padding = editor.printMarginInch !== null && editor.printMarginInch >= 0 ? editor.printMarginInch : LABEL_PADDING;
  const labelWidth = editor._tl.width;
  const item_x = item.X || item.x;
  let item_width = item.Width || item.width;
  if (rotated) {
    item_width = item.Height || item.height;
  }
  if (item instanceof Neodynamic.SDK.Printing.ImageItem && item.rotation_angle > 0 && item.rotation_angle !== 180 && item.rotation_angle % 90 === 0) {
    item_width = item.Height || item.height;
  }
  const positionToLabel = item_width + item_x;
  let returnedPosition = null;

  if (item_x < padding) {
    returnedPosition = padding;
  } else if (positionToLabel >= labelWidth + padding) {
    const positionToBe = labelWidth - item_width - padding;
    returnedPosition = positionToBe;
  } else {
    returnedPosition = item_x;
  }
  if (onItemDrop) {
    returnedPosition = returnedPosition * (editor.zoom / 100);
  }
  return returnedPosition;
}

export function fix_item_y_axis_margin(editor: any, item: any, onItemDrop: boolean, rotated?: boolean): number {
  const padding = editor.printMarginInch !== null && editor.printMarginInch >= 0 ? editor.printMarginInch : LABEL_PADDING;
  // we should consider the orientation when calculating
  const labelHeight = editor._tl.height;
  const item_y = item.Y || item.y;
  let item_height = item.Height || item.height;
  if (rotated) {
    item_height = item.Width || item.width;
  }
  if (item instanceof Neodynamic.SDK.Printing.ImageItem && item.rotation_angle > 0 && item.rotation_angle !== 180 && item.rotation_angle % 90 === 0) {
    item_height = item.Width || item.width;
  }
  const positionToLabel = item_height + item_y;
  let returnedPosition = null;
  if (item_y < padding) {
    returnedPosition = padding;
  } else if (positionToLabel >= labelHeight + padding) {
    const positionToBe = labelHeight - item_height - padding;
    returnedPosition = positionToBe;
  } else {
    returnedPosition = item_y;
  }
  if (onItemDrop) {
    returnedPosition = returnedPosition * (editor.zoom / 100);
  }
  return returnedPosition;
}

export function parseToPrecision(number: number, precision: number = 3): number {
  return parseFloat(number.toFixed(precision));
}

export function fixItemForDayOfWeek(item: any, tleditor: Editor, orientation: string) {
  if (item.thermal_label_object && item.thermal_label_object._comments?.type === ComponentTypes.DAY_OF_WEEK) {
    return;
  }
  let new_y_position = 0;
  let new_x_position = 0;
  const dow_image = tleditor._tl.items.find((component) => component.comments.type === ComponentTypes.DAY_OF_WEEK)._fabric_item;
  const imageY = dow_image.oCoords.bl.y;
  const imageX = dow_image.oCoords.bl.x;

  if (item.intersectsWithObject(dow_image)) {
    const angle = Math.round(item.getAngle());
    let additionalSubstraction = angle === 180 ? item.getHeight() : angle === -90 || angle === 270 ? item.getWidth() : 0;

    if (orientation === Orientation.LANDSCAPE) {
      additionalSubstraction = angle === 0 || angle === 360 ? item.getWidth() : angle === -90 || angle === 270 ? item.getHeight() : 0;
      new_x_position = imageX - additionalSubstraction - 10;
      new_y_position = item.top;
    } else {
      new_x_position = item.left;
      new_y_position = imageY + additionalSubstraction + 10;
    }
    item.top = new_y_position;
    item.left = new_x_position;

    if (item.thermal_label_object) {
      item.setCoords();
      item.thermal_label_object._updateFromCanvas();
    } else {
      if (item._objects) {
        item._objects.forEach((o) => {
          o.setCoords();
          o.thermal_label_object._updateFromCanvas();
        });
        const objects = item._objects;
        item.destroy();
        let group = new fabric.Group(objects);
        const isWidget = tleditor.checkIfWidget(item);
        group = tleditor.setGroupOptions(group, isWidget);
        group.top = item.top;
        group.left = item.left;
        item = group;
        tleditor._canvasMouseUp({ e: null, target: item });
      }
    }
    tleditor._tlweCanvasFabric.renderAll();
  }
}

export function getDynamicItem(type: string): TextItem {
  const item: TextItem = new Printing.TextItem();
  item.uuid = uuidv4();
  setAdditionalFieldInComments(item, LabelItemProperties.TYPE, type);
  setAdditionalFieldInComments(item, LabelItemProperties.PLACEHOLDER, getItemPlaceHolder(type));
  preventDblClickEvent(item);
  item.text = item.comments.placeholder;
  return item;
}

/**
 * Function used to clear the double click event listener from the object.
 * @param item the desired Text item in the designer
 */
export function preventDblClickEvent(item: ThermalLabelItem) {
  item._fabric_item.__eventListeners['object:dblclick'] = [];
}

export function setEditorRestrictions(itemType: string, item: ThermalLabelItem) {
  switch (itemType) {
    case ComponentTypes.NUTRITION_FACT:
      item.scalingLocked = true;
      item.refresh();
      break;
    case ComponentTypes.DAY_OF_WEEK:
      item.editable = false;
      item.scalingLocked = true;
      item.locked = true;
      item._fabric_item.lockMovementX = true;
      item._fabric_item.lockMovementY = true;
      break;
    default:
      preventDblClickEvent(item);
      break;
  }
}

export function getItemPlaceHolder(type: string): string {
  let placeholder = '';
  switch (type) {
    case ComponentTypes.RFID_EPC:
      placeholder = '[EPC]';
      break;
    case ComponentTypes.STORE_NUMBER:
      placeholder = '[Location Number]';
      break;
    case ComponentTypes.STORE_NAME:
      placeholder = '[Location Name]';
      break;
    case ComponentTypes.STORE_ADDRESS:
      placeholder = '[Address Line 1]';
      break;
    case ComponentTypes.STORE_ADDRESS_2:
      placeholder = '[Address Line 2]';
      break;
    case ComponentTypes.STORE_CITY:
      placeholder = '[City]';
      break;
    case ComponentTypes.STORE_REGION:
      placeholder = '[State/ Region]';
      break;
    case ComponentTypes.STORE_ZIP:
      placeholder = '[Zip/ Postal Code]';
      break;
    case ComponentTypes.STORE_EMAIL:
      placeholder = '[Location Email]';
      break;
    case ComponentTypes.STORE_PHONE:
      placeholder = '[Location Phone]';
      break;
    case ComponentTypes.ITEM_PRINT_NAME:
    case ComponentTypes.PRODUCT_CAPTION:
      placeholder = '[Caption]';
      break;
    case ComponentTypes.TIMER_NAME:
      placeholder = '[Timer Name]';
      break;

    case ComponentTypes.ITEM_SEC_PRINT_NAME:
      placeholder = '[Secondary Caption]';
      break;

    case ComponentTypes.ITEM_DISPLAY_NAME:
      placeholder = '[Recipe Name]';
      break;

    case ComponentTypes.ITEM_SEC_DISPLAY_NAME:
      placeholder = '[Secondary Recipe Name]';
      break;

    case ComponentTypes.ITEM_NET_WEIGHT:
      placeholder = '[Net Weight]';
      break;

    case ComponentTypes.ITEM_TARE_WEIGHT:
      placeholder = '[Tare Weight]';
      break;

    case ComponentTypes.ITEM_SCALE_WEIGHT:
      placeholder = '[Weight (Scale)]';
      break;

    case ComponentTypes.CALCULATED_FIELD:
    case ComponentTypes.PRODUCT_CALCULATED_FIELD:
      placeholder = '[Calculated Field]';
      break;

    case ComponentTypes.PRODUCT_DISCOUNTED_CALCULATED_FIELD:
      placeholder = '[Discounted Calculated Field]';
      break;

    case ComponentTypes.ITEM_PRICE:
      placeholder = '[Price]';
      break;

    case ComponentTypes.BARCODE_VALUE:
    case ComponentTypes.PRODUCT_BARCODE_VALUE:
      placeholder = '[Barcode Value]';
      break;

    case ComponentTypes.ITEM_SHELF_LIFE_HOURS:
      placeholder = '[Expiration Date]';
      break;

    case ComponentTypes.ITEM_VARIANT_PARENT_NAME:
      placeholder = '[Name]';
      break;

    case ComponentTypes.ITEM_SEC_VARIANT_PARENT_NAME:
      placeholder = '[Secondary Name]';
      break;

    case ComponentTypes.ITEM_DESCRIPTION:
    case ComponentTypes.PRODUCT_DESCRIPTION:
      placeholder = '[Description]';
      break;
    case ComponentTypes.ITEM_SEC_DESCRIPTION:
      placeholder = '[Secondary Description]';
      break;
    case ComponentTypes.ITEM_PREP_DESCRIPTION:
      placeholder = '[Prep Description]';
      break;
    case ComponentTypes.ITEM_USE_BY_DESCRIPTION:
      placeholder = '[Use By Description]';
      break;

    case ComponentTypes.ADDON_PRINT_NAME:
    case ComponentTypes.ADDON_COMPOSITE_FIELD_CAPTION:
      placeholder = '[Addon Caption]';
      break;

    case ComponentTypes.ADDON_SEC_PRINT_NAME:
      placeholder = '[Addon S.Caption]';
      break;
    case ComponentTypes.ADDON_DISPLAY_NAME:
      placeholder = '[Addon Name]';
      break;

    case ComponentTypes.ADDON_SEC_DISPLAY_NAME:
      placeholder = '[Addon S.Name]';
      break;

    case ComponentTypes.ADDON_NET_WEIGHT:
      placeholder = '[Addon Net Weight]';
      break;

    case ComponentTypes.ADDON_PRICE:
      placeholder = '[Addon Price]';
      break;

    case ComponentTypes.ADDON_SHELF_LIFE_HOURS:
      placeholder = '[Addon Exp Date]';
      break;
    case ComponentTypes.ADDON_CALCULATED_FIELD:
      placeholder = '[ADDON Calculated Field]';
      break;
    case ComponentTypes.MANAGER_ON_DUTY:
      placeholder = '[Manager On Duty]';
      break;
    case ComponentTypes.EMPLOYEE_NAME:
      placeholder = '[Employee Name]';
      break;
    case ComponentTypes.EMPLOYEE_ID:
      placeholder = '[Employee ID]';
      break;
    // case ComponentTypes.CURRENT_DAY:
    //   placeholder = '[TODAY]';
    //   break;
    case ComponentTypes.INGREDIENTS:
      placeholder = '[Ingredients]';
      break;
    case ComponentTypes.MENUITEM_CALORIES:
      placeholder = '[Calories]';
      break;
    case ComponentTypes.ALLERGENS:
      placeholder = '[Allergens]';
      break;
    case ComponentTypes.CATEGORY:
      placeholder = '[Category]';
      break;
    case ComponentTypes.SEC_CATEGORY:
      placeholder = '[Secondary Category]';
      break;
    case ComponentTypes.COMPOSITE_FIELD:
      placeholder = '[Composite Field]';
      break;
    case ComponentTypes.ADDON_COMPOSITE_FIELD:
      placeholder = '[Add-ons]';
      break;
    case ComponentTypes.GROUP_TAGS:
      placeholder = '[Group Tags]';
      break;
    case ComponentTypes.RECIPES_FIELD:
      placeholder = '[Recipe Composition]';
      break;
    case ComponentTypes.PRICE_PER_WEIGHT:
      placeholder = '[Price / Weight]';
      break;
    case ComponentTypes.NEWBARCODE:
      placeholder = '[Barcode]';
      break;
    case ComponentTypes.CONDITIONAL_LABEL:
      placeholder = '[Conditional Label]';
      break;
    case ComponentTypes.RECTANGLE:
      placeholder = '[Rectangle]';
      break;
    case ComponentTypes.START_DATE:
      placeholder = '[Start Date]';
      break;
    case ComponentTypes.END_DATE:
      placeholder = '[End Date]';
      break;
    case ComponentTypes.PRODUCT_SKU:
      placeholder = '[SKU]';
      break;
    case ComponentTypes.PRODUCT_ORIGINAL_PRICE:
      placeholder = '[Original price]';
      break;
    case ComponentTypes.PRODUCT_CURRENT_PRICE:
      placeholder = '[Current price]';
      break;
    case ComponentTypes.PRODUCT_DISCOUNT_RATE:
      placeholder = '[Discount rate]';
      break;
    case ComponentTypes.PRODUCT_SIZE:
      placeholder = '[Size]';
      break;
    case ComponentTypes.PRODUCT_COLOR:
      placeholder = '[Color]';
      break;
    case ComponentTypes.PRODUCT_DEPARTMENT:
      placeholder = '[Department]';
      break;
    case ComponentTypes.BATCH_COUNT:
      placeholder = '[Batch Count]';
      break;
    case 'NomeItem.barcode':
    case 'NomeExtra.barcode':
      placeholder = '[Barcode Value]';
      break;
    default:
      placeholder = '[' + type + ']';
      break;
  }
  return placeholder;
}

export function getCompositeFieldsText(fields: ItemField[], dfsService, type: string, custom_fields, translateService): string {
  let newText = '';
  if (fields.length > 0 && fields[0].type) {
    for (let i = 0; i < fields.length; i++) {
      let itemText = '';
      if (fields[i].type) {
        if (fields[i].type === ComponentTypes.LABEL || fields[i].type === ComponentTypes.CONDITIONAL_LABEL) {
          itemText = fields[i].value || '';
        } else if (
          fields[i].type === ComponentTypes.DATE ||
          fields[i].type === ComponentTypes.DISCARD_DATE ||
          (fields[i].type === ComponentTypes.CUSTOM_FIELDS && getCustomeFieldTypeId(fields[i], custom_fields) === CustomFieldTypeEnum.Int) ||
          fields[i].type === ComponentTypes.START_DATE ||
          fields[i].type === ComponentTypes.END_DATE ||
          fields[i].type === ComponentTypes.PRODUCT_START_DATE ||
          fields[i].type === ComponentTypes.PRODUCT_END_DATE
        ) {
          let date = new Date();
          if (fields[i].endOfDay) {
            date = setAsEndOfDay(date);
          }
          const format =
            fields[i].type === ComponentTypes.DATE || fields[i].type === ComponentTypes.PRODUCT_START_DATE || fields[i].type === ComponentTypes.PRODUCT_END_DATE ? fields[i].formats : fields[i].date_additional_format;
          const formattedDate =
            fields[i].isCustomDateFormat || fields[i].formats === 'customDayOfWeek'
              ? dfsService.formatCustomDate(date, fields[i].customDateFormatOptions.customDateFormatData)
              : dfsService.formatDate(date, format || SUPPORTED_DATE_FROMATS[0].value, fields[i].localeId || this.localeId, false, fields[i].dateFormatSeperator);
          itemText = formattedDate;
        } else {
          itemText = getItemPlaceHolder(fields[i].type);
          if (fields[i].type.includes(ComponentTypes.CUSTOM_FIELDS)) {
            itemText = `[${fields[i].preType}]`;
          }
          if (fields[i].type.includes(ComponentTypes.PRICE_PER_WEIGHT) && fields[i].separatorText) {
            itemText = translateService.instant('label_designer.usable_objects.section_items.price_per_weight_seperator', { seperator: fields[i].separatorText });
          }
        }
        newText += `${fields[i].preText ? fields[i].preText : ''}${itemText}${fields[i].postText ? fields[i].postText : ''}`;
      }

      if (fields[i].breakLineAfter) {
        newText += `\n`;
      }
    }
  } else {
    newText = type === ComponentTypes.ADDON_COMPOSITE_FIELD ? getItemPlaceHolder(ComponentTypes.ADDON_COMPOSITE_FIELD) : getItemPlaceHolder(ComponentTypes.COMPOSITE_FIELD);
  }
  return newText;
}

export function getSelectedItemName(type: ThermalLabelItem): string {
  return getSelectedItemNameByType(type.comments.type);
}

export function getSelectedItemNameByType(type: string): string {
  switch (type) {
    case ComponentTypes.RFID_EPC:
      return 'table_headers.EPC';
    case ComponentTypes.BARCODE:
    case ComponentTypes.NEWBARCODE:
      return 'label_designer.usable_objects.section_items.barcode';
    case ComponentTypes.CURRENT_DAY:
      return 'label_designer.usable_objects.section_items.current_day';
    case ComponentTypes.DATE:
      return 'label_designer.usable_objects.section_items.date';
    case ComponentTypes.IMAGE:
      return 'label_designer.usable_objects.section_items.image';
    case ComponentTypes.ITEM_SHELF_LIFE_HOURS:
      return 'label_designer.usable_objects.section_items.shelf_life';
    case ComponentTypes.NUTRITION_FACT:
      return 'label_designer.usable_objects.section_items.nutrition_facts';
    case ComponentTypes.LABEL:
      return 'label_designer.usable_objects.section_items.text';
    case ComponentTypes.CUSTOM_FIELDS:
      return 'label_designer.usable_objects.section_items.custom_field';
    case ComponentTypes.KILL_DATE:
      return 'label_designer.usable_objects.section_items.kill_date';
    case ComponentTypes.REFERENCE_DISCARD_DATE:
      return 'label_designer.usable_objects.section_items.ref_discard_date';
    case ComponentTypes.DISCARD_DATE:
      return 'label_designer.usable_objects.section_items.discard_date';
    case ComponentTypes.ITEM_PRINT_NAME:
      return 'label_designer.usable_objects.section_items.caption';
    case ComponentTypes.TIMER_NAME:
      return 'label_designer.usable_objects.section_items.timer_name';
    case ComponentTypes.EMPLOYEE_NAME:
      return 'label_designer.usable_objects.section_items.employee_name';
    case ComponentTypes.LINE:
      return 'label_designer.usable_objects.section_items.line';
    case ComponentTypes.STORE_PHONE:
      return 'label_designer.usable_objects.section_items.store_phone';
    case ComponentTypes.STORE_LOGO_1:
      return 'locations_manager.customize_labeling_logo1';
    case ComponentTypes.STORE_LOGO_2:
      return 'locations_manager.customize_labeling_logo2';
    case ComponentTypes.STORE_NUMBER:
      return 'label_designer.usable_objects.section_items.store_number';
    case ComponentTypes.STORE_NAME:
      return 'label_designer.usable_objects.section_items.store_name';
    case ComponentTypes.STORE_ADDRESS_2:
      return 'label_designer.usable_objects.section_items.store_address_2';
    case ComponentTypes.STORE_ADDRESS:
      return 'label_designer.usable_objects.section_items.store_address';
    case ComponentTypes.STORE_CITY:
      return 'label_designer.usable_objects.section_items.store_city';
    case ComponentTypes.STORE_REGION:
      return 'label_designer.usable_objects.section_items.store_region';
    case ComponentTypes.STORE_ZIP:
      return 'label_designer.usable_objects.section_items.store_zip';
    case ComponentTypes.STORE_EMAIL:
      return 'label_designer.usable_objects.section_items.store_email';
    case ComponentTypes.EMPLOYEE_ID:
      return 'label_designer.usable_objects.section_items.employee_id';
    case ComponentTypes.ITEM_DISPLAY_NAME:
      return 'label_designer.usable_objects.section_items.recipe_name';
    case ComponentTypes.ITEM_NET_WEIGHT:
      return 'label_designer.usable_objects.section_items.net_weight';
    case ComponentTypes.ITEM_TARE_WEIGHT:
      return 'label_designer.usable_objects.section_items.tare_weight';
    case ComponentTypes.ITEM_SCALE_WEIGHT:
      return 'label_designer.usable_objects.section_items.scale_weight';
    case ComponentTypes.CALCULATED_FIELD:
    case ComponentTypes.PRODUCT_CALCULATED_FIELD:
      return 'label_designer.usable_objects.section_items.calculated_field';
    case ComponentTypes.PRODUCT_DISCOUNTED_CALCULATED_FIELD:
      return 'label_designer.usable_objects.section_items.discounted_calculated_field';
    case ComponentTypes.PRODUCT_START_DATE:
      return 'label_designer.usable_objects.section_items.start_date';
    case ComponentTypes.PRODUCT_END_DATE:
      return 'label_designer.usable_objects.section_items.end_date';
    case ComponentTypes.ITEM_PRICE:
      return 'label_designer.usable_objects.section_items.price';
    case ComponentTypes.BARCODE_VALUE:
    case ComponentTypes.PRODUCT_BARCODE_VALUE:
      return 'label_designer.usable_objects.section_items.barcode_value';
    case ComponentTypes.ITEM_VARIANT_PARENT_NAME:
      return 'label_designer.usable_objects.section_items.name';
    case ComponentTypes.ITEM_SEC_PRINT_NAME:
      return 'label_designer.usable_objects.section_items.sec_caption';
    case ComponentTypes.ITEM_SEC_DISPLAY_NAME:
      return 'label_designer.usable_objects.section_items.recipe_name';
    case ComponentTypes.ITEM_DISPLAY_NAME:
      return 'label_designer.usable_objects.section_items.name';
    case ComponentTypes.ADDON_PRINT_NAME:
      return 'label_designer.usable_objects.section_items.caption';
    case ComponentTypes.ITEM_DESCRIPTION:
    case ComponentTypes.PRODUCT_DESCRIPTION:
      return 'label_designer.usable_objects.section_items.description';
    case ComponentTypes.ITEM_SEC_DESCRIPTION:
      return 'label_designer.usable_objects.section_items.sec_description';
    case ComponentTypes.ITEM_PREP_DESCRIPTION:
      return 'label_designer.usable_objects.section_items.prep_description';
    case ComponentTypes.ITEM_USE_BY_DESCRIPTION:
      return 'label_designer.usable_objects.section_items.use_by_description';

    case ComponentTypes.ADDON_DISPLAY_NAME:
      return 'label_designer.usable_objects.section_items.name';
    case ComponentTypes.ADDON_NET_WEIGHT:
      return 'label_designer.usable_objects.section_items.net_weight';
    case ComponentTypes.ADDON_PRICE:
      return 'label_designer.usable_objects.section_items.price';
    case ComponentTypes.ADDON_PRINT_NAME:
      return 'label_designer.usable_objects.section_items.caption';
    case ComponentTypes.ADDON_SEC_DISPLAY_NAME:
      return 'label_designer.usable_objects.section_items.name';
    case ComponentTypes.ADDON_SEC_PRINT_NAME:
    case ComponentTypes.PRODUCT_CAPTION:
      return 'label_designer.usable_objects.section_items.caption';
    case ComponentTypes.ADDON_SHELF_LIFE_HOURS:
      return 'label_designer.usable_objects.section_items.shelf_life';
    case ComponentTypes.ADDON_CALCULATED_FIELD:
      return 'label_designer.usable_objects.section_items.calculated_field';
    case ComponentTypes.INGREDIENTS:
      return 'label_designer.usable_objects.section_items.ingredients';
    case ComponentTypes.MENUITEM_CALORIES:
      return 'label_designer.usable_objects.section_items.calories';
    case ComponentTypes.ALLERGENS:
      return 'label_designer.usable_objects.section_items.allergens';
    case ComponentTypes.CATEGORY:
      return 'label_designer.usable_objects.section_items.category';
    case ComponentTypes.SEC_CATEGORY:
      return 'label_designer.usable_objects.section_items.sec_category';
    case ComponentTypes.WIDGET_NF:
      return 'label_designer.usable_objects.section_items.nutrition_fact';
    case ComponentTypes.ADDON_COMPOSITE_FIELD:
      return 'label_designer.usable_objects.section_items.addon_composite_field';
    case ComponentTypes.COMPOSITE_FIELD:
      return 'label_designer.usable_objects.section_items.composite_field';
    case ComponentTypes.GROUP_TAGS:
      return 'label_designer.usable_objects.section_items.group_tags';
    case ComponentTypes.PRICE_PER_WEIGHT:
      return 'label_designer.usable_objects.section_items.price_per_weight';
    case ComponentTypes.RECIPES_FIELD:
      return 'label_designer.usable_objects.section_items.recipes_field';
    case ComponentTypes.CONDITIONAL_LABEL:
      return 'label_designer.usable_objects.section_items.conditional_text';
    case ComponentTypes.START_DATE:
      return 'label_designer.usable_objects.section_items.start_date';
    case ComponentTypes.END_DATE:
      return 'label_designer.usable_objects.section_items.end_date';
    case ComponentTypes.DAY_OF_WEEK:
      return 'label_designer.usable_objects.section_items.day_of_week_img';
    case ComponentTypes.RECTANGLE:
      return 'label_designer.usable_objects.section_items.rectangle';
    case ComponentTypes.WIDGET:
    case ComponentTypes.WIDGET_INFO_HOLDER:
      return 'label_designer.widget';
    case ComponentTypes.PRODUCT_SKU:
      return 'label_designer.usable_objects.section_items.sku';
    case ComponentTypes.PRODUCT_ORIGINAL_PRICE:
      return 'label_designer.usable_objects.section_items.original_price';
    case ComponentTypes.PRODUCT_CURRENT_PRICE:
      return 'label_designer.usable_objects.section_items.current_price';
    case ComponentTypes.PRODUCT_DISCOUNT_RATE:
      return 'label_designer.usable_objects.section_items.discount_rate';
    case ComponentTypes.PRODUCT_SIZE:
      return 'label_designer.usable_objects.section_items.size';
    case ComponentTypes.PRODUCT_COLOR:
      return 'label_designer.usable_objects.section_items.color';
    case ComponentTypes.PRODUCT_DEPARTMENT:
      return 'label_designer.usable_objects.section_items.department';
    case ComponentTypes.BATCH_COUNT:
      return 'label_designer.usable_objects.section_items.batch_count';
    case ComponentTypes.VOICE_PICK:
      return 'label_designer.usable_objects.section_items.voice_pick';
    default:
      return '';
  }
}

export function getInfoData(component_type: string): string {
  switch (component_type) {
    case ComponentTypes.START_DATE:
      return 'label_designer.usable_objects.info_data.start_date';
    case ComponentTypes.END_DATE:
      return 'label_designer.usable_objects.info_data.end_date';
    default:
      return null;
  }
}

/**
 * Image url to be rendered.
 * return base 64 string in the promise then
 * @param url Image to be rendered URL
 */

export function getBase64ImageFromUrl(url: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    let dataUrl = '';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      dataUrl = canvas.toDataURL();
      resolve(dataUrl);
    };
    image.src = url;
  });
}

/**
 * Function to set the attributes of sizing, reads it from comments and set it as xml attribtue and then re-serialize the xml
 * @param xml
 */
export function cleanXML(xml: string) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const items = xmlDoc.getElementsByTagName('ThermalLabel')[0].children[0];
  if (items) {
    for (let index = 0; index < items.childElementCount; index++) {
      const item = items.children[index];
      if (item.hasAttribute('Comments')) {
        if (item.getAttribute('Comments').includes('shrinkToFit_x0022_:true')) {
          item.setAttribute('Sizing', 'Stretch');
        }
      }
    }
    const oSerializer = new XMLSerializer();
    const sXML = oSerializer.serializeToString(xmlDoc);
    return sXML;
  } else {
    return xml;
  }
}

export function updateDOWXML(xml: string, updateObj) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const thermalLabel = xmlDoc.getElementsByTagName('ThermalLabel')[0];
  if (updateObj.ThermalLabel) {
    for (const attr in updateObj.ThermalLabel) {
      thermalLabel.setAttribute(attr, updateObj.ThermalLabel[attr]);
    }
  }
  const items = thermalLabel.children[0];
  if (items) {
    for (let index = 0; index < items.childElementCount; index++) {
      if (updateObj.IsDayOfWeek) {
        const item = items.children[index];
        if (item.hasAttribute('Name')) {
          if (item.getAttribute('Name').includes('IsDayOfWeek') || item.getAttribute('Comments').includes('dayOfWeek')) {
            for (const attr in updateObj.IsDayOfWeek) {
              item.setAttribute(attr, updateObj.IsDayOfWeek[attr]);
            }
          }
        }
      }
    }
    const oSerializer = new XMLSerializer();
    const sXML = oSerializer.serializeToString(xmlDoc);
    return sXML;
  } else {
    return xml;
  }
}

export function updateContentXML(xml: string, updateObj) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  updateObj.forEach((el) => {
    const updateTag = xmlDoc.getElementsByTagName(el.tag)[0];
    updateTag.setAttribute(el.attr, el.value);
  });

  const oSerializer = new XMLSerializer();
  const sXML = oSerializer.serializeToString(xmlDoc);
  return sXML;
}

export function getPropertyValueFromXML(xml: string, propertyObj) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const tag = xmlDoc.getElementsByTagName(propertyObj.tag)[0];
  if (tag.hasAttribute(propertyObj.attr)) {
    return tag.getAttribute(propertyObj.attr);
  }
}

export function getEditorSelectedItems(tleditor: Editor) {
  let items = null;
  if (tleditor.current_selection) {
    items = tleditor.current_selection;
  } else if (tleditor.current_selections) {
    items = tleditor.current_selections;
  }
  return items;
}

export function getEditorItemByUuid(tleditor: Editor, uuid: string): ThermalLabelItem {
  return tleditor._tl.items?.find((item) => item.uuid === uuid);
}

export function getItemObjectWidth(unitUtils, item): number {
  const charsCount = item.text.split('\n').reduce((a, b) => (a.length > b.length ? a : b)).length;
  const textSize = unitUtils.convertFontUnitToPixel(Math.floor(item.font.size), 3); // 3 is for points
  const width = unitUtils.convertPixelToUnit(textSize * (charsCount / 2), 1); // is for inches
  return width;
}

export function getItemObjectHeight(unitUtils, item, rows: number): number {
  const textSize = unitUtils.convertFontUnitToPixel(Math.floor(item.font.size), 3); // 3 is for points
  const height = unitUtils.convertPixelToUnit(textSize * (rows * 1.2), 1); // is for inches
  return height;
}

/**
 * @param xml labelXml
 * @param labelDimensions new dimensions
 */
export function rotateLabelXml(xml: XMLDocument, labelDimensions: LabelDimension) {
  xml.getElementsByTagName('ThermalLabel')[0].setAttribute('Height', labelDimensions.height.toString());
  xml.getElementsByTagName('ThermalLabel')[0].setAttribute('Width', labelDimensions.width.toString());
}

export function serializeXml(xmlString: XMLDocument) {
  return new XMLSerializer().serializeToString(xmlString);
}

export function setNewPositions(item: any, new_x_position: number, new_y_position: number, new_rotation: number): void {
  item.setAttribute('X', new_x_position.toFixed(4));
  item.setAttribute('Y', new_y_position.toFixed(4));
  item.setAttribute('Rotationangle', new_rotation.toString());
}

export function returnItemTypeHasProps(item: ThermalLabelItem) {
  const type = item.comments.type;
  if (
    type === ComponentTypes.DATE ||
    type === ComponentTypes.IMAGE ||
    type === ComponentTypes.BARCODE ||
    type === ComponentTypes.NEWBARCODE ||
    type === ComponentTypes.CURRENT_DAY ||
    type === ComponentTypes.ITEM_SHELF_LIFE_HOURS ||
    type === ComponentTypes.ADDON_SHELF_LIFE_HOURS ||
    type === ComponentTypes.NUTRITION_FACT ||
    type === ComponentTypes.LABEL ||
    type === ComponentTypes.REFERENCE_DISCARD_DATE ||
    type === ComponentTypes.DISCARD_DATE ||
    type === ComponentTypes.WIDGET_NF ||
    (type === ComponentTypes.CUSTOM_FIELDS && item.comments.typeId && item.comments.typeId === 3)
  ) {
    return true;
  }
}

/**
 * Function used to get a screenshot of the canvas as is
 */
export function getLabelSketch(export_label?: boolean): string {
  const canvas = <HTMLCanvasElement>document.getElementById('tlweCanvas');
  const ctx = canvas.getContext('2d');
  ctx.strokeStyle = '#A9A9A9';
  ctx.strokeRect(0, 0, canvas.width, canvas.height);
  const base64Image = canvas.toDataURL();
  return export_label ? '' : base64Image;
}

export function updatePositionsAfterRotate(objects: any[]) {
  objects.forEach((object) => {
    if (object.thermal_label_object) {
      object.thermal_label_object._updateFromCanvas();
    }
    if (object._objects && object._objects.length > 0) {
      updatePositionsAfterRotate(object._objects);
    }
  });
}

export function ungroup(_group: any, canvas: any) {
  if (_group.type === 'group') {
    const items = _group._objects;
    if (_group._objects && _group._objects.length > 0) {
      _group._objects.forEach((o) => {
        setCoordsRecursive(o);
      });
    }
    _group._restoreObjectsState();
    canvas.remove(_group);
    for (let i = 0; i < items.length; i++) {
      canvas.add(items[i]);
    }
    canvas.renderAll();
  }
}

export function setCoordsRecursive(object: any) {
  object.setCoords();
  if (object._objects && object._objects.length > 0) {
    object._objects.forEach((o) => setCoordsRecursive(o));
  }
}

export function getIterationTextValue(map_value: string, itemPlaceholder: string, precision: number): string {
  const precisionArr = [];
  for (let i = 0; i < precision; i++) {
    precisionArr.push(0);
  }
  const textAfterPrecision = `${precisionArr.length > 0 ? '0.' : '0'}${precisionArr.join('').toString()}`;
  switch (map_value) {
    case LabelItemProperties.FACT_NAME:
      return `${itemPlaceholder}`;
    case LabelItemProperties.VALUE_1:
    case LabelItemProperties.VALUE_2:
    case LabelItemProperties.VALUE_3:
    case LabelItemProperties.VALUE_4:
    case LabelItemProperties.VALUE_5:
    case LabelItemProperties.VALUE_6:
    case LabelItemProperties.VALUE_7:
      return textAfterPrecision;
    case LabelItemProperties.DAILY_VALUE:
      let dvPrec = null;
      if (precisionArr.length > 0) {
        dvPrec = `${textAfterPrecision}`;
      } else {
        dvPrec = '00';
      }
      return dvPrec;
    case LabelItemProperties.DAILY_VALUE_2:
    case LabelItemProperties.DAILY_VALUE_3:
    case LabelItemProperties.DAILY_VALUE_4:
    case LabelItemProperties.DAILY_VALUE_5:
    case LabelItemProperties.DAILY_VALUE_6:
    case LabelItemProperties.DAILY_VALUE_7:
      let dv2Prec = null;
      if (precisionArr.length > 0) {
        dv2Prec = `${textAfterPrecision}`;
      } else {
        dv2Prec = '00';
      }
      return dv2Prec;
    default:
      return null;
  }
}

export function showUOMEnabeld(map_value: string): boolean {
  if (map_value !== LabelItemProperties.FACT_NAME) {
    return true;
  }
  return false;
}

export function firstLetterUpperCase(string: string) {
  if (string) {
    const splitText = string.split('');
    splitText[0] = splitText[0].toUpperCase();
    return splitText.join('');
  }
  return null;
}

export function createWidgetObjectItems(widgetArray: Widget[]): DesignerSectionItem[] {
  const itemsArray: DesignerSectionItem[] = [];
  widgetArray.forEach((widget) => {
    const item = new DesignerSectionItem(widget.name, ComponentTypes.WIDGET);
    item.id = widget.widgetId;
    item.typeName = widget.type;
    item.profileId = widget.profileId;
    item.profileName = widget.profileName;
    item.width = widget.width;
    item.height = widget.height;
    itemsArray.push(item);
  });
  return itemsArray;
}

export function getSection(sectionsArray: DesignerSection[], sectionId: number): DesignerSection {
  return sectionsArray.find((section) => section.section_id === sectionId);
}

export function hideSection(sectionsArray: DesignerSection[], sectionId: number): void {
  const section = getSection(sectionsArray, sectionId);
  section.enabled = false;
}

export function showSection(sectionsArray: DesignerSection[], sectionId: number): void {
  const section = getSection(sectionsArray, sectionId);
  section.enabled = true;
}

export function enableDisableSectionItem(sectionsArray: DesignerSection[], sectionId: number, itemMapValue: string, disable: boolean, tooltip: string): void {
  const section = getSection(sectionsArray, sectionId);
  const item = section.section_items.find((item) => item.map_value === itemMapValue);
  if (item) {
    item.disabled = disable;
    item.tooltip = tooltip;
  }
}

export function setNewGroupsPositions(group: any) {
  let groupTop = group.getTop();
  let groupLeft = group.getLeft();
  const groupHeight = group.getHeight();
  const groupWidth = group.getWidth();
  switch (group.angle) {
    case 90:
      groupLeft -= groupHeight;
      break;
    case 180:
      break;
    case 270:
    case -90:
      groupTop -= groupWidth;
      break;
    case 0:
      break;
  }
  group.setLeft(groupLeft);
  group.setTop(groupTop);
}
export function reAddItemsToItemsArray(objects: any[], editor: Editor) {
  const editorItems = objects // .filter(_objects => _objects.type !== 'group')
    .filter((_o) => _o && _o.hasOwnProperty('thermal_label_object'))
    .map((_object) => _object.thermal_label_object);
  editor._tl.items = editorItems;
}
export function downloadFile(data: string, filename: string, type) {
  const file = new Blob([data], { type: type });
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(file, filename);
  } else {
    // Others
    const a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export function isItemAllowedForRemotePrinting(item): boolean {
  if (item.comments && item.comments.type && !item.comments.groupId) {
    return OBJECTS_ALLOWED_FOR_REMOTE_PRINTING.includes(item.comments.type);
  }
  return false;
}

export function adjustObjectsForisAvailableForRemotePrintingLabel(designerSections) {
  return _.map(designerSections, (section) => {
    let section_items = _.filter(section.section_items, (item) => item.map_value !== 'calculatedField' && item.map_value !== 'conditionalLabel');
    return Object.assign({}, section, { section_items });
  }).filter((section) => !(section.section_id === DesignerSections.MENU_ITEM || section.section_id === DesignerSections.TIMER || section.section_id === DesignerSections.NF_WIDGETS));
}

export function adjustObjectsForTenantType(designerSections, tenantTypeId?) {
  if (tenantTypeId && tenantTypeId == TenantTypeEnum.RETAIL) {
    designerSections.find((section) => section.section_id == DesignerSections.CATALOG_ITEM).enabled = true;
    designerSections.find((section) => section.section_id == DesignerSections.MENU_ITEM).enabled = false;
    designerSections.find((section) => section.section_id == DesignerSections.NF_WIDGETS).enabled = false;
    designerSections.find((section) => section.section_id == DesignerSections.NUTRITION_FACTS).enabled = false;

    const generalSection = getSection(designerSections, DesignerSections.GENERAL);
    const disabeledItemsInGeneralSection = ['batchCount'];
    generalSection.section_items = generalSection.section_items.filter((x) => !disabeledItemsInGeneralSection.includes(x.map_value));
  }
  return designerSections;
}
export function getItemSectionByTenantType(sections, tenantTypeId) {
  return sections.find((section) => section.section_id === (tenantTypeId === TenantTypeEnum.FOOD ? DesignerSections.MENU_ITEM : DesignerSections.CATALOG_ITEM));
}

export function hasLabelObjectsForRemotePrinting(xml): boolean {
  let hasObject = false;
  const notAllowedObjectsType = [
    'conditionalLabel',
    'calculatedField',
    'NomeItem.printName',
    'NomeItem.price',
    'NomeItem.shelfLifeHours',
    'NomeItem.CustomField',
    'ingredients',
    'allergens',
    'NomeItem.netWeightValue',
    'discardDate',
    'referenceDiscardDate',
    'NomeItem.secPrintName',
    'NomeItem.category',
    'NomeItem.secCategory',
    'addonCompositeField',
    'groupTags',
    'recipesField',
    'startDate',
    'endDate',
    'timerName',
    'widgetInfoHolder'
  ];
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const items = xmlDoc.getElementsByTagName('ThermalLabel')[0].children[0];
  if (items) {
    notAllowedObjectsType.forEach((type) => {
      for (let index = 0; index < items.childElementCount; index++) {
        const item = items.children[index];
        if (item.hasAttribute('Comments')) {
          if (item.getAttribute('Comments').includes(`type_x0022_:_x0022_${type}_x0022_`)) {
            hasObject = true;
          }
        }
      }
    });
  }
  return hasObject;
}

export function checkForItemErorrs(editor: Editor, item, featureList, label?: LabelDetailsModel, widget?: Widget) {
  let errors = [];
  if (featureList.includes(LocalStorageFlags.hasRemotePrinting)) {
    const notAllowedObjectsTypeForRemotePrinting = [
      'conditionalLabel',
      'calculatedField',
      'NomeItem.printName',
      'NomeItem.price',
      'NomeItem.shelfLifeHours',
      'NomeItem.CustomField',
      'ingredients',
      'allergens',
      'NomeItem.netWeightValue',
      'discardDate',
      'referenceDiscardDate',
      'NomeItem.secPrintName',
      'NomeItem.category',
      'NomeItem.secCategory',
      'addonCompositeField',
      'groupTags',
      'recipesField',
      'startDate',
      'endDate',
      'timerName',
      'widgetInfoHolder'
    ];

    if (item && item.comments && item.comments.type) {
      if (item.comments.type === ComponentTypes.COMPOSITE_FIELD) {
        let hasNotSupportedType = item.comments.compositeFields.some((compositeField) => notAllowedObjectsTypeForRemotePrinting.includes(compositeField.type));
        if (hasNotSupportedType) {
          errors.push('label_designer.remote_printing.messages.object_not_supported');
        }
      }
      if (notAllowedObjectsTypeForRemotePrinting.includes(item.comments.type)) {
        errors.push('label_designer.remote_printing.messages.object_not_supported');
      }
    }
  }

  if (item && item.comments) {
    const notNameAtPrinterStorage: boolean = !(item instanceof Neodynamic.SDK.Printing.ImageItem) || (item instanceof Neodynamic.SDK.Printing.ImageItem && item['name_at_printer_storage'] !== 'canvasBackground');

    let item_width = item.width;
    let item_height = item.height;
    const height = label ? label.height - label.printMarginRatio : widget.height;
    const width = label ? label.width - label.printMarginRatio : widget.width;
    const printMarginRatio = label ? label.printMarginRatio : 0;
    // Check object rotation and swap the value for width and height used for calculation
    const namePattern = /^[\p{L}\p{N}\s]*$/u;
    const isAliasNameNotValid = !namePattern.test(item.comments?.alias);
    if (item.comments && isAliasNameNotValid) {
      errors.push('form_validation.only_letters_and_numbers');
    }
    if (item.comments && !item.comments.alias && aliasIsReferenced(item.comments.uuid, editor, label)) {
      errors.push('label_designer.errors.object_in_use');
    }
    if (item.rotation_angle === 90 || item.rotation_angle === 270) {
      item_width = item.height;
      item_height = item.width;
    }
    if (notNameAtPrinterStorage && (item.y + item_height > height + 0.01 || item.x + item_width > width + 0.01 || item.y < printMarginRatio || item.x < printMarginRatio)) {
      errors.push('form_validation.outside_margins');
    }
    if (item.comments.type === ComponentTypes.LABEL) {
      if (!item.text.length) {
        errors.push('form_validation.text_not_empty');
      }
    }
    if (item.comments.type === ComponentTypes.IMAGE) {
      if (!item.source_base64 && !item.comments.customCode) {
        errors.push('form_validation.select_image');
      }
    }
    if (item.comments.type === ComponentTypes.COMPOSITE_FIELD || selectedFieldIsAddon(item.comments.type)) {
      if (!item.comments.compositeFields.length) {
        errors.push('form_validation.field_not_empty');
      }
    }
    if (item.comments.type === ComponentTypes.BARCODE_VALUE) {
      if (item.comments.formatter < 1) {
        errors.push('form_validation.number_field_less_than_one');
      }
    }
    if (item.comments.type === ComponentTypes.NEWBARCODE) {
      if (item.comments.upcData) {
        for (var key in item.comments.upcData) {
          if (item.comments.upcData[key].hasOwnProperty('type') && !item.comments.upcData[key]['type']) {
            errors.push('form_validation.field_not_empty');
          }
        }
      }
    }
  }

  return errors;
}

export function selectedFieldIsAddon(type: string) {
  switch (type) {
    case ComponentTypes.ADDON_DISPLAY_NAME:
    case ComponentTypes.ADDON_NET_WEIGHT:
    case ComponentTypes.ADDON_PRICE:
    case ComponentTypes.ADDON_PRINT_NAME:
    case ComponentTypes.ADDON_SEC_DISPLAY_NAME:
    case ComponentTypes.ADDON_SEC_PRINT_NAME:
    case ComponentTypes.ADDON_SHELF_LIFE_HOURS:
    case ComponentTypes.ADDON_CALCULATED_FIELD:
    case ComponentTypes.ADDON_COMPOSITE_FIELD:
    case ComponentTypes.ADDON_COMPOSITE_FIELD_CAPTION:
    case ComponentTypes.ADDON_BARCODE_VALUE:
      return true;
    default:
      return false;
  }
}
export function itemsHasRefDiscardDate(items) {
  return items.find((item) => item.comments.type === ComponentTypes.REFERENCE_DISCARD_DATE);
}

export function getUserInputFields(editor: Editor, selectedItem?: any): DesignerSectionItem[] {
  const itemsToAdd: DesignerSectionItem[] = [];
  editor._tl.items.forEach((item) => {
    const data = item.comments;
    if (selectedItem === item) {
      return;
    }
    if (data.alias && (data.require_user_input || data.hasReferenceShelfLife) && (!data.referralId || data.referralId === 'undefined')) {
      const itemtoAdd: DesignerSectionItem = { name: data.alias, map_value: data.type, referralId: data.uuid };
      itemsToAdd.push(itemtoAdd);
    }
    if (data[LabelItemProperties.COMPOSITE_FIELDS]?.length >= 0) {
      data[LabelItemProperties.COMPOSITE_FIELDS].forEach((innerItem) => {
        if (innerItem.alias && (innerItem.requireUserInput || innerItem.require_user_input || innerItem.hasReferenceShelfLife) && (!innerItem.referralId || innerItem.referralId === 'undefined')) {
          const itemtoAdd: DesignerSectionItem = { name: innerItem.alias, map_value: innerItem.type, referralId: innerItem.uuid };
          itemsToAdd.push(itemtoAdd);
        }
      });
    }
    if (data.upcData) {
      for (var key in data.upcData) {
        if (data.upcData[key].alias && data.upcData[key].requireUserInput) {
          const itemtoAdd: DesignerSectionItem = { name: data.upcData[key].alias, map_value: data.upcData[key].type, referralId: data.upcData[key].uuid };
          itemsToAdd.push(itemtoAdd);
        }
        if (data.upcData[key] instanceof Array && data.upcData[key].length > 0) {
          item.comments.upcData.additionalFields.forEach((additionalItem) => {
            if (additionalItem.alias && additionalItem.requireUserInput) {
              const itemtoAdd: DesignerSectionItem = { name: additionalItem.alias, map_value: additionalItem.type, referralId: additionalItem.uuid };
              itemsToAdd.push(itemtoAdd);
            }
          });
        }
      }
    }
  });
  return itemsToAdd.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });
}

export function getCompositeFields(editor: Editor, selectedItem?: any): DesignerSectionItem[] {
  const itemsToAdd: DesignerSectionItem[] = [];
  editor._tl.items.forEach((item) => {
    const data = item.comments;
    if (selectedItem === item) {
      return;
    }
    if (data.type === ComponentTypes.COMPOSITE_FIELD && data.alias) {
      const itemtoAdd: DesignerSectionItem = { name: data.alias, map_value: data.type, referralId: data.uuid };
      itemsToAdd.push(itemtoAdd);
    }
  });
  return itemsToAdd.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });
}

export function getBarcodeFields(editor: Editor, selectedItem?: any): DesignerSectionItem[] {
  const itemsToAdd: DesignerSectionItem[] = [];
  editor._tl.items.forEach((item) => {
    const data = item.comments;
    if (selectedItem === item) {
      return;
    }
    if (data.type === ComponentTypes.NEWBARCODE && data.extraType === 'dynamic' && data.alias) {
      const itemtoAdd: DesignerSectionItem = { name: data.alias, map_value: data.type, referralId: data.uuid };
      itemsToAdd.push(itemtoAdd);
    }
  });
  return itemsToAdd.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });
}

export function addUserInputsFields(section: DesignerSection, editor: Editor, selectedItem?: any) {
  const itemsToAdd: DesignerSectionItem[] = getUserInputFields(editor, selectedItem);
  updateSectionDependingOnItems(section, itemsToAdd);
}

export function addCompositeFields(section: DesignerSection, editor: Editor, selectedItem?: any) {
  const itemsToAdd: DesignerSectionItem[] = getCompositeFields(editor, selectedItem);
  updateSectionDependingOnItems(section, itemsToAdd);
}

export function addBarcodeFields(section: DesignerSection, editor: Editor, selectedItem?: any) {
  const itemsToAdd: DesignerSectionItem[] = getBarcodeFields(editor, selectedItem);
  updateSectionDependingOnItems(section, itemsToAdd);
}

export function updateSectionDependingOnItems(section: DesignerSection, itemsToAdd: DesignerSectionItem[]) {
  if (itemsToAdd.length > 0) {
    section.enabled = true;
    section.section_items = itemsToAdd;
  } else {
    section.enabled = false;
  }
}

export function createAtmaFieldMapFromMap(atmaFields: AtmaFields[], values?: atmaFieldMapItem, isPrimaryId?): UntypedFormGroup {
  const localDisplayName = values ? (values.displayName ? values.displayName : atmaFields.find((x) => x.name === values.atmaField)?.displayName) : null;
  let field = new UntypedFormGroup({
    objectFieldType: new UntypedFormControl(values ? values.objectFieldType : null, Validators.required),
    atmaField: new UntypedFormControl(values ? values.atmaField : null, Validators.required),
    shelfLife: new UntypedFormControl(values ? values.shelfLife : null),
    displayName: new UntypedFormControl(localDisplayName),
    isPrimaryId: new UntypedFormControl(isPrimaryId)
  });
  if (isPrimaryId) {
    field.get('atmaField').disable();
  }
  return field;
}

export function createAtmaFieldMap(atmaFields: AtmaFields[], values?: atmaField, isPrimaryId?): UntypedFormGroup {
  let objectFieldType = null;
  if (values && values.objectField) {
    if (values.objectField.type === ComponentTypes.CUSTOM_FIELDS && 'format' in values.objectField) {
      objectFieldType = values.objectField?.type + '$' + values.objectField?.format;
    } else if ('referralId' in values.objectField) {
      let type = values.objectField?.type;
      if (values.objectField?.type === 'date') {
        type = ComponentTypes.DATE;
      }
      objectFieldType = type + ',' + values.objectField.referralId;
    } else if (values.objectField.type === 'date') {
      objectFieldType = ComponentTypes.DATE;
    } else {
      objectFieldType = values.objectField.type;
    }
  }
  const localDisplayName = values ? (values.displayName ? values.displayName : atmaFields.find((x) => x.name === values.atmaField)?.displayName) : null;
  let field = new UntypedFormGroup({
    objectFieldType: new UntypedFormControl(objectFieldType, Validators.required),
    atmaField: new UntypedFormControl(values ? values.atmaField : null, Validators.required),
    shelfLife: new UntypedFormControl(values ? (values.objectField && typeof values.objectField === 'object' && 'discardDate' in values.objectField ? values.objectField.discardDate?.type : null) : null),
    displayName: new UntypedFormControl(localDisplayName),
    isPrimaryId: new UntypedFormControl(isPrimaryId)
  });
  if (isPrimaryId) {
    // disabled only primary value
    field.get('atmaField').disable();
  }
  return field;
}

export function createObjectFieldBasedOnType(mapItem: atmaFieldMapItem, custome_fields: DesignerSectionItem[]): atmaField {
  let object: atmaField = new atmaField(mapItem);
  switch (object.objectField.type) {
    case ComponentTypes.DISCARD_DATE:
      object.objectField = new DiscardDateItem(object, mapItem);
      break;
    case ComponentTypes.CUSTOM_FIELDS:
      object.objectField = new CustomFieldItem(object, mapItem, custome_fields);
      break;
    case ComponentTypes.DATE:
      object.objectField = new DateItem(object);
      break;
    case ComponentTypes.MENUITEM_CALORIES:
      object.objectField = new NutritionalFactITem(object, mapItem);
      break;
  }
  return object;
}
export function setVoicePickHandlesControl(item) {
  item._fabric_item.setControlVisible('mb', false);
  item._fabric_item.setControlVisible('mt', false);
  item._fabric_item.setControlVisible('mr', false);
  item._fabric_item.setControlVisible('ml', false);
  item._fabric_item.setControlVisible('tl', false);
  item._fabric_item.setControlVisible('tr', false);
  item._fabric_item.setControlVisible('bl', false);
  item._fabric_item.setControlVisible('br', false);
  item.refresh();
}

export function isDayOfWeek(editor: Editor): boolean {
  if (editor && editor._tl && editor._tl.items) {
    return editor._tl.items.some((item) => item.comments.type === ComponentTypes.DAY_OF_WEEK);
  }
}

export function aliasReferenced(selectedItem: any, editor: Editor, label: LabelDetailsModel): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const aliasIsInside = defined(control.parent?.get(LabelItemProperties.UUID)); // bcz composite field has uuid in its form
    let uuid: string;

    if (aliasIsInside) {
      uuid = control.parent.get(LabelItemProperties.UUID).value;
    } else {
      uuid = getObjectPropertyValue(selectedItem.comments, LabelItemProperties.UUID);
    }

    const referenced = aliasIsReferenced(uuid, editor, label);
    const aliasIsEmpty = notDefinedOrEmptyString(control.value);

    return referenced && aliasIsEmpty ? { [FormErrosEnum.labelObjectInUse]: true } : null;
  };
}

export function aliasIsReferenced(uuid: string, editor: Editor, label: LabelDetailsModel): boolean {
  const aliasIsReferencedInCompositeOrBarcode = editor._tl.items
    .filter((object) => object.comments.type === ComponentTypes.COMPOSITE_FIELD || object.comments.type === ComponentTypes.NEWBARCODE)
    .some((item) => {
      const fields = item.comments[LabelItemProperties.COMPOSITE_FIELDS];
      const aliasReferenced = fields?.some((f) => f.referralId === uuid);
      return aliasReferenced;
    });
  const aliasReferencedInAtma = label?.rfidSettings?.atmaFieldMap?.some((map) => map.objectField?.referralId && map.objectField.referralId === uuid);
  const referenced = aliasIsReferencedInCompositeOrBarcode || aliasReferencedInAtma;
  return referenced;
}

export function aliasFieldValidator(editor: Editor): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const namePattern = /^[\p{L}\p{N}\s]*$/u;
    const isAliasNameNotValid = !namePattern.test(control.value);
    let isAliasNameNotUnique = false;
    if (control && editor) {
      let numberOfTimesTheNameExist = 0;
      editor._tl.items.forEach((field) => {
        const isSame = (f: any) => definedAndNotEmptyString(f.alias) && definedAndNotEmptyString(control.value) && f.alias === control.value;
        const same = isSame(field.comments);
        if (same) {
          numberOfTimesTheNameExist++;
        }

        field.comments.compositeFields?.forEach((f) => {
          const same = isSame(f);
          if (same) {
            numberOfTimesTheNameExist++;
          }
        });

        Object.values(field.comments.upcData || {}).forEach((f: any) => {
          if (isObject(f)) {
            const same = isSame(f);
            if (same) {
              numberOfTimesTheNameExist++;
            }
          } else if (f instanceof Array) {
            f.forEach((internalItem) => {
              if (isSame(internalItem)) {
                numberOfTimesTheNameExist++;
              }
            });
          }
        });

        Object.values(field.comments.voicePickData || {}).forEach((f) => {
          if (isObject(f)) {
            const same = isSame(f);
            if (same) {
              numberOfTimesTheNameExist++;
            }
          }
        });
      });

      isAliasNameNotUnique = numberOfTimesTheNameExist > 1;
    }
    let validationConfig = null;
    if (isAliasNameNotValid) {
      validationConfig = {
        isAliasNameNotValid: isAliasNameNotValid
      };
    }
    if (isAliasNameNotUnique) {
      validationConfig = {
        isAliasNameNotUnique: isAliasNameNotUnique
      };
    }

    return validationConfig;
  };
}

export function isSomeObjectOutsideByWidthHeight(editor: Editor, labelHeight: number, labelWidth: number): boolean {
  return editor._tl.items.some((item) => {
    const notNameAtPrinterStorage: boolean = !(item instanceof Neodynamic.SDK.Printing.ImageItem) || (item instanceof Neodynamic.SDK.Printing.ImageItem && item['name_at_printer_storage'] !== 'canvasBackground');
    //console.log(`labelWidth: ${labelWidth}`);
    //console.log(`labelHeight: ${labelHeight}`);
    //console.log(`item.x: ${item.x}`);
    //console.log(`item.y: ${item.y}`);
    //console.log(`item.width: ${item.width}`);
    //console.log(`item.height: ${item.height}`);
    //console.log(`item.y + item.height: ${item.y + item.height}`);
    //console.log(`item.x + item.width: ${item.x + item.width}`);
    //console.log(`item.rotation_angle: ${item.rotation_angle}`);

    let item_width = item.width;
    let item_height = item.height;

    // Check object rotation and swap the value for width and height used for calculation
    if (item.rotation_angle === 90 || item.rotation_angle === 270) {
      item_width = item.height;
      item_height = item.width;
    }

    return notNameAtPrinterStorage && (item.y + item_height > labelHeight + 0.01 || item.x + item_width > labelWidth + 0.01 || item.y < 0 || item.x < 0);
  });
}
export function checkReferencedObjects(
  selectedItem: any,
  removeReferences: boolean,
  editor: Editor,
  dfsService: any,
  customFields: DesignerSectionItem[],
  translateService: any,
  rfidSettings: RfidSettings,
  cumulativeConflicts?: ConflictData
): boolean | ConflictData {
  let result: boolean = false;
  let conflicts: ConflictData = cumulativeConflicts || {
    compositeFields: 0,
    barcodes: 0,
    atmaFields: 0
  };

  if (selectedItem) {
    // Go through each of the other objects and see if any contain a reference to the object being deleted.
    const selectedItemId = selectedItem._comments?.uuid || selectedItem.uuid;
    // some item under composite field will not have uuid therefore they should not be checked
    if (!selectedItemId) {
      return;
    }
    editor._tl.items.forEach((item) => {
      const data = item.comments;

      // No need to check object to itself, so skip...
      if (selectedItem === item) {
        return;
      }

      // Composite Field
      if (data[LabelItemProperties.COMPOSITE_FIELDS]?.length >= 0) {
        // Check to see if selected object is referenced
        data[LabelItemProperties.COMPOSITE_FIELDS].forEach((innerItem, i, object) => {
          if (selectedItemId === innerItem.referralId) {
            //console.log('Found referenced item inside composite field uuid: ' + data?.type + '/' + data?.uuid); //+ item.);
            result = true;
            if (removeReferences) {
              object.splice(i, 1);
              // Update text display on canvas for the composite field.  Need to check type to make sure current item is a composite field
              // or addon composite field before updating text since barcode objects also contain the compositeFields array.
              if (data.type === ComponentTypes.COMPOSITE_FIELD || data.type == ComponentTypes.ADDON_COMPOSITE_FIELD) {
                item.text = getCompositeFieldsText(item.comments.compositeFields, dfsService, item.comments.type, customFields, translateService);
                item.refresh();
              }
            } else {
              if (data.type === ComponentTypes.COMPOSITE_FIELD || data.type === ComponentTypes.ADDON_COMPOSITE_FIELD) {
                conflicts.compositeFields++;
              } else {
                conflicts.barcodes++;
              }
              return;
            }
          }
        });
      }

      // Barcode
      if (data.upcData) {
        for (var key in data.upcData) {
          // Check to see if selected object is referenced
          if (data.upcData[key].type && data.upcData[key].type.includes(selectedItemId)) {
            //console.log('Found referenced item inside barcode uuid: ' + data?.type + '/' + data?.uuid); //+ item.);
            result = true;
            if (removeReferences) {
              data.upcData[key].type = null;
            } else {
              conflicts.barcodes++;
              return;
            }
          }

          if (data.upcData[key] instanceof Array && data.upcData[key].length > 0) {
            item.comments.upcData[key].forEach((item) => {
              if (item.type && item.type.includes(selectedItemId)) {
                //console.log('Found referenced item inside barcode upcdata array uuid: ' + item?.type + '/' + item?.uuid);
                result = true;
                if (removeReferences) {
                  item.type = null;
                } else {
                  conflicts.barcodes++;
                  return;
                }
              }
            });
          }
        }
        if (removeReferences) {
          deletingReferencedItem.next(true);
        }
      }
    });
    if (rfidSettings?.atmaFieldMap) {
      const mapping = rfidSettings.atmaFieldMap;
      const affectedmapIds = [];
      mapping.forEach((map, index) => {
        if (map.objectField?.referralId && map.objectField.referralId === selectedItemId) {
          result = true;
          affectedmapIds.push(index);
          if (removeReferences) {
            map.objectField = null;
          } else {
            conflicts.atmaFields++;
          }
        }
      });
    }
  }
  // checking composite fields siblings refference
  if (selectedItem._comments?.compositeFields?.length > 0) {
    selectedItem._comments.compositeFields.forEach((item) => {
      if (item.type) {
        const childrenConflicts = checkReferencedObjects(item, removeReferences, editor, dfsService, customFields, translateService, rfidSettings, conflicts);
        if (typeof childrenConflicts === 'object') {
          conflicts = childrenConflicts;
          result = true;
        }
      }
    });
  }
  // checking dynamic barcode static structure siblings refference
  if (selectedItem._comments?.upcData) {
    for (var key in selectedItem._comments.upcData) {
      if (selectedItem._comments.upcData[key].uuid) {
        const childrenConflicts = checkReferencedObjects(selectedItem._comments.upcData[key], removeReferences, editor, dfsService, customFields, translateService, rfidSettings, conflicts);
        if (typeof childrenConflicts === 'object') {
          conflicts = childrenConflicts;
          result = true;
        }
      }
    }
  }
  return result ? conflicts : false;
}
export function getReferencedObjectsDialogData(conflictData: ConflictData) {
  let data = {
    ...new ConfirmationModalData('label_designer.referenced_object_warning.title', null, 'proceed', 'cancel'),
    dynamicComponent: new DynamicComponentModel(HeaderListFooterComponent, {
      headerMessage: 'label_designer.referenced_object_warning.message',
      conflictingData: getconflictingDataTranslation(conflictData),
      footerMessage: null
    })
  };
  return data;
}
export function getconflictingDataTranslation(conflictData: ConflictData): string[] {
  const data = [];
  if (conflictData.compositeFields) {
    data.push('label_designer.referenced_object_warning.composite_error');
  }
  if (conflictData.barcodes) {
    data.push('label_designer.referenced_object_warning.barcode_error');
  }
  if (conflictData.atmaFields) {
    data.push('label_designer.referenced_object_warning.comissioning_error');
  }
  return data;
}
export function setAsEndOfDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
}
