import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationService } from '../../../shared/services/location.service';
import { AuthService } from '../../../core/auth/auth.service';
import { addPaginationParametersToUrl, appendQueryParamIfDefined, defined } from '../../../shared/helpers/app.helpers';
import { APIResponseModel } from '../../../shared/models/api-response';
import { Node } from '../../../shared/models/node.model';
import { PreSelectedNode } from '../../../shared/models/Tree-Node.model';
import { User } from '../../../shared/models/user.model';
import { Currency } from '../../menu-manager/models/Currency.model';
import { AccountAccessToTerminal } from '../models/accountAccessToTerminal.model';
import { AssignedMenus } from '../models/assignedMenus.model';
import { ChecklistAssignment } from '../models/checklistAssignment.model';
import { CustomProductPrice, LocationMenuMenuItemsMetaData } from '../models/locationMenuMenuItems.model';
import { LocationMenus } from '../models/locationMenus.model';
import { MenuItemId } from '../models/MenuItem.model';
import { customizedRecipe } from '../models/RecipeLocationMenuItem.model';
import { Store } from '../models/store.model';
import { StoreDetails } from '../models/storeDetails.model';
import { subNodeParents } from '../models/subNodeParents.model';
import { Terminal } from '../models/terminal.model';
import { UnassignTerminalsPayload } from '../models/unassignTerminals.model';
import { environment } from './../../../../environments/environment';
import { AssignmentChecklist } from './../../../shared/models/checklist.model';
import { PaginationResponse } from './../../../shared/models/pagination-response.model';
import { TreeNode } from './../../../shared/models/Tree-Node.model';
import { ApiService } from './../../../shared/services/api/api.service';
import { LocationSimpleModel } from '../models/location-simple.model';
import { TimerLocationModel, TimerReqDetails } from '../../timer-manager/models/timer-template.model';
import { TerminalModules } from '../models/terminal-modules.model';
import { LocationAccountValidation } from '../models/location-validations.model';
import { SortDirection } from '@angular/material/sort';
import { BulkMoveLocation } from '../models/location.model';
import { CatalogLocationModel, CatalogReqDetails, UnassignCatalogReqDetails } from '../../catalog-manager/models/catalog-location.model';
import { ADFCatalog } from '../models/adf-catalog.model';

@Injectable({
  providedIn: 'root'
})
export class LocationManagerService {
  deviceImportSuccess: Subject<boolean> = new Subject();
  baseServiceUrl = `${environment.baseAPIUrl}/${environment.version}/accounts/`;
  private folderPublishSuccess = new Subject<void>();

  currentSelectedNode: Node;
  public currentSelectedNodeSubject: BehaviorSubject<Node> = new BehaviorSubject<Node>(null);
  public isRootNodeSubject: Subject<boolean> = new Subject<boolean>();
  public creationSucess: Subject<boolean> = new Subject<boolean>();
  public usersAssignmentSuccess: Subject<boolean> = new Subject<boolean>();
  public menusAssignmentSuccess: Subject<boolean> = new Subject<boolean>();
  public mediasAssignmentSuccess: Subject<boolean> = new Subject<boolean>();
  public terminalsAssignmentSuccess: Subject<boolean> = new Subject<boolean>();
  public checklistsAssignmentSuccess: Subject<boolean> = new Subject<boolean>();
  public importdevicesButtonHighlight: Subject<boolean> = new Subject<boolean>();
  public timersAssignmentSuccess: Subject<boolean> = new Subject<boolean>();
  public noResultAction: Subject<{ button: string; section: string; itemIndex: number }> = new Subject();
  public timersPublishSuccess: Subject<boolean> = new Subject<boolean>();
  public menuPublishSuccess: Subject<boolean> = new Subject<boolean>();
  public reloadUponSubscription: Subject<any> = new Subject<any>();

  treeReadyEventEmitter = new Subject<TreeNode[]>();
  breadcrumb = {
    nodes: [],
    nodeFound: false
  };

  constructor(private apiService: ApiService, private authService: AuthService, private locationService: LocationService) {}

  emitFolderPublishSuccessEvent(): void {
    this.folderPublishSuccess.next();
  }

  getFolderPublishSuccessEventListener(): Observable<void> {
    return this.folderPublishSuccess.asObservable();
  }

  reloadLocationsManagerAssignedItems(): void {
    this.reloadUponSubscription.next();
  }

  canGenerateSubscriptionCode(locationId: number, accountId: number) {
    if (locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/CanGenerateCode`;
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  canSubscribeToSubscriptionCode(locationId: number, accountId: number) {
    if (locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/CanSubscribeToCode`;
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  generateSubscriptionCode(locationId: number, accountId: number, isAutomaticApproval: boolean, isViewingSubscriberAnalyticsConfirmed: boolean) {
    if (locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/GenerateSubscriptionCode`;

      //Request Body
      let body = {
        IsAutomaticApproval: isAutomaticApproval,
        isViewingSubscriberAnalyticsConfirmed: isViewingSubscriberAnalyticsConfirmed
      };
      return this.apiService.postRequest(url, body).pipe(map((response) => response.results));
    }
  }

  clearSubscriptionCode(locationId: number, accountId: number) {
    if (locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/ClearSubscriptionCode`;
      let body = null;
      return this.apiService.postRequest(url, body).pipe(map((response) => response.results));
    }
  }

  getSubscriptionDetails(locationId: number, accountId: number, subscriptionId: number) {
    if (locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/GetSubscriptionDetails`;
      url = appendQueryParamIfDefined(url, 'SubscriptionId', subscriptionId);

      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  getSubscriptionModules(accountId: number) {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/GetSubscriptionModules`;

      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  subscribeToSubscriptionCode(
    locationId: number,
    accountId: number,
    subscriptionCode: string,
    automaticAssign: boolean = false,
    automaticPublish: boolean = false,
    isDifferentNutritionalFactProfileConfirmed: boolean = false
  ) {
    if (locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/Subscribe`;

      //Request Body
      let body = {
        subscriptionCode: subscriptionCode,
        IsAutomaticAssignment: automaticAssign,
        IsAutomaticPublishing: automaticPublish,
        isDifferentNutritionalFactProfileConfirmed: isDifferentNutritionalFactProfileConfirmed
      };

      return this.apiService.postRequest(url, body).pipe(map((response) => response.results));
    }
  }

  getNodeChildren(accountId: number, parentNodeId?: number, toBeUsedInTree = false, showLoader = true, includeStores: boolean = true) {
    return this.locationService.getNodeChildren(accountId, parentNodeId, toBeUsedInTree, showLoader, includeStores);
  }

  getAssignedMenus(accountId: number, locationId: number): Observable<AssignedMenus[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/menus`;
      return this.apiService.getRequest(url).pipe(
        map((response) => {
          return <AssignedMenus[]>response.results;
        })
      );
    }
  }

  getAssignedMedias(accountId: number, locationId: number): Observable<any[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/medias`;
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  assignTerminalToOneNode(accountId: number, locationId: number, terminalId: number, force?: boolean): any {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/terminals/${terminalId}`;
      if (force) {
        url += '?move=true';
      }
      const jsonBody = {};
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  exportDevice(accountId: number, timezone: number): any {
    if (accountId) {
      let fileName = `devices.csv`;
      let url = `${this.baseServiceUrl}${accountId}/terminals/export?timezone=${timezone}`;

      return this.apiService.getRequest(url, true, false, true).subscribe((res) => {
        const blob = new Blob(['\ufeff', <any>res], { type: 'text/csv' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = fileName;
        a.href = downloadUrl;
        a.click();
      });
    }
  }

  //Mock Data
  locationMenus: LocationMenus[] = [];
  // locationMenus:LocationMenus[]=[{isAssigned:true,brandLogo:'so_fresh_so_green_logo.png',menuId:1,name:'Menu1'},{isAssigned:false,brandLogo:'HealthyLifeLogo.svg',menuId:2,name:'Menu2'}]
  getAllMenusAndMenusAssignedToLocation(accountId: number, locationId: number, searchKeyword: string = null): Observable<PaginationResponse<LocationMenus[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/menus?Check_by=locationid&locationid=${locationId}`;
      url = appendQueryParamIfDefined(url, 'searchKeyword', searchKeyword);
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  getNodeById(accountId: number, nodeId: number, brandId?: number, showLoader?: boolean): Observable<Node> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${nodeId}`;
      url = appendQueryParamIfDefined(url, 'brandId', brandId);
      return this.apiService.getRequest(url, showLoader).pipe(map((response) => response.results));
    }
  }

  updateNode(accountId: number, nodeId: number, node?: Node, store?: Store) {
    let body: any;
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${nodeId}`;
      if (store) {
        body = store;
      }
      if (node) {
        body = node;
      }
      return this.apiService.putRequest(url, body).pipe(
        map((response) => {
          return response.results;
        })
      );
    }
  }

  createNode(accountId: number, node: Node) {
    if (accountId) {
      let url = '';
      if (node.parentLocationId) {
        url = this.baseServiceUrl + `${accountId}/locations/${node.parentLocationId}/locations`;
      } else {
        url = this.baseServiceUrl + `${accountId}/locations`;
      }

      return this.apiService.postRequest(url, node).pipe(map((response) => response.results));
    }
  }

  createStore(accountId: number, nodeParentLocationId: number, store: Store) {
    if (accountId) {
      let url = '';
      if (nodeParentLocationId) {
        url = this.baseServiceUrl + `${accountId}/locations/${nodeParentLocationId}/stores`;
      } else {
        url = this.baseServiceUrl + `${accountId}/stores`;
      }

      return this.apiService.postRequest(url, store).pipe(map((response) => response.results));
    }
  }

  createSubLocation(accountId: number, nodeParentLocationId: number, subLocation: StoreDetails) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${nodeParentLocationId}/sublocations`;
    return this.apiService.postRequest(url, subLocation).pipe(map((response) => response.results));
  }

  updateSubLocation(accountId: number, nodeParentLocationId: number, storeId: number, subLocation: StoreDetails) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${nodeParentLocationId}/sublocations/${storeId}`;
    return this.apiService.putRequest(url, subLocation).pipe(map((response) => response.results));
  }

  getAncestors(accountId: number, nodeParentLocationId: number) {
    if (accountId) {
      let url = '';
      if (nodeParentLocationId) {
        url = this.baseServiceUrl + `${accountId}/locations/${nodeParentLocationId}/stores`;
      } else {
        url = this.baseServiceUrl + `${accountId}/stores`;
      }

      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  setCurrentSelectedNode(node: Node) {
    this.currentSelectedNode = node;
    this.currentSelectedNodeSubject.next(this.currentSelectedNode);
  }

  setIsRootNode(isRoot: boolean) {
    this.isRootNodeSubject.next(isRoot);
  }

  getCurrentSelectedNode(): Observable<Node> {
    return of(this.currentSelectedNode);
  }

  onCreationSuccess() {
    this.creationSucess.next(true);
  }

  GetNodeParents(accountId: number, nodeId: string): Observable<subNodeParents[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${nodeId}/tree?Include=ancestors`;
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<PreSelectedNode>) => {
          return response.results.tree;
        })
      );
    }
  }

  getTerminalsByNodeId(accountId: number, nodeId?: number): Observable<Terminal[]> {
    if (accountId) {
      const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/locations/${nodeId}/terminals`;
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<Terminal[]>) => {
          return response.results;
        })
      );
    }
  }

  getTerminalSyncFailures(accountId: number, nodeId: number, terminalId: number): Observable<any[]> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/terminals/syncFailures`;
      url = appendQueryParamIfDefined(url, 'terminalId', terminalId);
      url = appendQueryParamIfDefined(url, 'locationId', nodeId);

      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<any[]>) => {
          return response.results;
        })
      );
    }
  }

  getStoreNumber(node) {
    let storeNumber = null;
    if (node && node.type === 2) {
      // node is store
      const selectedBrand = this.authService.getSelectedBrand();
      if (defined(selectedBrand.prefix)) {
        storeNumber = `${this.authService.getSelectedBrand().prefix}${node.storeDetails.storeNumber}`;
      } else storeNumber = node.storeDetails.storeNumber;
    }
    return storeNumber;
  }

  getAssignedChecklistsByNodeId(accountId: number, nodeId: number, filterBy: string, storeNumber: string, start: number, limit: number) {
    return this.getAssignedUnAssignedChecklistsByNodeId(accountId, nodeId, null, filterBy, storeNumber, true, false, start, limit, true);
  }

  getAssignedUnAssignedChecklistsByNodeId(
    accountId: number,
    nodeId: number,
    searchKeyword?: string,
    filterBy?: string,
    storeNumber?: string,
    isAssignment?: boolean,
    forAssignment?: boolean,
    start?: number,
    limit?: number,
    getOnlyAssignedChecklist?: boolean
  ): Observable<PaginationResponse<AssignmentChecklist[]>> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/locations/${nodeId}/checklists`;
      if (searchKeyword) url += `?searchKeyword=${searchKeyword}`;
      if (filterBy) {
        if (!searchKeyword) url += `?filterBy=${filterBy}`;
        else url += `&filterBy=${filterBy}`;
      }
      if (storeNumber) {
        url += (url.includes('?') ? '&' : '?') + `nomeStoreKey=${storeNumber}`;
      }
      // always get the first 10 checklists in case we are getting assigned checklists
      if (isAssignment) {
        url += (url.includes('?') ? '&' : '?') + `isAssignment=true`;
      }

      //If we need to get the appropriate checklists for the Assignment Pop up in locations Manager
      if (forAssignment) {
        url += (url.includes('?') ? '&' : '?') + `forAssignment=true`;
      }

      url = appendQueryParamIfDefined(url, 'start', start);
      url = appendQueryParamIfDefined(url, 'limit', limit);
      url = appendQueryParamIfDefined(url, 'getOnlyAssignedChecklist', getOnlyAssignedChecklist);
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<PaginationResponse<AssignmentChecklist[]>>) => {
          return response.results;
        })
      );
    }
  }

  getTimersByLocation(accountId: number, locationId: number, brandId: number, start?: number, limit?: number, searchKeyword?: string): Observable<TimerLocationModel[]> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/locations/${locationId}/timers`;
      url += brandId != null ? `?brandId=${brandId}` : '';
      url += start != null ? `&start=${start}` : '';
      url += limit != null ? `&limit=${limit}` : '';
      url += searchKeyword ? `&searchkeyword=${searchKeyword}` : '';
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<TimerLocationModel[]>) => {
          return response.results;
        })
      );
    }
  }

  getAllTimersAndTimersAssignedToLocation(accountId: number, locationId: number, brandId?: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<TimerLocationModel[]>> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}${environment.version2}/accounts/${accountId}/timer?locationId=${locationId}&check_by=locationid`;
      url += brandId != null ? `&brandId=${brandId}` : '';
      url += start != null ? `&start=${start}` : '';
      url += limit != null ? `&limit=${limit}` : '';
      url += searchKeyword ? `&searchkeyword=${searchKeyword}` : '';
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<PaginationResponse<TimerLocationModel[]>>) => {
          return response.results;
        })
      );
    }
  }

  getAssignedCatalogs(accountId: number, locationId: number): Observable<CatalogLocationModel[]> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}${environment.version2}/accounts/${accountId}/node/${locationId}/catalogs`;
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<CatalogLocationModel[]>) => {
          return response.results;
        })
      );
    }
  }

  editADFIntegration(accountId: number, locationId: number, catalog: ADFCatalog, isEnabled: boolean): Observable<any> {
    if (accountId) {
      const body = {
        catalogId: catalog.id,
        isEnabled: isEnabled,
        currencyId: catalog.adfCurrencyId,
        currencyFromFeed: catalog.adfCurrencyFromFeed,
        currencyValue: catalog.adfCurrencyValue,
        isLocationSet: catalog.adfIsLocationSet
      };

      let url = `${environment.baseAPIUrl}${environment.version2}/accounts/${accountId}/node/${locationId}/catalog/EditADF`;
      return this.apiService.postRequest(url, body).pipe(map((response) => response.results));
    }
  }

  getLocationAssignedUnassignedCatalogs(accountId: number, locationId: number, searchKeyword?: string): Observable<PaginationResponse<CatalogLocationModel[]>> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}${environment.version2}/accounts/${accountId}/node/${locationId}/catalogs/all`;
      url += searchKeyword ? `?searchkeyword=${searchKeyword}` : '';
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<PaginationResponse<CatalogLocationModel[]>>) => {
          return response.results;
        })
      );
    }
  }

  assignUnassignCatalogsToLocation(accountId: number, locationId: number, catalogReqDetails: CatalogReqDetails) {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version2}/accounts/${accountId}/node/${locationId}/Catalogs/AssignUnassign`;
      return this.apiService.postRequest(url, catalogReqDetails).pipe(
        map((response: APIResponseModel<string>) => {
          return response.results;
        })
      );
    }
  }

  unassignAllCatalogs(accountId: number, locationId: number) {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version2}/accounts/${accountId}/node/${locationId}/Catalogs/Unassign`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  unassignCatalog(accountId: number, locationId: number, catalogId: number) {
    if (accountId) {
      const unassignedCatalogReqDetails: UnassignCatalogReqDetails = {
        unassignedCatalogId: catalogId
      };
      let url = `${environment.baseAPIUrl}/${environment.version2}/accounts/${accountId}/node/${locationId}/Catalog/Unassign`;
      return this.apiService.postRequest(url, unassignedCatalogReqDetails).pipe(
        map((response: APIResponseModel<string>) => {
          return response.results;
        })
      );
    }
  }

  assignTimersToLocation(accountId: number, locationId: number, timersDetails: TimerReqDetails) {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version}/accounts/${accountId}/locations/${locationId}/timers`;
      return this.apiService.patchRequest(url, timersDetails).pipe(
        map((response: APIResponseModel<string>) => {
          return response.results;
        })
      );
    }
  }

  publishAssignedTimer(accountId: number, locationId: number, timerId: number, publishAt: Date, publishLabels: boolean, publishLocally: boolean) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/timers/${timerId}/publish`;
    const data = {
      publishAt: publishAt,
      publishLabels: publishLabels,
      publishGlobally: !publishLocally
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response));
  }

  publishAllAssignedTimer(accountId: number, locationId: number, publishAt: Date, publishLabels: boolean, publishLocally: boolean) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/timers/publish`;
    const data = {
      publishAt: publishAt,
      publishLabels: publishLabels,
      publishGlobally: !publishLocally
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response));
  }

  getUsersByNodeId(accountId: number, nodeId: number, limit?: number): Observable<User[]> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version}/accounts/${accountId}/locations/${nodeId}/users`;
      if (limit) {
        url += `?limit=${limit}`;
      }
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<User[]>) => {
          return response.results;
        })
      );
    }
  }

  getAllUsersAndUsersAssignedToLocation(accountId: number, nodeId: number, searchKeyword: string = null) {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version}/accounts/${accountId}/users?locationId=${nodeId}&check_by=locationid`;
      url = appendQueryParamIfDefined(url, 'searchKeyword', searchKeyword);
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<User[]>) => {
          return response.results;
        })
      );
    }
  }

  assignTerminalToNode(accountId: number, nodeId: number, assignedIds: number[], unassignedIds: number[]): any {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/locations/${nodeId}/terminals`;
      const jsonBody = { assigned: assignedIds, unassigned: unassignedIds };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  customizeMenuItems(
    accountId: number,
    nodeId: number,
    menuId: number,
    enabledIds: MenuItemId[],
    disabledIds: MenuItemId[],
    required: MenuItemId[],
    unassigned: MenuItemId[],
    customizedRecipe?: customizedRecipe[],
    customizedProductPrice?: CustomProductPrice[],
    reorderItems: boolean = true,
    overrideChildNodes?: boolean
  ): any {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/locations/${nodeId}/menus/${menuId}/menuItems?reorderItems=${reorderItems}`;
      //const jsonBody = { enabled: enabledIds, required: required, disabled: disabledIds, unassigned: unassigned };
      let customizedRecipes: customizedRecipe[] = [];
      if (customizedRecipe != undefined) {
        customizedRecipes = customizedRecipe;
      }
      enabledIds = enabledIds.filter((x) => required.indexOf(x) === -1);
      const jsonBody = {
        assigned: { enabled: enabledIds, required: required, disabled: disabledIds },
        unassigned: unassigned,
        customizedRecipeOption: customizedRecipes,
        customizedProductPrice: customizedProductPrice ? customizedProductPrice : [],
        overrideChildNodes
      };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  assignUsersToLocation(accountId: number, locationId: number, assignedIds: number[], unassignedIds: number[]) {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version}/accounts/${accountId}/locations/${locationId}/users`;
      const jsonBody = { assigned: assignedIds, unassigned: unassignedIds };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: APIResponseModel<string>) => {
          return response.results;
        })
      );
    }
  }

  assignChecklistsToLocation(accountId: number, locationId: number, assignedIds: ChecklistAssignment[], unassignedIds: ChecklistAssignment[], storeNumber?, unassignAll?: boolean) {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version}/accounts/${accountId}/locations/${locationId}/checklists`;
      if (storeNumber) {
        url += (url.includes('?') ? '&' : '?') + `nomeStoreKey=${storeNumber}`;
      }
      if (unassignAll) {
        url += (url.includes('?') ? '&' : '?') + `unassignAll=${unassignAll}`;
      }
      const jsonBody = { assigned: assignedIds, unassigned: unassignedIds };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: APIResponseModel<string>) => {
          return response.results;
        })
      );
    }
  }

  assignMenusToLocation(accountId: number, locationId: number, assignedIds: number[], unassignedIds: number[]) {
    if (accountId) {
      let url = `${environment.baseAPIUrl}/${environment.version}/accounts/${accountId}/locations/${locationId}/menus`;
      const jsonBody = { assigned: assignedIds, unassigned: unassignedIds };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: APIResponseModel<string>) => {
          return response.results;
        })
      );
    }
  }

  getAllTerminalsAndTerminalsAssignedToNode(accountId: number, nodeId: number): Observable<PaginationResponse<Terminal[]>> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/terminals?LocationId=${nodeId}&Check_by=locationid`;
      return this.apiService.getRequest(url, true).pipe(map((response) => response.results));
    }
  }

  getCurrencies(): Observable<Currency[]> {
    let url = `${environment.baseAPIUrl}${environment.version}/currencies`;
    return this.apiService.getRequest(url).pipe(
      map((response) => {
        return <Currency[]>response.results;
      })
    );
  }

  getAllMenuItemsByMenu(accountId: number, locationId: number, menuId: number, start?: number, limit?: number, searchKeyword?: string, sortBy?: string, sortDirection?: string): Observable<LocationMenuMenuItemsMetaData> {
    if (accountId) {
      let url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/locations/${locationId}/menus/${menuId}/menuItems`;
      // if (start != null) {
      //   url = url + `?start=${start}`;
      // }
      // if (limit != null) {
      //   url = url + `&limit=${limit}`;
      // }
      // if (searchKeyword != null) {
      //   url = url + `&searchKeyword=${searchKeyword}`;
      // }

      url = addPaginationParametersToUrl(url, start, limit, searchKeyword, sortBy, sortDirection);
      return this.apiService.getRequest(url).pipe(
        map((response: APIResponseModel<LocationMenuMenuItemsMetaData>) => {
          return response.results;
        })
      );
    }
  }

  getTerminalsForResellerByAccountId(
    accountId: number,
    start?: number,
    limit?: number,
    queryString?: string,
    searchKeyword?: string,
    locationId?: number,
    sortBy?: string,
    sortDirection?: string
  ): Observable<PaginationResponse<Terminal[]>> {
    let url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/terminals/reseller`;
    url = `${url}` + (queryString ? `?include=${queryString}` : '');
    url = `${url}` + (locationId ? (queryString ? `&LocationId=${locationId}` : `?LocationId=${locationId}`) : '');
    url = `${url}` + (sortBy ? (queryString || locationId ? `&SortBy=${sortBy}` : `?SortBy=${sortBy}`) : '');
    url = `${url}` + (sortDirection ? (queryString || locationId || sortBy ? `&SortDirection=${sortDirection}` : `?SortDirection=${sortDirection}`) : '');
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getTerminalsByAccountId(
    accountId: number,
    start?: number,
    limit?: number,
    statusQueryParam?: string,
    appQueryParam?: string,
    searchKeyword?: string,
    locationId?: number,
    sortBy?: string,
    sortDirection?: string,
    getSubscribersDataForPublisher?: boolean
  ): Observable<PaginationResponse<Terminal[]>> {
    let url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/terminals`;

    url = `${url}` + (statusQueryParam ? `?status=${statusQueryParam}` : '');
    url = `${url}` + (appQueryParam ? (statusQueryParam ? `&app=${appQueryParam}` : `?app=${appQueryParam}`) : '');

    url = `${url}` + (locationId ? (statusQueryParam || appQueryParam ? `&LocationId=${locationId}` : `?LocationId=${locationId}`) : '');
    url = `${url}` + (sortBy ? (statusQueryParam || appQueryParam || locationId ? `&SortBy=${sortBy}` : `?SortBy=${sortBy}`) : '');
    url = `${url}` + (sortDirection ? (statusQueryParam || locationId || sortBy || appQueryParam ? `&SortDirection=${sortDirection}` : `?SortDirection=${sortDirection}`) : '');
    url = appendQueryParamIfDefined(url, 'GetSubscribersDataForPublisher', getSubscribersDataForPublisher);
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  checkAccountAccessToImportTerminal(accountId: number): Observable<AccountAccessToTerminal> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/access`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  uploadTemplateFile(accountId: number, file: File, move: boolean): Observable<unknown> {
    const url = `${this.baseServiceUrl}${accountId}/bulk/terminals`;
    const myfile: FormData = new FormData();
    if (file !== undefined) {
      myfile.append('File', file);
      myfile.append('move', move.toString());
    }
    return this.apiService.patchRequest(url, myfile).pipe(map((response) => response.results));
  }

  uploadLocationTemplateFile(accountId: number, file: File, locationId: number): Observable<unknown> {
    const url = `${this.baseServiceUrl}${accountId}/bulk/locations`;
    const myfile: FormData = new FormData();
    if (file !== undefined) {
      myfile.append('File', file);
    }
    if (locationId !== undefined) {
      myfile.append('locationId', locationId.toString());
    }
    return this.apiService.patchRequest(url, myfile).pipe(map((response) => response.results));
  }

  publishAssignedMenu(accountId: number, locationId: number, menuId: number, publishAt: Date, publishGlobally: boolean, publishLabels: boolean) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/menus/${menuId}/publish`;
    const data = {
      publishAt: publishAt,
      publishGlobally: publishGlobally,
      publishLabels: publishLabels
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response));
  }

  publishAllAssignedMenu(accountId: number, locationId: number, publishAt: Date, publishGlobally: boolean, publishLabels: boolean) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/menus/publish`;
    const data = {
      publishAt: publishAt,
      publishGlobally: publishGlobally,
      publishLabels: publishLabels
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response));
  }

  publishAllData(accountId: number, locationId: number, publishAt: Date, publishGlobally: boolean) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/publish?publishAt=${publishAt}&publishGlobally=${publishGlobally}`;
    return this.apiService.putRequest(url, null).pipe(map((response) => response));
  }

  canPublishGlobally(accountId: number, locationId: number): Observable<boolean> {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/canPublishGLobally`;
    return this.apiService.getRequest(url).pipe(
      map((data) => {
        if (data.toString().includes('true')) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  deleteTerminal(accountId: number, terminalId: number) {
    const url = `${this.baseServiceUrl}${accountId}/terminals/${terminalId}`;
    return this.apiService.deleteRequest(url).pipe(map((response) => response));
  }

  requestTerminalFetchLogs(accountId: number, terminalId: number, cancel: boolean, attachDatabase: boolean) {
    let url = `${this.baseServiceUrl}${accountId}/terminals/${terminalId}/fetchLOgs`;
    if (cancel) {
      url = url + `?cancel=true`;
    } else if (attachDatabase) {
      url = url + `?attachDatabase=true`;
    }
    return this.apiService.patchRequest(url, null).pipe(map((response) => response));
  }

  requestTerminalFullSync(accountId: number, terminalId: number, cancel: boolean) {
    let url = `${this.baseServiceUrl}${accountId}/terminals/${terminalId}/fullSync`;
    if (cancel) {
      url = url + `?cancel=true`;
    }
    return this.apiService.patchRequest(url, null).pipe(map((response) => response));
  }

  exportLocation(accountId: number, locationId: number) {
    let fileName = `locations.csv`;
    let url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/export`;

    return this.apiService.getRequest(url, true, false, true).subscribe((res) => {
      const blob = new Blob(['\ufeff', <any>res], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = fileName;
      a.href = downloadUrl;
      a.click();
    });
  }

  deleteLocation(accountId: number, locationId: number) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}`;
    return this.apiService.deleteRequest(url).pipe(map((response) => response));
  }

  getLocation(accountId: number, locationId: number, brandId?: number): Observable<Node> {
    let url = `${this.baseServiceUrl}${accountId}/locations/${locationId}`;
    if (brandId != null) {
      url = url + `?brandId=${brandId}`;
    }

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  deleteSubLocation(accountId: number, locationId: number, subLocationId: number) {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/sublocations/${subLocationId}`;
    return this.apiService.deleteRequest(url).pipe(map((response) => response));
  }

  requestTerminalClearAppData(accountId: number, terminalId: number, cancel: boolean) {
    let url = `${this.baseServiceUrl}${accountId}/terminals/${terminalId}/clearAppData`;
    if (cancel) {
      url = url + `?cancel=true`;
    }
    return this.apiService.patchRequest(url, null).pipe(map((response) => response));
  }

  customizeLogoAndBanner(accountId: number, locationId: number, type: number, file: File) {
    let imageFile: FormData = new FormData();
    imageFile.append('imageFile', file);

    let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/customizeBrandLogoAndBanner?type=${type}`;

    return this.apiService.postRequest(url, imageFile).pipe(map((response) => response.results));
  }

  unassignMenu(accountId: number, locationId: number, menuId: number): Observable<any> {
    let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/menus/${menuId}/unassign`;
    return this.apiService.deleteRequest(url);
  }

  unassignUser(accountId: number, locationId: number, userId: number): Observable<any> {
    let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/users/${userId}/unassign`;
    return this.apiService.deleteRequest(url);
  }

  unassignMedia(accountId: number, locationId: number, mediaId: number): Observable<any> {
    let url = this.baseServiceUrl + `${accountId}/medias/locations/${locationId}/${mediaId}/unassign`;
    return this.apiService.deleteRequest(url);
  }

  unassignTerminals(accountId: number, locationId: number, payload: UnassignTerminalsPayload): any {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/terminals/`;
      return this.apiService.patchRequest(url, payload).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  emitTreeReadyEvent(tree: TreeNode[]): void {
    this.treeReadyEventEmitter.next(tree);
  }

  listenToTreeReadyEvent(): Observable<TreeNode[]> {
    return this.treeReadyEventEmitter.asObservable();
  }

  getNodeTree(tree: TreeNode, nodeId: number): TreeNode {
    this.resetBreadcrumbState();
    this.pushNodeNodes(tree, nodeId);
    const targetNode = this.breadcrumb.nodes[this.breadcrumb.nodes.length - 1];
    const targetNodeAncestors = targetNode.path.split('/').map((id) => this.breadcrumb.nodes.find((node) => node.id === Number(id)));

    const numberOfNodes = targetNodeAncestors.length;
    if (numberOfNodes === 1) {
      targetNodeAncestors[0].children = [];
    } else {
      for (let i = numberOfNodes - 1; i >= 0; i--) {
        const node = targetNodeAncestors[i];

        const targetNodeIndex = i === numberOfNodes - 1;
        if (targetNodeIndex) {
          node.children = [];
        }

        if (i > 0) {
          const parentNode = targetNodeAncestors[i - 1];
          if (parentNode) parentNode.children = [node];
        }
      }
    }

    return targetNodeAncestors[0];
  }

  refreshCode(accountId: number, locationId: number): Observable<LocationSimpleModel> {
    const url = `${this.baseServiceUrl}${accountId}/locations/${locationId}/refreshCode`;
    return this.apiService.postRequest(url, {}).pipe(map((response) => response.results));
  }

  private resetBreadcrumbState(): void {
    this.breadcrumb.nodes = [];
    this.breadcrumb.nodeFound = false;
  }

  private pushNodeNodes(node: TreeNode, nodeId: number) {
    if (!this.breadcrumb.nodeFound) {
      this.breadcrumb.nodes.push(node);
      if (node.id === nodeId) {
        this.breadcrumb.nodeFound = true;
      } else {
        node.children.forEach((childNode) => this.pushNodeNodes(childNode, nodeId));
      }
    }
  }

  updateDeviceSyncStatus(accountId: number, terminalId: TerminalModules, failureSyncStatus: boolean): any {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/terminals/setSyncStatus`;

      const jsonBody = {
        terminals: [terminalId],
        syncStatus: failureSyncStatus
      };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  updateAllDevicesSyncStatus(accountId: number, locationId: number, failureSyncStatus: boolean): any {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/terminals/setSyncStatus?locationId=${locationId}`;

      const jsonBody = {
        syncStatus: failureSyncStatus
      };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  moveLocation(accountId: number, locationId: number, storeNumber: string, targetLocationId: number, automaticPublish: boolean = false, ConfirmMovingLocation: boolean = false) {
    if (accountId && locationId && targetLocationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/move`;

      const jsonBody = {
        storeNumber,
        targetLocationId,
        automaticPublish,
        ConfirmMovingLocation
      };

      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  moveLocations(accountId: number, locationId: number, stores: BulkMoveLocation[]) {
    if (accountId && locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/move/bulk`;

      return this.apiService.patchRequest(url, stores).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  validateMoveLocations(accountId: number, locationId: number) {
    if (accountId && locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/move/bulk/validate`;
      return this.apiService.getRequest(url).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  getMoveLocationFailures(accountId: number, locationId: number) {
    if (accountId && locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/move/bulk/failures`;
      return this.apiService.getRequest(url).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  resetMoveLocationFailures(accountId: number, locationId: number) {
    if (accountId && locationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/move/bulk/resetFailures`;
      return this.apiService.postRequest(url, {}).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  convertSubLocationToLocation(accountId: number, locationId: number, storeId: number, targetLocationId: number, automaticPublish: boolean = false) {
    if (accountId && locationId && targetLocationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/sublocations/${storeId}/convert`;

      const jsonBody = {
        targetLocationId,
        automaticPublish
      };

      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  moveSubLocation(accountId: number, locationId: number, storeId: number, targetLocationId: number) {
    if (accountId && locationId && targetLocationId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/sublocations/${storeId}/move`;

      const jsonBody = {
        targetLocationId
      };
      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  getListOfLocations(resellerId: number, accountId: number, start?: number, limit?: number, searchKeyword?: string, sortBy: string = 'name', sortDirection: SortDirection = 'desc'): Observable<PaginationResponse<any[]>> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/${accountId}/locationinfo`;
    url = addPaginationParametersToUrl(url, start, limit);
    url = appendQueryParamIfDefined(url, 'location', searchKeyword);
    url = appendQueryParamIfDefined(url, 'sortBy', sortBy);
    url = appendQueryParamIfDefined(url, 'sortDirection', sortDirection);

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  unassignAllMenus(accountId: number, locationId: number) {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/menus/unassign`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  unassignAllUsers(accountId: number, locationId: number) {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/users/unassign`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  unassignAllTerminals(accountId: number, locationId: number) {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/terminals/unassign`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  unassignAllTimers(accountId: number, locationId: number) {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/timers/unassign`;
      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  updateAccountDeviceStatus(accountId: number, terminalId: number, accountDeviceStatus: boolean): any {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/terminals/setAccountDeviceStatus`;

      const jsonBody = {
        terminalId: terminalId,
        accountDeviceStatus: accountDeviceStatus
      };

      return this.apiService.patchRequest(url, jsonBody).pipe(
        map((response: any) => {
          return response;
        })
      );
    }
  }

  requestClearAppReport(accountId: number, locationId, selectedAccounts: number[], unSelectedAccounts: number[], selectAllChecked, email: string, translationPayload: any): any {
    let url = this.baseServiceUrl + `${accountId}/locations/${locationId}/terminals/export/report/clearAppData?email=${email}`;
    var body = {
      selectAllChecked,
      selectedAccounts,
      unSelectedAccounts,
      translationPayload: translationPayload,
      day: new Date()
    };

    return this.apiService.postRequest(url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
