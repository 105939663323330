export enum ProductComponentTypes {
  // General components
  DATE = 'currentDate',

  // Food Components
  ITEM_PRINT_NAME = 'Caption',
  ITEM_PRICE = 'Price',
  ITEM_NET_WEIGHT = 'NetWeightMeasure',
  GROUP_TAGS = 'GroupTags',
  BARCODE_VALUE = 'Barcode',
  ITEM_DESCRIPTION = 'Description',
  ITEM_PREP_DESCRIPTION = 'PrepDescription',
  ITEM_USE_BY_DESCRIPTION = 'UseByDescription',
  ITEM_SEC_PRINT_NAME = 'Caption_Secondary',
  ITEM_SEC_DESCRIPTION = 'Description_Secondary',
  CATEGORY = 'CategoryName',
  SEC_CATEGORY = 'CategoryName_Secondary',
  DISCARD_DATE = 'discardDate',
  RECIPES_FIELD = 'RecipesField',
  INGREDIENTS = 'Ingredients',
  ALLERGENS = 'Allergens',
  MENUITEM_CALORIES = 'Calories',
  VOICE_PICK = 'VoicePick',
  ITEM_SCALE_WEIGHT = 'ScaleWeight',
  ITEM_TARE_WEIGHT = 'TareWeightValue',
  PRICE_PER_WEIGHT = 'PricePerWeight',

  // Retail components
  PRODUCT_CAPTION = 'Caption',
  PRODUCT_DESCRIPTION = 'Description',
  PRODUCT_SKU = 'SKU',
  PRODUCT_ORIGINAL_PRICE = 'OriginalPrice',
  PRODUCT_CURRENT_PRICE = 'CurrentPrice',
  PRODUCT_DISCOUNT_RATE = 'DiscountRate',
  PRODUCT_SIZE = 'Size',
  PRODUCT_COLOR = 'Color',
  PRODUCT_DEPARTMENT = 'Department',
  PRODUCT_START_DATE = 'StartDate',
  PRODUCT_END_DATE = 'EndDate',
  PRODUCT_CURRENCY = 'CurrencyCode',
  PRODUCT_LABEL = 'LabelName',
  PRODUCT_PRINT_LABEL = 'PrintLabel'
}
