export interface AtmaFields {
  name: string;
  displayName: string;
  fieldType: FieldType;
  sortOrder: number;
  isPrimaryId: boolean;
  inUse: boolean;
}
export interface LocalRfidConfig {
  organizationName: string;
  subscriptionKey: string;
  typeId: number;
  tenant: string;
  tenantTypeId: number;
  url: string;
}
export enum FieldType {
  String = 0,
  Int32 = 1,
  Double = 2,
  Decimal = 3,
  Boolean = 4,
  Float = 5,
  UInt64 = 6,
  UInt32 = 7,
  UInt16 = 8,
  Int64 = 9,
  Int16 = 10,
  DateTime = 11,
  DateTimeOffset = 12,
  Byte = 13,
  Gtin = 14,
  Text = 15,
  FileUpload = 16,
  Url = 17,
  ImageUrl = 18,
  Color = 20
}

export interface AtmaLabelUsage {
  labels: string[];
}
